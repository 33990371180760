import { Component, OnInit, OnChanges, Input, Output, EventEmitter, SimpleChanges } from "@angular/core";
import {
    MediaConnectFlow,
    MediaConnectVpcInterface,
    MediaconnectVPCNetworkInterfaceType,
    MediaConnectSource
} from "../../../models/shared";
import {
    AmazonAWSIAMRole,
    AmazonAWSSecurityGroup,
    AmazonAWSSubnet
} from "../../../pages/configuration/amazon-aws/amazon-aws";
import { Constants } from "./../../../constants/constants";
import { ControlContainer, NgForm, NgControl } from "@angular/forms";
import { CustomControlValueAccessor } from "../custom-control-value-accessor/custom-control-value-accessor";

export type FullVPCInterface = MediaConnectVpcInterface & { inUse?: boolean };

@Component({
    selector: "zx-mediaconnect-vpc",
    templateUrl: "./zx-mediaconnect-vpc.component.html",
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class ZxMediaConnectVPCComponent
    extends CustomControlValueAccessor<FullVPCInterface>
    implements OnInit, OnChanges
{
    disabled?: boolean;
    @Input() model: FullVPCInterface;
    @Input() channel: MediaConnectFlow | null;
    @Input() source: MediaConnectSource | null;
    @Input() jpegXSOutput: boolean;
    @Input() sourceSelection: boolean;
    @Input() outputSelection: boolean;
    @Input() removeAllowed: boolean;
    @Input() readOnly: boolean;
    @Input() collapsed: boolean;
    @Input() awsIAMRoles: AmazonAWSIAMRole[];
    @Input() awsSubnets: (AmazonAWSSubnet & { name })[];
    @Input() awsSubnetOptions: (AmazonAWSSubnet & { name })[];
    @Input() awsSecurityGroups: AmazonAWSSecurityGroup[];

    @Input() isParentFormSubmitted: boolean;
    @Input() vpcNames: string[];
    @Input() enaCount: number;
    @Input() efaCount: number;

    @Input() awsIAMRolesLoading: boolean;
    @Input() awsRegionDetailsLoading: boolean;

    @Output() propertiesChange = new EventEmitter();
    @Output() vpcRemove = new EventEmitter();

    vpcInterfaceTypes: { id: MediaconnectVPCNetworkInterfaceType; name: string }[] = [
        { id: "ena", name: "ENA" },
        { id: "efa", name: "EFA" }
    ];

    awsSecurityGroupOptions?: AmazonAWSSecurityGroup[];
    vpcId: string;
    selectedSecurityGroups: string[] = [];
    constants = Constants;

    constructor(public ngControl: NgControl) {
        super(ngControl);
    }

    ngOnInit() {
        if (!this.model) return;
        this.awsSecurityGroupOptions = [];
        this.updateVpcInterfaceSubnet();
        this.selectedSecurityGroups = (this.model?.security_group_ids || "").split(",").filter(grp => grp !== "");
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.awsIAMRoles) {
            if (!this.readOnly) this.model.role_arn = undefined;
        }
        if (changes.awsSecurityGroups && !this.readOnly) {
            this.selectedSecurityGroups = [];
            this.awsSecurityGroupOptions = [];
        }

        if (changes.awsSubnetOptions || changes.awsSubnets || changes.awsIAMRoles) {
            if (!this.readOnly) this.model.subnet_id = undefined;
            this.updateVpcInterfaceSubnet();
        }
    }

    nameChanged() {
        this.propertiesChange.emit();
    }

    typeChanged() {
        this.propertiesChange.emit();
    }

    VPCSelectionChange() {
        this.propertiesChange.emit();
    }

    reportRemoveVPC() {
        this.vpcRemove.emit(this.model);
    }

    updateVpcInterfaceSubnet() {
        if (this.channel.aws_account_id == null) return;
        const subnet = this.awsSubnets.find(sub => sub.id === this.model.subnet_id);
        if (!subnet) {
            this.awsSecurityGroupOptions = [];
            this.selectedSecurityGroups = [];
            return;
        }
        this.vpcId = subnet.vpc_id;
        this.awsSecurityGroupOptions = this.awsSecurityGroups.filter(sg => sg.vpc_id === this.vpcId);
        if (!this.readOnly) this.selectedSecurityGroups = [];
    }

    updateVpcInterfaceSecurityGroups() {
        this.model.security_group_ids = this.selectedSecurityGroups.join(",");
    }

    runNetworkTypesStateQuery() {
        return JSON.stringify({
            efa: { max: 1, count: this.efaCount },
            ena: { max: 2, count: this.enaCount }
        });
    }

    expandControls() {
        this.collapsed = false;
    }

    collapseControls() {
        this.collapsed = true;
    }
}

export function validateVPCTypes(vpcs?: MediaConnectVpcInterface[]): { enaCount: number; efaCount: number } {
    let efaCount = 0;
    let enaCount = 0;
    for (const vpc of vpcs ?? []) {
        if (!vpc.name || vpc.name === "") continue; //  Skip uninitialized controls.
        if (vpc.network_interface_type === "ena") enaCount++;
        if (vpc.network_interface_type === "efa") efaCount++;
    }

    return { efaCount, enaCount };
}
