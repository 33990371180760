import { Component, Injector, Input, OnInit, Type, ViewChild, ViewContainerRef } from "@angular/core";
import { BaseThumbnailComponent } from "../base-thumbnail/base-thumbnail.component";
import { TargetThumbnailComponent } from "src/app/pages/targets/target/target-thumbnail/target-thumbnail.component";
import { SourceThumbnailComponent } from "../source-thumbnail/source-thumbnail.component";

@Component({
    selector: "app-thumbnail-wrapper",
    template: `<ng-container #container></ng-container>`
})
export class ThumbnailWrapperComponent implements OnInit {
    @ViewChild("container", { read: ViewContainerRef, static: true }) container!: ViewContainerRef;
    @Input() row!: any;
    @Input() thumbnailRefreshSeconds = 8;
    @Input() fullyLoaded = false;

    constructor(private injector: Injector) {}

    ngOnInit(): void {
        // Clear the container before creating a new component.
        this.container.clear();

        // Select the component type based on the row data.
        const componentType: Type<BaseThumbnailComponent> =
            this.row.category === "target" ? TargetThumbnailComponent : SourceThumbnailComponent;

        // Create the component dynamically.
        const componentRef = this.container.createComponent<BaseThumbnailComponent>(componentType, {
            injector: this.injector
        });

        // Set common inputs.
        const thumbnailComp = componentRef.instance;
        thumbnailComp.overlay = false;
        thumbnailComp.info = false;
        thumbnailComp.bordered = false;
        thumbnailComp.bitrateOverlay = false;
        thumbnailComp.refreshInterval = this.thumbnailRefreshSeconds * 1000;

        // Set the fullyLoaded property if defined on the component.
        if ("fullyLoaded" in thumbnailComp) {
            thumbnailComp.fullyLoaded = this.fullyLoaded;
        }

        // Set component-specific input.
        if (this.row.category === "target") (thumbnailComp as TargetThumbnailComponent).target = this.row;
        else (thumbnailComp as SourceThumbnailComponent).source = this.row;
    }
}
