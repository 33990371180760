import { Component, Input } from "@angular/core";
import { Bitrate } from "src/app/models/shared";
import { AdaptiveChannel } from "../../../channel";
import { Constants } from "src/app/constants/constants";

@Component({
    selector: "app-channel-bitrates-source-status-column",
    templateUrl: "./channel-bitrates-source-status-column.component.html"
})
export class ChannelBitratesSourceStatusColumnComponent {
    @Input() bitrate: Bitrate;
    @Input() channel: AdaptiveChannel;

    getBitrateStatus(br: Bitrate) {
        if (!br) return "";
        if (!this.channel?.is_enabled || !br.source.is_enabled) return "disabled";
        if (br.source.objectState?.state === "error") return "bad";
        if (br.source.objectState?.state === "warning") return "warning";
        return "good";
    }

    getBitrateStatusShort(br: Bitrate) {
        if (!br) return "";
        if (!this.channel?.is_enabled || !br.source.is_enabled) return Constants.statuses.disabled;
        if (br.source.objectState?.state === "error" && br.source.activeStates?.length > 0)
            return br.source.activeStates[0].short_message;
        return Constants.statuses.good;
    }
}
