import { ComponentRef, Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { firstValueFrom, Observable, of, ReplaySubject, Subscriber } from "rxjs";
import { share, map, catchError, take } from "rxjs/operators";

import { TranslateService } from "@ngx-translate/core";
import { StatusTextPipe } from "./../../pipes/status-text.pipe";
import { Constants } from "./../../constants/constants";
import { APIResponse, Broadcaster, BroadcasterAutoRecoveryParamsModel, KeyMap } from "./../../models/shared";
import { AuthService } from "src/app/services/auth.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BroadcasterConfigHelpDialogComponent } from "./../shared/modals/broadcaster-config-help-dialog/broadcaster-config-help-dialog.component";

import * as _ from "lodash";
import moment from "moment";
import { SharedService } from "src/app/services/shared.service";
import { SSHTunnelState } from "@zixi/models";

export interface BroadcasterLsFile {
    name: string;
    date: string;
    size: number;
}

export interface BroadcasterLsResult {
    dirs: {
        name: string;
    }[];
    files: BroadcasterLsFile[];
    error: string;
    path: string;
}

@Injectable({
    providedIn: "root"
})
export class BroadcastersService {
    broadcasters: Observable<Broadcaster[]>;
    private broadcastersRS$: ReplaySubject<Broadcaster[]>;
    private dataStore: {
        broadcasters: Broadcaster[];
    };

    private lastAllBroadcastersRefresh: number;
    private lastBroadcastersRefresh: number;

    constructor(
        private authService: AuthService,
        private http: HttpClient,
        private translate: TranslateService,
        private ngbModal: NgbModal,
        private sharedService: SharedService
    ) {
        this.reset();

        this.authService.isLoggedIn.subscribe(isLoggedIn => {
            if (!isLoggedIn) this.reset();
        });
    }

    private reset() {
        this.dataStore = {
            broadcasters: []
        };

        this.lastAllBroadcastersRefresh = null;
        this.lastBroadcastersRefresh = null;

        this.broadcastersRS$ = new ReplaySubject(1) as ReplaySubject<Broadcaster[]>;
        this.broadcasters = this.broadcastersRS$.asObservable();
    }

    private prepBroadcaster(broadcaster: Broadcaster) {
        broadcaster._frontData = {
            sortableStatus: "",
            streams: null,
            lastRefresh: moment().format(),
            is_aws: false,
            is_azure: false,
            is_gcp: false,
            is_linode: false,
            is_auto_scaling: false,
            scaling: ""
        };

        broadcaster.type = "broadcaster";

        // Streams
        if (broadcaster.status) {
            if (broadcaster.status.inputs) broadcaster._frontData.streams += broadcaster.status?.inputs_count;
            if (broadcaster.status.outputs) broadcaster._frontData.streams += broadcaster.status?.outputs_count;
            if (broadcaster.status.adaptives) broadcaster._frontData.streams += broadcaster.status?.adaptives_count;
        }

        // is?
        broadcaster._frontData.is_aws =
            broadcaster.broadcaster_cluster.aws_account_id && broadcaster.broadcaster_cluster.aws_account_id != null
                ? true
                : false;
        broadcaster._frontData.is_azure =
            broadcaster.broadcaster_cluster.azure_account_id && broadcaster.broadcaster_cluster.azure_account_id != null
                ? true
                : false;
        broadcaster._frontData.is_gcp =
            broadcaster.broadcaster_cluster.gcp_account_id && broadcaster.broadcaster_cluster.gcp_account_id != null
                ? true
                : false;
        broadcaster._frontData.is_linode =
            broadcaster.broadcaster_cluster.linode_account_id &&
            broadcaster.broadcaster_cluster.linode_account_id != null
                ? true
                : false;
        broadcaster._frontData.is_auto_scaling =
            broadcaster._frontData.is_aws ||
            broadcaster._frontData.is_azure ||
            broadcaster._frontData.is_linode ||
            broadcaster._frontData.is_gcp;

        // Scaling
        if (broadcaster._frontData.is_aws) broadcaster._frontData.scaling = "AWS";
        if (broadcaster._frontData.is_azure) broadcaster._frontData.scaling = "AZURE";
        if (broadcaster._frontData.is_gcp) broadcaster._frontData.scaling = "GCP";
        if (broadcaster._frontData.is_linode) broadcaster._frontData.scaling = "LINODE";
        if (!broadcaster._frontData.is_auto_scaling) broadcaster._frontData.scaling = "MANUAL";

        this.sharedService.prepStatusSortFields(broadcaster);

        const typed = new Broadcaster();
        Object.assign(typed, broadcaster);
        return typed;
    }

    private updateStore(broadcaster: Broadcaster, merge: boolean): void {
        broadcaster = this.prepBroadcaster(broadcaster);
        const currentIndex = this.dataStore.broadcasters.findIndex(u => u.id === broadcaster.id);
        if (currentIndex === -1) {
            this.dataStore.broadcasters.push(broadcaster);
            return;
        }

        if (merge) {
            const current = this.dataStore.broadcasters[currentIndex];
            Object.assign(current, broadcaster);
        } else {
            this.dataStore.broadcasters[currentIndex] = broadcaster;
        }
    }

    refreshAllBroadcasters(force?: boolean): Observable<Broadcaster[]> {
        // Only refresh if force is true or last refresh is not in last minute
        if (!force && _.now() - this.lastAllBroadcastersRefresh <= 60000) return this.broadcasters;
        this.lastAllBroadcastersRefresh = _.now();

        const broadcasters$ = this.http
            .get<APIResponse<Broadcaster[]>>(Constants.apiUrl + Constants.apiUrls.broadcaster)
            .pipe(share());

        broadcasters$.subscribe(
            data => {
                const broadcasters: Broadcaster[] = data.result;

                this.dataStore.broadcasters.forEach((existing, existingIndex) => {
                    const newIndex = broadcasters.findIndex(b => b.id === existing.id);
                    if (newIndex === -1) this.dataStore.broadcasters.splice(existingIndex, 1);
                });

                broadcasters.forEach(refreshedBroadcaster => this.updateStore(refreshedBroadcaster, true));

                this.broadcastersRS$.next(Object.assign({}, this.dataStore).broadcasters);
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_BROADCASTERS"), error)
        );
        return broadcasters$.pipe(map(r => r.result));
    }

    refreshBroadcasters(clusterId: number, force?: boolean): Observable<Broadcaster[]> {
        // Only refresh if force is true or last refresh is not in last minute
        if (!force && _.now() - this.lastBroadcastersRefresh <= 60000) return this.broadcasters;
        this.lastBroadcastersRefresh = _.now();

        const broadcasters$ = this.http
            .get<APIResponse<Broadcaster[]>>(
                Constants.apiUrl + Constants.apiUrls.cluster + "/" + clusterId + Constants.apiUrls.broadcaster
            )
            .pipe(share());

        broadcasters$.subscribe(
            data => {
                const broadcasters: Broadcaster[] = data.result;

                this.dataStore.broadcasters
                    .filter(broadcaster => broadcaster.broadcaster_cluster_id === clusterId)
                    .forEach((existing, existingIndex) => {
                        const newIndex = broadcasters.findIndex(b => b.id === existing.id);
                        if (newIndex === -1) this.dataStore.broadcasters.splice(existingIndex, 1);
                    });

                broadcasters.forEach(refreshedBroadcaster => this.updateStore(refreshedBroadcaster, true));

                this.broadcastersRS$.next(Object.assign({}, this.dataStore).broadcasters);
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_BROADCASTERS"), error)
        );
        return broadcasters$.pipe(map(r => r.result));
    }

    refreshBroadcaster(id: number, force?: boolean): Observable<Broadcaster> {
        if (!force && this.dataStore.broadcasters && this.dataStore.broadcasters.length) {
            const broadcaster: Broadcaster | undefined = this.dataStore.broadcasters.find(b => b.id === id);
            //
            if (broadcaster && broadcaster.hasFullDetails) {
                // Check if last refresh is within last minute
                if (moment().isBefore(moment(broadcaster._frontData.lastRefresh).add(1, "minutes"))) {
                    return new Observable((observe: Subscriber<Broadcaster>) => {
                        observe.next(broadcaster);
                        observe.complete();
                    });
                }
            }
        }

        const broadcaster$ = this.http
            .get<APIResponse<Broadcaster>>(Constants.apiUrl + Constants.apiUrls.broadcaster + "/" + id)
            .pipe(share());

        broadcaster$.subscribe(
            data => {
                const broadcaster: Broadcaster = data.result;
                broadcaster.hasFullDetails = true;

                this.updateStore(broadcaster, false);

                this.broadcastersRS$.next(Object.assign({}, this.dataStore).broadcasters);
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_BROADCASTER"), error)
        );
        return broadcaster$.pipe(map(r => r.result));
    }

    prepParams(ids: number[] | string[]) {
        let filter = new HttpParams();
        if (ids) {
            ids.forEach(id => {
                filter = filter.append("id", id);
            });
        }
        return filter;
    }

    refreshBroadcastersByIds(ids: number[]): Observable<Broadcaster[]> {
        const broadcasters$ = this.http
            .get<APIResponse<Broadcaster[]>>(Constants.apiUrl + Constants.apiUrls.broadcaster + "?update", {
                params: this.prepParams(ids)
            })
            .pipe(share());

        broadcasters$.subscribe(
            data => {
                const broadcasters = data.result;

                (ids || []).forEach(id => {
                    const newIndex = broadcasters.findIndex(b => b.id === id);
                    if (newIndex === -1) {
                        const existingIndex = this.dataStore.broadcasters.findIndex(b => b.id === id);
                        if (existingIndex !== -1) this.dataStore.broadcasters.splice(existingIndex, 1);
                    }
                });

                broadcasters.forEach(b => this.updateStore(b, true));

                this.broadcastersRS$.next(Object.assign({}, this.dataStore).broadcasters);
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_BROADCASTERS"), error)
        );
        return broadcasters$.pipe(map(r => r.result));
    }

    getCachedBroadcaster(id: number) {
        if (this.dataStore.broadcasters && id) return this.dataStore.broadcasters.find(b => b.id === id);
        else return undefined;
    }

    getCachedBroadcasters(ids: number[]) {
        if (this.dataStore.broadcasters && ids.length)
            return this.dataStore.broadcasters.filter(b => ids.includes[b.id]);
        return undefined;
    }

    getCachedBroadcasterByName(name: string, dns_prefix: string) {
        if (this.dataStore.broadcasters && name && dns_prefix)
            return this.dataStore.broadcasters.find(
                b => b.name === name && b.broadcaster_cluster.dns_prefix === dns_prefix
            );
        else return undefined;
    }

    async addBroadcaster(id: number, model: Record<string, unknown>): Promise<Broadcaster | null> {
        try {
            const result = await this.http
                .post<APIResponse<Broadcaster>>(
                    Constants.apiUrl + Constants.apiUrls.cluster + "/" + id + Constants.apiUrls.broadcaster,
                    model
                )
                .toPromise();

            if (!result.success) return null;
            if (!result.result) return null;
            const broadcaster = result.result;
            this.updateStore(broadcaster, false);

            this.broadcastersRS$.next(Object.assign({}, this.dataStore).broadcasters);
            return broadcaster;
        } catch (error) {
            return null;
        }
    }

    async deleteBroadcaster(broadcaster: Broadcaster, recovery?: BroadcasterAutoRecoveryParamsModel): Promise<boolean> {
        try {
            const result = await this.http
                .delete<APIResponse<number>>(
                    Constants.apiUrl + Constants.apiUrls.broadcaster + "/" + `${broadcaster.id}`,
                    recovery ? { body: recovery } : undefined
                )
                .toPromise();

            const deletedId = result.result;
            const broadcasterIndex = this.dataStore.broadcasters.findIndex(b => b.id === deletedId);
            if (broadcasterIndex !== -1) this.dataStore.broadcasters.splice(broadcasterIndex, 1);

            this.broadcastersRS$.next(Object.assign({}, this.dataStore).broadcasters);
            return true;
        } catch (error) {
            return false;
        }
    }

    async updateBroadcaster(broadcaster: Broadcaster, model: Record<string, unknown>) {
        try {
            const result = await this.http
                .put<APIResponse<Broadcaster>>(
                    Constants.apiUrl + Constants.apiUrls.broadcaster + "/" + `${broadcaster.id}`,
                    model
                )
                .toPromise();
            const updatedBroadcaster: Broadcaster = result?.result;
            this.updateStore(updatedBroadcaster, true);
            this.broadcastersRS$.next(Object.assign({}, this.dataStore).broadcasters);
            return updatedBroadcaster;
        } catch (error) {
            if (error.status === 428) return true;
            else return false;
        }
    }

    async applyBroadcasterConfig(id: number, ports: boolean) {
        try {
            await this.http
                .post<Broadcaster>(Constants.apiUrl + Constants.apiUrls.broadcaster + "/" + id + "/apply_config", {
                    auth: true,
                    ports,
                    allow_restart: true,
                    dtls_cert: true
                })
                .toPromise();
            return true;
        } catch (error) {
            return false;
        }
    }

    async installAgentZ(id: number) {
        try {
            await this.http
                .put<Broadcaster>(Constants.apiUrl + Constants.apiUrls.broadcaster + "/" + id + "/installAgentZ", {})
                .toPromise();
            return true;
        } catch (error) {
            return false;
        }
    }

    async ls(id: number, path: string): Promise<BroadcasterLsResult> {
        try {
            const response = await this.http
                .get<APIResponse<BroadcasterLsResult>>(
                    Constants.apiUrl + Constants.apiUrls.broadcaster + "/" + id + "/ls",
                    {
                        params: { path }
                    }
                )
                .toPromise();

            if (response.success) return response.result;
        } catch (error) {
            return null;
        }
    }
    //

    broadcasterConfigHelp(broadcaster: Broadcaster) {
        const modal = this.ngbModal.open(BroadcasterConfigHelpDialogComponent, {
            backdrop: "static",
            centered: true,
            size: "lg"
        });
        modal.componentInstance.broadcaster = broadcaster;
        return modal.result;
    }

    /**
     * @param ids The default value sets to null to get all records. To get specific ones, pass an array with the related ids
     * @param isGetUpdatedInTheLast90Seconds The default value sets to false. Set to true to get additional records that were updated in the last minute and a half (relevant when providing specific ids).
     * @returns Observable resolve with an array of broadcaster or in case of error it resolves with null and log the error to the console
     */
    getBroadcastersNew(ids: number[] = null, isGetUpdatedInTheLast90Seconds = false): Observable<Broadcaster[]> {
        let params = ids ? this.prepParams(ids) : new HttpParams();
        if (isGetUpdatedInTheLast90Seconds) {
            params = params.append("update", true);
        }
        const req$ = this.http.get<APIResponse<Broadcaster[]>>(Constants.apiUrl + Constants.apiUrls.broadcaster, {
            params
        });

        return req$.pipe(
            map(response => {
                if (!response.success) {
                    throw new Error(this.translate.instant("API_ERRORS.FAIL_STATUS"));
                }
                return response.result;
            }),
            catchError(error => {
                // eslint-disable-next-line no-console
                console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_BROADCASTER"), error);
                return of(null);
            }),
            share()
        );
    }

    // getTargetBroadcaster
    getTargetBroadcaster(id: number) {
        return new Observable((observe: Subscriber<Broadcaster>) => {
            const bx = this.getCachedBroadcaster(id);
            if (bx) {
                observe.next(bx);
                observe.complete();
                return;
            }

            this.refreshBroadcastersByIds([id])
                .pipe(
                    take(1),
                    map(bxs => bxs.find(bx => bx.id === id))
                )
                .subscribe(bx => {
                    if (bx) {
                        observe.next(bx);
                        observe.complete();
                    } else {
                        observe.error();
                    }
                });
        });
    }

    async getBroadcasterNdiStreams(id: number) {
        try {
            const { result } = await firstValueFrom(
                this.http.get<APIResponse<string[]>>(
                    Constants.apiUrl + Constants.apiUrls.broadcaster + "/" + id + "/ndi_list"
                )
            );
            return result;
        } catch (error) {
            return false;
        }
    }

    async getBroadcasterAgentZOS(id: number) {
        try {
            const url = Constants.apiUrl + Constants.apiUrls.broadcaster + "/" + id + "/agentz_data";
            const AgentZData = await firstValueFrom(this.http.get<APIResponse<any>>(url));
            return AgentZData.result;
        } catch (error) {
            catchError(error => {
                // eslint-disable-next-line no-console
                console.log("error when trying to get broadcaster Agent OS data:", error);
                return of(false);
            });
        }
    }

    checkAgentZHasRecentReport(bx: Broadcaster) {
        const now = new Date().valueOf();
        const agentzLastReport = bx.agentz_last_report ? new Date(bx.agentz_last_report).valueOf() : 0;
        const deltaSinceLastReport = now - agentzLastReport;
        const activeAgentZ = bx.agentz_last_report && deltaSinceLastReport < Constants.agentzReportThreshold_ms;
        return activeAgentZ;
    }

    isTunnelGood(object: Record<string, SSHTunnelState>, key: string) {
        if (key in object) {
            if (object.hasOwnProperty(key)) {
                // is good when it exists and it’s status === "On" and reverse_tunnel is true
                if (object[key].status === "On" && object[key].reverse_tunnel) return true;
                else return false;
            }
        }
        return false;
    }
    getTunnelStatus(broadcaster: Broadcaster) {
        if (broadcaster.Customer?.tunnel_servers?.length) {
            let goodCount = 0;
            let badCount = 0;
            for (const tunnel of broadcaster.Customer.tunnel_servers) {
                if (broadcaster?.status?.tunnels) {
                    if (this.isTunnelGood(broadcaster?.status?.tunnels, tunnel.dns_prefix)) goodCount++;
                    else badCount++;
                } else badCount++;
            }
            if (goodCount > 0 && badCount === 0) return "good";
            else if (goodCount > 0 && badCount > 0) return "warning";
            else if (goodCount === 0 && badCount > 0) return "error";
        } else {
            return "none";
        }
    }
    getTunnelStatusText(s: string) {
        if (s === "good") return "OK";
        else if (s === "warning") return "Warning";
        else if (s === "error") return "Error";
        else return "None";
    }
}
