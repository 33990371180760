<div class="thumbnail" #thumbnailContainer [ngClass]="{ 'border-0 mb-0 p-0': !bordered }">
    <div class="top-info" *ngIf="target && info && !infoBtm">
        <div class="d-flex justify-content-between mb-1" [ngClass]="{ 'ms-2 me-2': !bordered && !grid }">
            <app-target-thumbnail-info [target]="target" [showTag]="showTag" [searchTerm]="searchTerm"></app-target-thumbnail-info>
        </div>
    </div>
    <div class="aspect-ratio-fix" [ngStyle]="{ 'background-color': initialized ? 'black' : 'transparent' }">
        <div class="img-container" [ngClass]="{ 'border-0': !bordered }">
            <spinner-animation *ngIf="loading"></spinner-animation>
            <img src="../../../../../assets/images/thumb-stream.png" alt="{{ target ? target.name : 'preview' }}-thumbnail" *ngIf="loading" />
            <img
                #thumbnail
                [src]="imgSource"
                alt="{{ target ? target.name : 'preview' }}-thumbnail"
                (load)="onImgLoad()"
                *ngIf="!loading && !livePlayer"
                [ngClass]="{ superwide: superWide }"
            />
        </div>
    </div>
    <div *ngIf="target" class="overlay-text" [hidden]="!overlay || !alertOverlay || isSmall" [ngClass]="{ 'top-0': !info || infoBtm, isSmall: isSmall }">
        <div class="alert-container" [ngClass]="{ 'mb-0': !info }">
            <div class="alert-container-inner">
                <div *ngFor="let activeState of target.activeStates" class="alert" [ngClass]="activeState.type === 'error' ? 'alert-danger' : 'alert-warning'">
                    <strong>{{ activeState.short_message }}</strong>
                </div>
                <div class="alert alert-light mb-0" role="alert" *ngIf="target.is_enabled && target.state === 'pending'">
                    <strong>{{ "OFFLINE" | translate }}</strong>
                </div>
                <div class="alert alert-light mb-0" role="alert" *ngIf="!target.is_enabled">
                    <strong>{{ "DISABLED" | translate }}</strong>
                </div>
                <div class="alert alert-warning mb-0" role="alert" *ngIf="hasDecodeErrorMsg">
                    <strong>{{ hasDecodeErrorMsg }}</strong>
                </div>
            </div>
        </div>
    </div>
    <div class="overlay-audio" [hidden]="loading || !hasAudioLevels || !overlay || !audioOverlay" [ngClass]="{ horizontal: isHorizontal, 'btm-25px': infoBtm }">
        <canvas #overlayAudio></canvas>
    </div>
    <div class="overlay-timestamp" *ngIf="!loading && pts" [hidden]="!overlay || !timeOverlay" [ngClass]="{ 'btm-25px': infoBtm }">
        {{ pts / 27000 | timestamp }}
    </div>
    <div class="bottom-info" *ngIf="target && info && infoBtm">
        <div class="d-flex justify-content-between mt-1" [ngClass]="{ 'ms-2 me-2': !bordered && !grid }">
            <app-target-thumbnail-info [target]="target" [showTag]="showTag" [searchTerm]="searchTerm"></app-target-thumbnail-info>
        </div>
    </div>
</div>
