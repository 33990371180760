import { Component, inject } from "@angular/core";
import { MatButtonToggle } from "@angular/material/button-toggle";
import { TimeZoneT } from "src/app/constants/constants";
import { TimeZoneService } from "src/app/services/time-zone.service";
@Component({
    selector: "app-time-settings",
    templateUrl: "./time-settings.component.html",
    styleUrls: ["./time-settings.component.scss"]
})
export class TimeSettingsComponent {
    intl = Intl;
    orderedTimeZones: TimeZoneT[];
    timeZoneService = inject(TimeZoneService);

    constructor() {
        this.setTimeZoneOrder();
    }

    setTimeZoneOrder() {
        const mostRecentTimeZonesString: string = localStorage.getItem("recent.timeZones") ?? "";
        const mostRecentTimeZonesArray: string[] = mostRecentTimeZonesString.split(",").reverse();
        let timeZones = this.timeZoneService.timeZones;
        for (let i = 0, len = mostRecentTimeZonesArray.length; i < len; i++) {
            timeZones = this.moveToFirst(timeZones, "value", mostRecentTimeZonesArray[i]);
        }
        this.orderedTimeZones = [...timeZones];
    }

    moveToFirst(arr: TimeZoneT[], key: string, value: string) {
        const arrayUpdated: TimeZoneT[] = [];
        arr.forEach(e => {
            if (e[key] === value) arrayUpdated.unshift(e);
            else arrayUpdated.push(e);
        });
        return arrayUpdated;
    }

    timeFormatChange(event: { source: MatButtonToggle; value: string }) {
        this.timeZoneService.setTimeFormat(event.value);
    }
}
