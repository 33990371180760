import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router, NavigationStart } from "@angular/router";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

import { Constants } from "src/app/constants/constants";
import moment from "moment";
import { IncidentsService } from "src/app/pages/incidents/incidents.service";
import { ZmEvent } from "src/app/pages/events/event";
import { Incident } from "src/app/pages/incidents/incident";
import { MixpanelService } from "src/app/services/mixpanel.service";

@Component({
    selector: "app-add-incident-dialog",
    templateUrl: "./add-incident-dialog.component.html"
})
export class AddIncidentDialogComponent implements OnInit, OnDestroy {
    object;
    from: moment.Moment;
    to: moment.Moment;
    event: ZmEvent;

    newIncident = true;
    incidentName = "";
    incidentID: number;
    incident: Incident;

    likelyCause: string;
    possibleCauses: string[] = [];

    objectID: number;
    objectName: string;
    objectType: string;

    loading: boolean;
    saving = false;
    state = "default";

    constants = Constants;
    minLength = 3;

    private routeSubscription: Subscription;

    constructor(
        public activeModal: NgbActiveModal,
        private router: Router,
        private incidentsService: IncidentsService,
        private mixpanelService: MixpanelService
    ) {
        this.routeSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationStart))
            .subscribe(() => {
                // Close modal on navigation event
                this.activeModal.close();
            });
    }

    async ngOnInit() {
        // eslint-disable-next-line no-console
        // No Event
        if (!this.event) {
            if (this.object.feeder || this.object.receiver || this.object.zec) {
                this.possibleCauses = ["Performance", "Other"];
                if (!this.likelyCause) this.likelyCause = "Performance";
            } else if (this.object.broadcaster) {
                this.possibleCauses = ["Broadcaster", "Client Network", "Server Network", "Performance", "Other"];
                if (!this.likelyCause) this.likelyCause = "Performance";
            } else if (this.object.source || this.object.mediaconnect_sources) {
                this.possibleCauses = ["Broadcaster", "Content Analysis", "Client Network", "Server Network", "Other"];
                if (!this.likelyCause) this.likelyCause = "Content Analysis";
            } else {
                this.possibleCauses = ["Broadcaster", "Client Network", "Server Network", "Other"];
                if (!this.likelyCause) this.likelyCause = "Other";
            }
        }
        // ZEC
        if (this.object.zec) {
            this.objectID = this.object.zec.id;
            this.objectName = this.object.zec.name;
            this.objectType = "zec";
        }
        // Feeder
        if (this.object.feeder) {
            this.objectID = this.object.feeder.id;
            this.objectName = this.object.feeder.name;
            this.objectType = "feeder";
        }
        // Receiver
        if (this.object.receiver) {
            this.objectID = this.object.receiver.id;
            this.objectName = this.object.receiver.name;
            this.objectType = "receiver";
        }
        // Cluster
        if (this.object.cluster) {
            this.objectID = this.object.cluster.id;
            this.objectName = this.object.cluster.name;
            this.objectType = "cluster";
        }
        // Source
        if (this.object.source) {
            this.objectID = this.object.source.id;
            this.objectName = this.object.source.name;
            this.objectType = "source";
        }
        // Mediaconnect Sources
        if (this.object.mediaconnect_sources) {
            this.objectID = this.object.mediaconnect_sources.id;
            this.objectName = this.object.mediaconnect_sources.name;
            this.objectType = "mediaconnect_sources";
        }
        // Broadcaster
        if (this.object.broadcaster) {
            this.objectID = this.object.broadcaster.id;
            this.objectName = this.object.broadcaster.name;
            this.objectType = "broadcaster";
        }
        // Adaptive Channel
        if (this.object.adaptive_channel) {
            this.objectID = this.object.adaptive_channel.id;
            this.objectName = this.object.adaptive_channel.name;
            this.objectType = "adaptive_channel";
        }
        // Delivery Channel
        if (this.object.delivery_channel) {
            this.objectID = this.object.delivery_channel.id;
            this.objectName = this.object.delivery_channel.name;
            this.objectType = "delivery_channel";
        }
        // Failover
        if (this.object.failover_channel) {
            this.objectID = this.object.failover_channel.id;
            this.objectName = this.object.failover_channel.name;
            this.objectType = "failover_channel";
        }
        // Mediaconnect Flow
        if (this.object.mediaconnect_flows) {
            this.objectID = this.object.mediaconnect_flows.id;
            this.objectName = this.object.mediaconnect_flows.name;
            this.objectType = "mediaconnect_flows";
        }
        // Medialive
        if (this.object.medialive_channels) {
            this.objectID = this.object.medialive_channels.id;
            this.objectName = this.object.medialive_channels.name;
            this.objectType = "medialive_channels";
        }
        // Targets
        if (
            this.object.http ||
            this.object.publishing_target ||
            this.object.s3 ||
            this.object.gcp ||
            this.object.azure ||
            this.object.mediastore
        ) {
            if (this.object.http) {
                this.objectID = this.object.http.id;
                this.objectName = this.object.http.name;
            }
            if (this.object.publishing_target) {
                this.objectID = this.object.publishing_target.id;
                this.objectName = this.object.publishing_target.name;
            }
            if (this.object.s3) {
                this.objectID = this.object.s3.id;
                this.objectName = this.object.s3.name;
            }
            if (this.object.gcp) {
                this.objectID = this.object.gcp.id;
                this.objectName = this.object.gcp.name;
            }
            if (this.object.azure) {
                this.objectID = this.object.azure.id;
                this.objectName = this.object.azure.name;
            }
            if (this.object.mediastore) {
                this.objectID = this.object.mediastore.id;
                this.objectName = this.object.mediastore.name;
            }
            this.objectType = "http";
        }
        if (this.object.zixi_push || this.object.push || this.object.mediaconnect) {
            if (this.object.zixi_push) {
                this.objectID = this.object.zixi_push.id;
                this.objectName = this.object.zixi_push.name;
            }
            if (this.object.push) {
                this.objectID = this.object.push.id;
                this.objectName = this.object.push.name;
            }
            if (this.object.mediaconnect) {
                this.objectID = this.object.mediaconnect.id;
                this.objectName = this.object.mediaconnect.name;
            }
            this.objectType = "push";
        }
        if (this.object.zixi_pull) {
            this.objectID = this.object.zixi_pull.id;
            this.objectName = this.object.zixi_pull.name;
            this.objectType = "pull";
        }
        if (this.object.rtmp_push) {
            this.objectID = this.object.rtmp_push.id;
            this.objectName = this.object.rtmp_push.name;
            this.objectType = "rtmp";
        }
        if (this.object.udp_rtp) {
            this.objectID = this.object.udp_rtp.id;
            this.objectName = this.object.udp_rtp.name;
            this.objectType = "udp_rtp";
        }
        if (this.object.srt_targets) {
            this.objectID = this.object.srt_targets.id;
            this.objectName = this.object.srt_targets.name;
            this.objectType = "srt";
        }
        if (this.object.ndi_targets) {
            this.objectID = this.object.ndi_targets.id;
            this.objectName = this.object.ndi_targets.name;
            this.objectType = "ndi";
        }
        if (this.object.rist) {
            this.objectID = this.object.rist.id;
            this.objectName = this.object.rist.name;
            this.objectType = "rist";
        }
        if (this.object.mediaconnect_cdi_targets) {
            this.objectID = this.object.mediaconnect_cdi_targets.id;
            this.objectName = this.object.mediaconnect_cdi_targets.name;
            this.objectType = "cdi";
        }
        if (this.object.mediaconnect_jpegxs_targets) {
            this.objectID = this.object.mediaconnect_jpegxs_targets.id;
            this.objectName = this.object.mediaconnect_jpegxs_targets.name;
            this.objectType = "jpegxs";
        }
        if (this.object.medialive_http_targets) {
            this.objectID = this.object.medialive_http_targets.id;
            this.objectName = this.object.medialive_http_targets.name;
            this.objectType = "medialive_http";
        }
    }

    ngOnDestroy() {
        this.routeSubscription.unsubscribe();
    }

    async onSubmit() {
        this.saving = true;

        if (this.newIncident) {
            let model: Partial<Incident>;
            if (!this.event) {
                model = {
                    triggering_object_id: this.objectID,
                    triggering_object_name: this.objectName,
                    triggering_object_type: this.objectType,
                    start_time: this.from ? this.from.toISOString() : undefined,
                    end_time: this.to ? this.to.toISOString() : undefined,
                    name: this.incidentName,
                    state: "open",
                    likely_cause: this.likelyCause,
                    triggering_error_code: "manual",
                    triggering_error_group: "graph",
                    triggering_error_message: this.likelyCause,
                    triggering_error_short_message: this.likelyCause
                };
            } else {
                model = {
                    start_time: this.from ? this.from.toISOString() : undefined,
                    end_time: this.to ? this.to.toISOString() : undefined,
                    name: this.incidentName,
                    state: "open",
                    likely_cause: this.event ? this.event.short_message : this.likelyCause,
                    triggering_event_id: this.event ? this.event.id : undefined
                };
            }

            const result = await this.incidentsService.addIncident(model);

            if (result) {
                this.mixpanelService.sendEvent("create new incident");
                this.incident = result;
            } else {
                // TODO: handle error
                // eslint-disable-next-line no-console
                console.log("error");
            }
        } else {
            // Get existing incident
            await this.incidentsService.refreshIncident(this.incidentID, true).toPromise();
            const existingIncident = this.incidentsService.getCachedIncident(this.incidentID);

            if (!existingIncident) throw new Error("Incident not found");

            const objects = (existingIncident?.objects ?? []).concat([
                { object_id: this.objectID, object_type: this.objectType }
            ]);

            // Set start/end times
            let startTime: moment.Moment;
            let endTime: moment.Moment;
            if (this.event && this.event.event_date) {
                if (moment(this.event.event_date).isBefore(existingIncident.start_time))
                    startTime = moment(this.event.event_date);
                else startTime = moment(existingIncident.start_time);
                if (moment(this.event.event_date).isAfter(existingIncident.end_time))
                    endTime = moment(this.event.event_date);
                else endTime = moment(existingIncident.end_time);
            } else {
                if (this.from.isBefore(existingIncident.start_time)) startTime = this.from;
                else startTime = moment(existingIncident.start_time);
                if (this.to.isAfter(existingIncident.end_time)) endTime = this.to;
                else endTime = moment(existingIncident.end_time);
            }

            const model = {
                start_time: startTime,
                end_time: endTime,
                objects: objects,
                name: existingIncident.name,
                state: existingIncident.state,
                likely_cause: existingIncident.likely_cause,
                configuration: existingIncident.configuration,
                triggering_event_id: existingIncident.triggering_event_id
            };

            const result = await this.incidentsService.updateIncident(this.incidentID, model);
            if (result) {
                this.mixpanelService.sendEvent("update incident", {
                    id: this.incidentID
                });
                this.incident = result;
            } else {
                // TODO: handle error
                // eslint-disable-next-line no-console
                console.log("error");
            }
        }

        this.state = "done";
        this.saving = false;
    }
}
