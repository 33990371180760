import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "uptime"
})
export class UptimePipe implements PipeTransform {
    padTime(t: number) {
        return t < 10 ? "0" + t : t;
    }

    transform(totalSeconds: number): string | null {
        if (isNaN(totalSeconds) || totalSeconds < 0) return null;

        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = Math.floor(totalSeconds % 60);

        return this.padTime(hours) + ":" + this.padTime(minutes) + ":" + this.padTime(seconds);
    }

    reverseTransformToSeconds(valueAsString: string): number {
        {
            if (valueAsString === "-") return 0;
            const valueAsArray = valueAsString.split(":").map(value => parseInt(value));
            return valueAsArray[0] * 60 * 60 * 24 + valueAsArray[1] * 60 * 60 + valueAsArray[2];
        }
    }
}
