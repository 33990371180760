import { Inject, Injectable, inject } from "@angular/core";
import { SharedResource } from "../models/shared";
import { map, catchError } from "rxjs/operators";
import { Observable, from, of } from "rxjs";
import { DOCUMENT } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { prepareToServer } from "src/app/utils/prepare-to-server";
import { SharedResourcePermissions } from "@zixi/models";
import { ZenApiService } from "./zen-rpc-service";

@Injectable({
    providedIn: "root"
})
export class SharedPageService {
    private zenApi = inject(ZenApiService);
    constructor(@Inject(DOCUMENT) private document: Document, private translateService: TranslateService) {}

    async generateSharedPage(
        sharedPageRoute: string,
        name: string,
        mainObjectId: number,
        mainObjectType: string,
        startTime: string,
        endTime: string,
        permissions: SharedResourcePermissions[]
    ) {
        const sharedResource = prepareToServer({ name, mainObjectId, mainObjectType, startTime, endTime, permissions });
        try {
            const res = await this.zenApi.client.sharedResource.create({ body: sharedResource });
            return {
                result: {
                    url: `${this.document.location.origin}/${sharedPageRoute}?token=${res.body.result.token}`,
                    password: res.body.result.password
                }
            };
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(this.translateService.instant("ERROR"), error);
            return { error: `${this.translateService.instant("ERROR")} ${error?.error?.error ?? ""}` };
        }
    }

    regeneratePassword(sharedResource: SharedResource): Observable<{
        result?: { password: string };
        error?: string;
    }> {
        return from(this.zenApi.client.sharedResource.regenerate({ params: { id: sharedResource.id } })).pipe(
            map(res => res.body),
            catchError(error => {
                // eslint-disable-next-line no-console
                console.log(this.translateService.instant("ERROR"), error);
                return of({ error: `${this.translateService.instant("ERROR")} ${error?.error?.error ?? ""}` });
            })
        );
    }

    deleteSharedResource(sharedResource: SharedResource) {
        return this.zenApi.client.sharedResource
            .destroy({ params: { id: sharedResource.id } })
            .then(res => res.body.success)
            .catch(error => {
                // eslint-disable-next-line no-console
                console.log(this.translateService.instant("ERROR"), error);
                return false;
            });
    }
}
