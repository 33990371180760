import { Injectable } from "@angular/core";
import { InferredAPIRequests } from "@zixi/zen-rpc";
import { ZenApiService } from "src/app/services/zen-rpc-service";

@Injectable({
    providedIn: "root"
})
export class ActiveStatesService {
    constructor(private zenApiService: ZenApiService) {}

    // PUT /api/states/active/:id
    async updateActiveState({ id, acknowledged }: InferredAPIRequests["activeState"]["update"]["body"]) {
        try {
            const result = await this.zenApiService.client.activeState.update({
                params: { id },
                body: { id, acknowledged }
            });
            if (!result.body.success) return false;
            return result.body.result;
        } catch (error) {
            return false;
        }
    }

    // DELETE /api/states/active/:id
    async deleteActiveState(id: number) {
        try {
            const result = await this.zenApiService.client.activeState.destroy({ params: { id } });
            if (!result.body.success) return false;
            return result.body.result;
        } catch (error) {
            return false;
        }
    }
}
