import { Component, OnInit, OnChanges, OnDestroy, Input, inject } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";
import * as _ from "lodash";
import moment from "moment";

import { EventsService } from "../../../pages/events/events.service";
import { UsersService } from "../../../pages/account-management/users/users.service";
import { ZmEvent, EventsFilter } from "../../../pages/events/event";
import { KeyMap, UserPermissions, ZixiObject } from "./../../../models/shared";
import { SCREEN_SIZE } from "src/app/models/screen-size.enum";
import { SharedService } from "src/app/services/shared.service";
import { IncidentsService } from "src/app/pages/incidents/incidents.service";
import { TableSchema } from "../table-list/table-list.component";
import { TranslateService } from "@ngx-translate/core";
import { StatusClassPipe } from "src/app/pipes/status-class.pipe";
import { ZxDateTimeDisplayComponent } from "../zx-date-time-display/zx-date-time-display.component";
import { UpperCasePipe } from "@angular/common";
import { DesnakePipe } from "src/app/pipes/desnake.pipe";
import { assignDateTimeDisplayInputsFactory } from "../zx-date-time-display/zx-date-time-display.table-adapter";
import { ZxNgbHighlightComponent } from "../zx-ngb-highlight/zx-ngb-highlight.component";
import { assignNgbHighlightInputsFactory } from "../zx-ngb-highlight/zx-ngb-highlight.table-adapter";
import { TimeZoneService, dateFormats } from "src/app/services/time-zone.service";

import {
    ZxEventTitleComponent,
    getZxEventTitleText
} from "src/app/components/shared/zx-event-title/zx-event-title.component";
import { assignZxEventTitleComponentInputsFactory } from "src/app/components/shared/zx-event-title/zx-event-title.table-adapter";

type EventsObjects = ZixiObject | ZixiObject[] | number[];
interface EventsTypedObjects {
    [type: string]: EventsObjects;
}

@Component({
    selector: "zx-dynamic-events",
    templateUrl: "./zx-dynamic-events.component.html",
    providers: [UpperCasePipe, StatusClassPipe, DesnakePipe]
})
export class ZxDynamicEventsComponent implements OnInit, OnChanges, OnDestroy {
    @Input() objects: EventsTypedObjects;
    @Input() bordered?: boolean;
    @Input() quickReport = true;
    quickReportUrl: string;

    toDate: moment.Moment;
    fromDate: moment.Moment;

    loading = true;
    events: ZmEvent[] = [];
    userPermissions: UserPermissions;
    eventsFilter: EventsFilter;

    filter = {
        error: true,
        warning: true,
        info: false,
        success: false
    };

    tableExpanded: boolean;
    size: SCREEN_SIZE;

    private eventsSubscription: Subscription;
    private userPermissionsSubscription: Subscription;
    private toDateSubscription: Subscription;
    private fromDateSubscription: Subscription;
    private pickerFromToDateSubscription: Subscription;
    private resetIncidentSubscription: Subscription;
    private eventsBS$ = new BehaviorSubject<ZmEvent[]>([]);

    get tableColumnsSchema(): TableSchema[] {
        return this._tableColumnsSchema;
    }
    set tableColumnsSchema(newValue: TableSchema[]) {
        this._tableColumnsSchema = newValue;
    }

    private es = inject(EventsService);
    private userService = inject(UsersService);
    public sharedService = inject(SharedService);
    private is = inject(IncidentsService);
    private translate = inject(TranslateService);
    private uppercasePipe = inject(UpperCasePipe);
    private desnakePipe = inject(DesnakePipe);
    private timeZoneService = inject(TimeZoneService);

    _tableColumnsSchema: TableSchema[] = [
        {
            header: this.translate.instant("OBJECT"),
            columnDef: "object",
            width: 180,
            visible: true,
            component: ZxEventTitleComponent,
            assignComponentsInputs: assignZxEventTitleComponentInputsFactory,
            textValue: (row: KeyMap<ZmEvent>) =>
                getZxEventTitleText(row.object_type, row.object_name, this.translate, this.desnakePipe),
            sortBy: (row: KeyMap<ZmEvent>) =>
                getZxEventTitleText(row.object_type, row.object_name, this.translate, this.desnakePipe)
        },
        {
            header: this.translate.instant("DATE/TIME"),
            columnDef: "date/time",
            width: 160,
            visible: true,
            component: ZxDateTimeDisplayComponent,
            assignComponentsInputs: assignDateTimeDisplayInputsFactory<ZmEvent>(
                row => row.event_date,
                dateFormats.shortDateTimeSeconds
            ),
            sortBy: (row: KeyMap<ZmEvent>) => new Date(row.event_date).getTime(),
            textValue: (row: KeyMap<ZmEvent>) => {
                if (row.event_date !== "0000-00-00 00:00:00") {
                    return this.timeZoneService.computeDateToTimeZoneReturnString(
                        row.event_date,
                        dateFormats.shortDateTimeSeconds
                    );
                }
                return "-";
            }
        },
        {
            header: this.translate.instant("MESSAGE"),
            columnDef: "message",
            width: 240,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<ZmEvent>>(
                row => row.message,
                row => row.message,
                row => true
            ),
            textValue: (row: KeyMap<ZmEvent>) => row.message,
            sortBy: (row: KeyMap<ZmEvent>) => row.message
        },
        {
            header: this.translate.instant("RULE"),
            columnDef: "rule",
            width: 180,
            visible: false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory(
                row => (this.isRuleExists(row) ? this.getColumnText(row, "rule") : "-"),
                row => (this.isRuleExists(row) ? this.getColumnText(row, "rule") : "-"),
                row => this.isRuleExists(row)
            ),
            textValue: (row: KeyMap<ZmEvent>) => (this.isRuleExists(row) ? this.getColumnText(row, "rule") : ""),
            sortBy: (row: KeyMap<ZmEvent>) => (this.isRuleExists(row) ? this.getColumnText(row, "rule") : "")
        }
    ];

    // Reset Filter
    resetFilter() {
        this.filter = {
            error: true,
            warning: true,
            info: false,
            success: false
        };
    }

    async ngOnChanges() {
        this.loading = true;
        if (this.sharedService.isEmptyObject(this.objects)) {
            this.events = [];
            this.loading = false;
            this.prepTableData();
            return;
        }
    }

    private getTypedIds(objects?: EventsTypedObjects) {
        return _.reduce(
            objects ? objects : this.objects,
            (typedIds, object: EventsObjects, type: string) => {
                if (object instanceof Array) {
                    typedIds[type] = _.map(object, (o: ZixiObject | number) => {
                        if (typeof o === "number") return o;
                        else return o.id;
                    });
                } else if (object && (object.id || object.id === 0)) {
                    typedIds[type] = [object.id];
                }
                return typedIds;
            },
            {}
        );
    }

    getColumnText(row: unknown | ZmEvent, columnType: string): string {
        const event: ZmEvent = row as unknown as ZmEvent;
        switch (columnType) {
            case "rule":
                return event?.error_group &&
                    event?.error_code &&
                    (event.event_type === "error" || event.event_type === "warning")
                    ? `${this.translate.instant(
                          this.uppercasePipe.transform(event.error_group)
                      )} - ${this.translate.instant(this.uppercasePipe.transform(event.error_code))}`
                    : "-";
            case "object": {
                const commonText: string = event.object_name ? " - " + event.object_name : "";
                switch (event.object_type) {
                    case "groups":
                        return this.translate.instant("USER_GROUP");
                    case "srt_targets":
                        return `${this.translate.instant("SRT")} ${this.translate.instant("TARGET")}${commonText}`;
                    case "ndi_targets":
                        return `${this.translate.instant("NDI")} ${this.translate.instant("TARGET")}${commonText}`;
                    default:
                        return `${this.desnakePipe.transform(event.object_type)} ${commonText}`;
                }
            }
            default:
                return "-";
        }
    }

    isRuleExists(row: unknown | ZmEvent): boolean {
        const event: ZmEvent = row as unknown as ZmEvent;
        return !!(
            event.error_group &&
            event.error_code &&
            (event.event_type === "error" || event.event_type === "warning")
        );
    }

    checkFilterHasObjects() {
        const obj = this.getTypedIds();
        const keys = Object.keys(obj);
        const key = keys[0];
        if (keys.length === 1 && key === "incidents" && obj[key].length === 0) {
            return false;
        } else return true;
    }

    // Prep Filter
    prepFilter(overrides: Partial<EventsFilter> = {}) {
        this.eventsFilter = Object.assign(
            {
                offset: undefined,
                pageSize: 1000,
                fromDate: this.fromDate ? this.fromDate : moment().subtract(12, "hours"),
                toDate: this.toDate ? this.toDate : moment(),
                msgTypes: this.filter,
                objects: this.getTypedIds()
            },
            overrides
        );
        return this.eventsFilter;
    }

    ngOnInit() {
        this.userPermissionsSubscription = this.userService.userPermissions.subscribe(obj => {
            this.userPermissions = obj;
        });

        this.eventsSubscription = this.es.events.subscribe(events => {
            this.events = events;
            // If filter has no object, prevent showing all events for time range
            if (!this.checkFilterHasObjects()) this.events = [];
            if (this.events) {
                this.prepTableData();
                this.loading = false;
            }
        });

        this.resetIncidentSubscription = this.is.getResetIncident().subscribe(reset => {
            if (reset) this.resetEvents();
        });

        // Date Subs
        this.toDateSubscription = this.is.getToDate.pipe().subscribe(d => {
            if (d) {
                this.toDate = d;
                this.reloadEvents();
            }
        });

        this.fromDateSubscription = this.is.getFromDate.pipe().subscribe(d => {
            if (d) {
                this.fromDate = d;
                this.reloadEvents();
            }
        });

        this.pickerFromToDateSubscription = this.is.getPickerFromToDate.pipe().subscribe(o => {
            if (o) {
                if (o.f) this.fromDate = o.f;
                if (o.t) this.toDate = o.t;
                this.reloadEvents();
            }
        });
    }

    ngOnDestroy() {
        this.userPermissionsSubscription.unsubscribe();
        this.eventsSubscription.unsubscribe();
        this.es.clearEvents();
        this.toDateSubscription.unsubscribe();
        this.fromDateSubscription.unsubscribe();
        this.pickerFromToDateSubscription.unsubscribe();
        this.resetIncidentSubscription.unsubscribe();
    }

    get events$() {
        return this.eventsBS$.asObservable();
    }

    // Prep Table Data
    private prepTableData() {
        // sort
        let events = this.events.sort((a, b) => (a.event_date > b.event_date ? 1 : -1));
        this.eventsBS$.next(events);
    }

    onFilterChange(filterChanged): void {
        this.filter = { ...filterChanged };
        localStorage.setItem("zxDynamicEvents.filter", JSON.stringify(this.filter));
        this.reloadEvents();
    }

    // Reload Events
    async reloadEvents() {
        if (this.sharedService.isEmptyObject(this.objects)) return;
        //
        this.loading = true;
        await this.es.refreshEvents(this.prepFilter());
        this.getQuickReportUrl();
        this.loading = false;
    }

    // Reset Events
    async resetEvents() {
        if (this.sharedService.isEmptyObject(this.objects)) return;
        //
        this.loading = true;
        this.resetFilter();
        this.events = [];
        await this.es.refreshEvents(this.prepFilter());
        this.getQuickReportUrl();
        this.loading = false;
    }

    getQuickReportUrl() {
        this.quickReportUrl = "/api/events/report?" + this.quickReportParameters();
    }

    quickReportParameters() {
        const reportEventsFilter = this.prepFilter({
            pageSize: 1000,
            fromDate: this.fromDate ? this.fromDate : undefined,
            toDate: this.toDate ? this.toDate : undefined,
            offset: undefined,
            msgTypes: this.filter,
            objects: this.getTypedIds()
        });

        return this.es.getEventsParameters(reportEventsFilter).toString();
    }
}
