<section id="error_concealment_section">
    <div class="toggle-fieldset-title" [ngClass]="{ closed: !showErrorConcealment }">
        <h3 class="toggle-fieldset" (click)="showErrorConcealment = !showErrorConcealment" [ngClass]="[!showErrorConcealment ? 'closed' : '']">
            {{ "ERROR_CONCEALMENT" | translate }}<fa-icon icon="plus" size="xs" [fixedWidth]="true" *ngIf="!showErrorConcealment"></fa-icon
            ><fa-icon icon="minus" size="xs" [fixedWidth]="true" *ngIf="showErrorConcealment"></fa-icon>
        </h3>
    </div>
    <fieldset class="mb-3 bordered no-bg" [ngClass]="{ 'd-none': !showErrorConcealment }">
        <div class="form-group">
            <div class="form-check">
                <input
                    type="checkbox"
                    class="form-check-input"
                    id="error_concealment"
                    name="error_concealment"
                    [(ngModel)]="model.error_concealment"
                    (change)="this.onChange()"
                />
                <label class="form-check-label" for="error_concealment">{{ "ENABLE" | translate }} {{ "ERROR_CONCEALMENT" | translate }}</label>
            </div>
        </div>
        <div class="form-group">
            <div class="form-check">
                <input
                    type="checkbox"
                    class="form-check-input"
                    id="error_concealment_continuous_timeline"
                    name="error_concealment_continuous_timeline"
                    [(ngModel)]="model.error_concealment_continuous_timeline"
                    (change)="this.onChange()"
                    [disabled]="!model.error_concealment"
                />
                <label class="form-check-label" for="error_concealment_continuous_timeline">{{ "ERROR_CONCEALMENT_CONTINUOUS_TIMELINE" | translate }}</label>
            </div>
        </div>
        <div class="form-group">
            <div class="form-check">
                <input
                    type="checkbox"
                    class="form-check-input"
                    id="error_concealment_replace_frames"
                    name="error_concealment_replace_frames"
                    [(ngModel)]="model.error_concealment_replace_frames"
                    (change)="this.onChange()"
                    [disabled]="!model.error_concealment"
                />
                <label class="form-check-label" for="error_concealment_replace_frames">{{ "ERROR_CONCEALMENT_REPLACE_FRAMES" | translate }}</label>
            </div>
        </div>
        <div class="form-group">
            <div class="form-check">
                <input
                    type="checkbox"
                    class="form-check-input"
                    id="error_concealment_fix_cc"
                    name="error_concealment_fix_cc"
                    [(ngModel)]="model.error_concealment_fix_cc"
                    (change)="this.onChange()"
                    [disabled]="!model.error_concealment"
                />
                <label class="form-check-label" for="error_concealment_fix_cc">{{ "ERROR_CONCEALMENT_FIX_CC" | translate }}</label>
            </div>
        </div>
        <div class="form-group">
            <label for="error_concealment_delay_ms">{{ "ERROR_CONCEALMENT_DELAY_MS" | translate }}</label>
            <input
                type="number"
                id="error_concealment_delay_ms"
                name="error_concealment_delay_ms"
                placeholder="{{ 'ERROR_CONCEALMENT_DELAY_MS' | translate }}"
                class="form-control form-control-sm"
                [(ngModel)]="model.error_concealment_delay_ms"
                (change)="this.onChange()"
                [disabled]="!model.error_concealment"
            />
        </div>
        <div class="form-group">
            <label for="error_concealment_cbr_padding_kbps">{{ "ERROR_CONCEALMENT_CBR_PADDING_KBPS" | translate }}</label>
            <input
                type="number"
                id="error_concealment_cbr_padding_kbps"
                name="error_concealment_cbr_padding_kbps"
                placeholder="{{ 'ERROR_CONCEALMENT_CBR_PADDING_KBPS' | translate }}"
                class="form-control form-control-sm"
                [(ngModel)]="model.error_concealment_cbr_padding_kbps"
                (change)="this.onChange()"
                [disabled]="!model.error_concealment"
            />
        </div>
        <div class="form-group">
            <label for="error_concealment_cbr_padding_pcr_interval_ms">{{ "ERROR_CONCEALMENT_CBR_PADDING_PCR_INTERVAL_MS" | translate }}</label>
            <ng-select
                id="error_concealment_cbr_padding_pcr_interval_ms"
                name="error_concealment_cbr_padding_pcr_interval_ms"
                [items]="pcrIntervalOptions"
                [clearable]="false"
                bindValue="value"
                bindLabel="label"
                [(ngModel)]="model.error_concealment_cbr_padding_pcr_interval_ms"
                class="form-control"
                (change)="this.onChange()"
                [disabled]="!model.error_concealment"
            >
            </ng-select>
        </div>
    </fieldset>
</section>
