import { OnDestroy, Component } from "@angular/core";
import { Router, NavigationStart } from "@angular/router";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "app-single-datepicker-dialog",
    templateUrl: "./single-datepicker-dialog.component.html"
})
export class SingleDatepickerDialogComponent implements OnDestroy {
    loading: boolean;
    muteUntil: Date;
    muteUntilString: string;
    label: string;

    private routeSubscription: Subscription;

    constructor(public activeModal: NgbActiveModal, private router: Router) {
        this.routeSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationStart))
            .subscribe(() => {
                // Close modal on navigation event
                this.activeModal.close();
            });
    }

    ngOnDestroy() {
        this.routeSubscription.unsubscribe();
    }

    confirm(muteUntilString: string) {
        this.muteUntil = new Date(muteUntilString);
        this.activeModal.close(this.muteUntil);
    }
}
