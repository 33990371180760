import { Component, Input, Output, EventEmitter } from "@angular/core";
import { Constants } from "src/app/constants/constants";

@Component({
    selector: "app-advanced-section-form",
    templateUrl: "./advanced-section-form.component.html",
    styleUrls: ["./advanced-section-form.component.scss"]
})
export class AdvancedSectionFormComponent {
    @Input() model;
    @Input() form;
    @Input() isEdit: boolean;
    @Input() showMTUField = false;
    @Input() showAutoMTU = false;
    @Input() showBilling = false;
    @Input() showAutopullBilling = false;
    @Input() showTranscodeEncoder = false;
    @Input() showInputDeviceBind = false;
    @Input() showFrozenVideoMinimalDuration = false;
    @Input() showBlancVideoMinimalDuration = false;
    @Input() showForceIFrames = false;
    @Input() showHtmlOverlayUrl = false;
    @Input() showExternalID = false;
    @Input() showDejitterInfo = false;
    @Input() showFECControls = false;
    @Input() showFrameThinningControls = false;
    @Input() isTarget = false;

    @Output() modelChange = new EventEmitter();

    showAdvanced = false;

    transcodeEncoderOptions = Constants.transcodeEncoderOptions;

    onChange() {
        this.modelChange.emit(this.model);
    }
}
