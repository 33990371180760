import { Component, OnInit, OnChanges, Input, SimpleChanges, inject } from "@angular/core";
import { Source, FailoverSource, Broadcaster } from "../../../../models/shared";
import { DeviceDetectorService } from "ngx-device-detector";

import { Constants } from "../../../../constants/constants";
import { ClipboardService } from "ngx-clipboard";
import { UsersService } from "src/app/pages/account-management/users/users.service";
import { SourcesService, SOURCE_CONTENT_ANALYSIS } from "../../sources.service";
import { firstValueFrom } from "rxjs";

@Component({
    selector: "app-source-details",
    templateUrl: "./source-details.component.html"
})
export class SourceDetailsComponent implements OnInit, OnChanges {
    @Input() source: Source;
    @Input() canEdit: boolean;
    @Input() targetBroadcaster: Broadcaster | undefined;

    CONTENT_ANALYSIS = SOURCE_CONTENT_ANALYSIS;
    pwVisible = false;
    local_closed_caption = true;
    isPushOrPull = false;

    urls = Constants.urls;

    primaryFailoverSources: FailoverSource[] = [];
    secondaryFailoverSources: FailoverSource[] = [];
    slateFailoverSources: FailoverSource[] = [];

    multiviewSources = [];
    isAdmin: boolean;

    private deviceService = inject(DeviceDetectorService);
    private cbs = inject(ClipboardService);
    private userService = inject(UsersService);
    private sourcesService = inject(SourcesService);
    canVLC = ["windows", "mac"].includes(this.deviceService.os.toLocaleLowerCase());

    encoderDisplayName: string | undefined;
    transcodeEncoderOptions = Constants.transcodeEncoderOptions;

    get contentAnalysis() {
        return this.sourcesService.getSourceContentAnalysisState(this.source);
    }

    async ngOnInit() {
        this.initFailoverSources();
        this.initMultiviewSources();

        const user = await firstValueFrom(this.userService.getCurrentUser());
        this.isAdmin = !!user.is_admin || !!user.is_objects_manager;
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes.source) {
            this.isPushOrPull = this.sourcesService.isPushOrPull(this.source);

            if (this.source) {
                this.initFailoverSources();
                this.initMultiviewSources();

                if (this.source.type === "transcoded") {
                    this.encoderDisplayName = this.transcodeEncoderOptions.find(
                        obj => obj.id === this.source.transcode_encoder
                    )?.name;
                }
            }

            if (changes.source.previousValue && changes.source.currentValue) {
                if (
                    changes.source.previousValue.id !== changes.source.currentValue.id ||
                    !changes.source.previousValue.hasFullDetails
                ) {
                    this.pwVisible = false;
                }
            }
        }
    }

    initFailoverSources() {
        const failoverSources = this.source.failoverSources ?? [];
        this.primaryFailoverSources = failoverSources.filter(fs => fs.priority > 1);
        this.secondaryFailoverSources = failoverSources.filter(fs => fs.priority === 1);
        this.slateFailoverSources = failoverSources.filter(fs => fs.priority === 0);
    }

    initMultiviewSources() {
        const multiviewSources = this.source.multiviewSources ?? [];
        this.multiviewSources = multiviewSources.sort((a, b) => a.grid_y - b.grid_y);
    }

    copyPassword(pw: string) {
        this.cbs.copy(pw);
    }
}
