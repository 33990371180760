import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from "@angular/core";
import { Subscription } from "rxjs";
import { ControlContainer, NgForm } from "@angular/forms";

import { UserGroup } from "./../../../models/shared";
import { UserGroupsService } from "../../../pages/account-management/user-groups/user-groups.service";

@Component({
    selector: "zx-groups-select",
    templateUrl: "./zx-groups-select.component.html",
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class ZxGroupsSelectComponent implements OnInit, OnDestroy {
    @Input() name: string;
    @Input() model: UserGroup[];
    @Input() required?: boolean;
    @Output() modelChange = new EventEmitter();

    loading: boolean;
    groups: UserGroup[];
    private groupsSubscription: Subscription;

    constructor(private gs: UserGroupsService) {}

    modelChanged() {
        this.modelChange.emit(this.model);
    }

    ngOnInit() {
        this.loading = true;
        this.gs.refreshUserGroups();
        this.groupsSubscription = this.gs.userGroups.subscribe(groups => {
            this.groups = [...groups];
            this.loading = false;
        });
    }

    ngOnDestroy() {
        this.groupsSubscription.unsubscribe();
    }
}
