import { Component, Input, OnInit, OnChanges, SimpleChanges } from "@angular/core";

@Component({
    selector: "zx-numeric-col",
    template: `
        <span *ngIf="title !== '-'">
            <ngb-highlight title="{{ title }}" [result]="title" [term]="searchTerm"></ngb-highlight
            ><span class="unit">{{ unit }}</span>
        </span>
        <span *ngIf="title === '-'">-</span>
    `
})
export class ZxNumericColComponent implements OnInit, OnChanges {
    @Input() number: number | string;
    @Input() unit: string;
    @Input() searchTerm: string | string[];

    title: string;

    ngOnInit(): void {
        this.title = this.getTitle(this.number);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.number) {
            this.title = this.getTitle(this.number);
        }
    }

    getTitle(number: number | string): string {
        return typeof number === "number" ? number.toString() : number;
    }
}
