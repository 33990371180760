<form id="form" #form="ngForm" (ngSubmit)="form.valid && onSubmit()" [ngClass]="{ loading: saving }">
    <div class="modal-header">
        <h3 class="modal-title">{{ "ASSIGN_TARGET_TO" | translate }} "{{ channel.name }}"</h3>
        <button type="button" class="close" aria-label="Close" [disabled]="saving" (click)="activeModal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <!-- Target Table -->
        <div class="form-group mb-0">
            <label for="target" [ngClass]="{ 'is-invalid': form.submitted && !selectedTarget }">{{ "TARGET" | translate }}</label>
            <!-- Table -->
            <app-table-list
                [ngClass]="{ 'is-invalid': form.submitted && !selectedTarget }"
                [tableName]="'assing-channel-target'"
                [displayTableName]="'TARGETS' | translate"
                [data]="allTargets | async"
                [(tableSchema)]="tableColumnsSchema"
                [showSelectionCheckbox]="false"
                [showReport]="false"
                [autoRows]="false"
                [useAsInput]="true"
                [refreshing]="loading"
                (currentSortDirection)="onSort($event)"
                (rowSelected)="selectRow($event)"
            >
            </app-table-list>
            <div *ngIf="form.submitted && !selectedTarget" class="invalid-feedback">
                <div>{{ "TARGET" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
            </div>
        </div>

        <ngb-alert [dismissible]="false" [type]="'info'" *ngIf="!targets.length && !loading">
            <strong>{{ "INFO" | translate }}</strong> - {{ "NO_TARGETS_AVAILABLE_MATCHING_THIS_CHANNEL_TYPE" | translate }}
            <span *ngIf="!channel.delivery">({{ "HTTP" | translate }})</span>
            <span *ngIf="channel.delivery">({{ "RTMP" | translate }}, {{ "PULL" | translate }}, {{ "PUSH" | translate }})</span>
        </ngb-alert>
        <div *ngIf="selectedTarget && selectedTarget.target && selectedTarget.delivery">
            <div *ngIf="selectedTarget.target.delivery_channel_id != null">
                <ngb-alert class="mt-2" [dismissible]="false" [type]="'warning'">
                    <strong>{{ "WARNING" | translate }}</strong> - {{ "THIS_TARGET_IS_ALREADY_ASSIGNED_TO" | translate }}
                    {{ selectedTarget.target.deliveryChannel.name }}
                </ngb-alert>
            </div>
            <!-- Source preference -->
            <div class="form-group pt-1" *ngIf="channel && channel.delivery">
                <label for="source_preference">
                    {{ "SOURCE_PREFERENCE" | translate }}
                </label>
                <zx-delivery-channel-source-select
                    id="preferred_source"
                    name="preferred_source"
                    [channel_id]="channel.id"
                    [(model)]="selectedTarget.target.preferred_source"
                    [disabled]="!channel"
                    [allowPreferenceSelection]="selectedTarget.pull"
                >
                </zx-delivery-channel-source-select>
            </div>
        </div>
        <div *ngIf="selectedTarget && selectedTarget.target && (selectedTarget.rtmp || selectedTarget.udp_rtp) && channel.medialive">
            <!-- Transcoding Profile -->
            <div class="form-group mt-2">
                <label for="transcodingProfile" [ngClass]="{ 'is-invalid': form.submitted && form.controls.transcodingProfile?.errors }"
                    >{{ "TRANSCODING_PROFILE" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                    ><fa-icon icon="redo" size="sm" [ngbTooltip]="RestartContent" triggers="hover click" [closeDelay]="500"></fa-icon
                    ><ng-template #RestartContent>{{ "RESTART_REQUIRED" | translate }}</ng-template></label
                >
                <zx-transcoding-profile-select
                    name="transcodingProfile"
                    [(model)]="transcodeProfile"
                    required="true"
                    [ngClass]="{ 'is-invalid': form.submitted && form.controls.transcodingProfile?.errors }"
                ></zx-transcoding-profile-select>
                <div *ngIf="form.controls.transcodingProfile?.invalid" class="invalid-feedback">
                    <div *ngIf="form.controls.transcodingProfile?.errors.required">{{ "PROFILE" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                </div>
                <!-- Source preference -->
                <div class="form-group pt-1" *ngIf="channel && channel.delivery">
                    <label for="source_preference">
                        {{ "SOURCE_PREFERENCE" | translate }}
                    </label>
                    <zx-delivery-channel-source-select
                        id="preferred_source"
                        name="preferred_source"
                        [channel_id]="channel.id"
                        [(model)]="selectedTarget.target.preferred_source"
                        [disabled]="!channel"
                        [allowPreferenceSelection]="selectedTarget.pull"
                    >
                    </zx-delivery-channel-source-select>
                </div>
            </div>
        </div>
        <div *ngIf="selectedTarget && selectedTarget.target && selectedTarget.adaptive">
            <div *ngIf="selectedTarget && selectedTarget.target && selectedTarget.target.adaptive_channel_id != null">
                <ngb-alert class="mt-2" [dismissible]="false" [type]="'warning'">
                    <strong>{{ "WARNING" | translate }}</strong> - {{ "THIS_TARGET_IS_ALREADY_ASSIGNED_TO" | translate }}
                    {{ selectedTarget.target.adaptiveChannel.name }}
                </ngb-alert>
            </div>
        </div>
        <div *ngIf="selectedTarget && selectedTarget.target && selectedTarget.target.mediaconnect_flow_id != null">
            <ngb-alert class="mt-2" [dismissible]="false" [type]="'warning'">
                <strong>{{ "WARNING" | translate }}</strong> - {{ "THIS_TARGET_IS_ALREADY_ASSIGNED_TO" | translate }}
                {{ selectedTarget.target.mediaconnectFlow.name }}
            </ngb-alert>
        </div>
        <div class="form-group mb-0 mt-2" *ngIf="channel.delivery || channel.adaptive">
            <div *ngIf="!selectedTarget || (!selectedTarget.ndi && !selectedTarget.pull)" class="form-check">
                <input type="checkbox" class="form-check-input" id="content_analysis" name="content_analysis" [(ngModel)]="forceSameBX" />
                <label class="form-check-label" for="content_analysis">{{ "FORCE_SAME_BX" | translate }} </label>
            </div>
        </div>
        <app-error [marginBottom]="false" [marginTop]="true"></app-error>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" [disabled]="saving" (click)="activeModal.close()">
            <fa-icon icon="times"></fa-icon>
            {{ "CANCEL" | translate }}
        </button>
        <button type="button" class="btn btn-primary" ngbAutofocus [disabled]="saving" zmid="form-submit" type="submit">
            <fa-icon icon="link" size="sm"></fa-icon>
            {{ "ASSIGN" | translate }}<span *ngIf="saving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
        </button>
    </div>
</form>
