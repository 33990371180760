import { Pipe, PipeTransform } from "@angular/core";
import { LocationInfo } from "../pages/maps/map";

@Pipe({
    name: "locationDisplayName"
})
export class LocationDisplayNamePipe implements PipeTransform {
    transform(location?: { ip?: LocationInfo; address?: LocationInfo }): string {
        if (!location || (!location?.ip && !location?.address)) return "-";
        const loc = location.address || location.ip;
        if (!loc) return "-";
        return loc.display_name;
    }
}
