<div class="form-group" *ngIf="canEdit">
    <h5 translate>{{ label }}</h5>
    <div *ngIf="user || password">
        <a href="javascript:void(0)" (click)="pwVisible = true" *ngIf="!pwVisible">{{ "SHOW" | translate }}</a>
        <span *ngIf="!pwVisible">&nbsp;/&nbsp;</span>
        <a href="javascript:void(0)" (click)="copy(password)" *ngIf="!pwVisible">{{ passwordLabel }}</a>
        <span *ngIf="pwVisible"
            >{{ user }}
            <a href="javascript:void(0)" title="{{ userLabel }}" (click)="copy(user)"><fa-icon icon="clipboard" size="sm"></fa-icon></a>
            <span *ngIf="password">
                / {{ password }}
                <a href="javascript:void(0)" title="{{ passwordLabel }}" (click)="copy(password)"><fa-icon icon="clipboard" size="sm"></fa-icon></a
                ><app-password-strength-icon
                    *ngIf="showPasswordStrength"
                    class="d-inline-block ms-1"
                    [password]="password"
                ></app-password-strength-icon></span></span
        ><a class="ms-1" href="javascript:void(0)" (click)="pwVisible = false" *ngIf="pwVisible">{{ "HIDE" | translate }}</a>
    </div>
    <div *ngIf="!user && !password">
        {{ "NONE" | translate }}
    </div>
</div>
