import { Pipe, PipeTransform, inject } from "@angular/core";
import { TimeZoneService, dateFormats } from "../services/time-zone.service";

@Pipe({
    name: "dateTimeZone"
})
export class DateTimeZonePipe implements PipeTransform {
    private timeZoneService = inject(TimeZoneService);

    transform(date: string, dateFormat: dateFormats): string {
        return this.timeZoneService.computeDateToTimeZoneReturnString(date, dateFormat);
    }
}
