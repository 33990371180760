import { inject, Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import moment from "moment";
import { map } from "rxjs";
import { EventFilter } from "../components/events-filter-form/events-filter-form.component";
import { LiveEventFilter } from "../components/live-events-filter-form/live-events-filter-form.component";
import { TimeZoneService } from "./time-zone.service";

@Injectable({
    providedIn: "root"
})
export class QueryParamsService {
    private route = inject(ActivatedRoute);
    private router = inject(Router);
    private timeZoneService = inject(TimeZoneService);
    private queryParams$ = this.route.queryParams;
    eventsParams$ = this.queryParams$.pipe(map(params => this.filterEventsParams(params, this.timeZoneService)));
    liveEventsParams$ = this.queryParams$.pipe(map(params => this.filterEventsParams(params, this.timeZoneService)));

    private filterEventsParams(
        params: Record<string, string>,
        timeZoneService: TimeZoneService
    ): Partial<EventFilter & LiveEventFilter> {
        const timeZone = timeZoneService.selectedTimeZone();
        const eventParams: Partial<EventFilter & LiveEventFilter> = {};
        if (params.msgFilter) eventParams.msgFilter = params.msgFilter;
        // TODO: just timezone to calc date here
        if (params.fromDate)
            eventParams.fromDate = moment.tz(
                params.fromDate,
                timeZone?.utc[0] ?? Intl.DateTimeFormat().resolvedOptions().timeZone
            );
        if (params.toDate)
            eventParams.toDate = moment.tz(
                params.toDate,
                timeZone?.utc[0] ?? Intl.DateTimeFormat().resolvedOptions().timeZone
            );
        if (params.objectType) eventParams.objectType = { name: params.objectType };
        if (params.resourceTags) eventParams.resourceTags = params.resourceTags.split(",").map(Number);
        if (params.startsIn) eventParams.startsIn = Number(params.startsIn);

        if (params.msgTypes) {
            const enabledMsgTypes = params.msgTypes.split(",");

            const msgTypes = enabledMsgTypes.reduce((acc, type) => {
                acc[type] = true;
                return acc;
            }, {} as EventFilter["msgTypes"]);
            eventParams.msgTypes = msgTypes;
        }

        if (params.stageStates) {
            const enabledStageStates = params.stageStates.split(",");

            const stageStates = enabledStageStates.reduce((acc, type) => {
                acc[type] = true;
                return acc;
            }, {} as LiveEventFilter["stageStates"]);
            eventParams.stageStates = stageStates;
        }

        return eventParams;
    }

    updateEventParams(eventParams: EventFilter | LiveEventFilter) {
        const params: Partial<Record<keyof EventFilter, string> & Record<keyof LiveEventFilter, string>> = {};
        if (eventParams.msgFilter) params.msgFilter = eventParams.msgFilter;
        if (eventParams.fromDate) params.fromDate = eventParams.fromDate.toISOString();
        if (eventParams.toDate) params.toDate = eventParams.toDate.toISOString();
        if (eventParams.resourceTags) params.resourceTags = eventParams.resourceTags.toString();
        if ("objectType" in eventParams && eventParams.objectType) params.objectType = eventParams.objectType.name;
        if (eventParams.startsIn) params.startsIn = String(eventParams.startsIn);

        if ("msgTypes" in eventParams && eventParams.msgTypes) {
            const msgTypesNames = [];
            for (const msgType in eventParams.msgTypes) if (eventParams.msgTypes[msgType]) msgTypesNames.push(msgType);

            if (msgTypesNames.length) {
                params.msgTypes = msgTypesNames.join(",");
            }
        }

        if ("stageStates" in eventParams && eventParams.stageStates) {
            const stageStatesNames = [];
            for (const stageState in eventParams.stageStates)
                if (eventParams.stageStates[stageState]) stageStatesNames.push(stageState);

            if (stageStatesNames.length) {
                params.stageStates = stageStatesNames.join(",");
            }
        }
        this.updateParams(params);
    }

    private updateParams(params: Record<string, string>) {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: params
        });
    }
}
