<zx-feeder
    [model]="object"
    [showLink]="true"
    *ngIf="
        (type === 'ZIXI EDGE DEVICE' || type === 'OBJECT' || type === 'FEEDER') &&
        ((object.type === 'feeder' && !object.superType) || object.superType === 'feeder')
    "
    [showTag]="type === 'OBJECT'"
></zx-feeder>
<zx-receiver
    [model]="object"
    [showLink]="true"
    *ngIf="
        (type === 'ZIXI EDGE DEVICE' || type === 'OBJECT' || type === 'RECEIVER') &&
        ((object.type === 'receiver' && !object.superType) || object.superType === 'receiver')
    "
    [showTag]="type === 'OBJECT'"
></zx-receiver>
<zx-zec
    [model]="object"
    [showLink]="true"
    *ngIf="(type === 'ZIXI EDGE DEVICE' || type === 'OBJECT' || type === 'ZEC') && ((object.type === 'zec' && !object.superType) || object.superType === 'zec')"
    [showTag]="type === 'OBJECT'"
></zx-zec>
<zx-cluster [model]="object" [showLink]="true" *ngIf="type === 'CLUSTER'"></zx-cluster>
<zx-broadcaster
    [model]="object"
    [showLink]="true"
    *ngIf="(type === 'BROADCASTER' || type === 'OBJECT') && !object.type && object.broadcaster_cluster_id"
    [showTag]="type === 'OBJECT'"
></zx-broadcaster>
<zx-adaptive
    [model]="object"
    [showLink]="true"
    *ngIf="
        (type === 'CHANNEL' && (object.adaptive || object.transcoded)) ||
        (type === 'OBJECT' && (object.adaptive || object.transcoded) && object.superType === 'channel')
    "
    [showTag]="type === 'OBJECT'"
></zx-adaptive>
<zx-delivery
    [model]="object"
    [showLink]="true"
    *ngIf="(type === 'CHANNEL' && object.delivery) || (type === 'OBJECT' && (object.delivery || object.type === 'delivery') && object.superType === 'channel')"
    [showTag]="type === 'OBJECT'"
></zx-delivery>
<zx-failover-channel
    [model]="object"
    [showLink]="true"
    *ngIf="(type === 'CHANNEL' && object.failover) || (type === 'OBJECT' && object.failover && object.superType === 'channel')"
    [showTag]="type === 'OBJECT'"
></zx-failover-channel>
<zx-mediaconnect-flow
    [model]="object"
    [showLink]="true"
    *ngIf="(type === 'CHANNEL' && object.mediaconnect) || (type === 'OBJECT' && object.mediaconnect && object.superType === 'channel')"
    [showTag]="type === 'OBJECT'"
></zx-mediaconnect-flow>
<zx-medialive-channel
    [model]="object"
    [showLink]="true"
    *ngIf="(type === 'CHANNEL' && object.medialive) || (type === 'OBJECT' && object.medialive && object.superType === 'channel')"
    [showTag]="type === 'OBJECT'"
></zx-medialive-channel>
<zx-source
    [model]="object"
    [showLink]="true"
    *ngIf="type === 'SOURCE' || (type === 'OBJECT' && object.superType === 'source')"
    [showTag]="type === 'OBJECT'"
></zx-source>
<zx-target
    [model]="{ target: object, apiType: targetsService.getTargetApiType(object) }"
    [showLink]="true"
    *ngIf="type === 'OBJECT' && object.superType === 'target'"
    [showTag]="true"
></zx-target>
<zx-target [model]="object" [showLink]="true" *ngIf="type === 'TARGET'"></zx-target>
<zx-live-event-name [model]="object" [showLink]="true" *ngIf="type === 'LIVE_EVENT'"></zx-live-event-name>
<zx-remote-access
    [model]="object"
    [showLink]="true"
    *ngIf="type === 'REMOTE_ACCESS' || (type === 'OBJECT' && object.superType === 'remote access')"
    [showTag]="type === 'OBJECT'"
></zx-remote-access>
<span *ngIf="type === 'USER'">{{ object.name }} ({{ object.email }})</span>
<span
    *ngIf="
        type === 'TAG' ||
        type === 'API_KEY' ||
        type === 'REPORT' ||
        type === 'SSH_KEY' ||
        type === 'USER_GROUP' ||
        type === 'ROLE' ||
        type === 'TASK_SET' ||
        type === 'SSO' ||
        type === 'PID_MAPPING_PROFILE' ||
        type === 'ALERT_PROFILE' ||
        type === 'MAP' ||
        type === 'INCIDENT' ||
        type === 'ANNOUNCEMENTS_CONFIGURATIONS' ||
        type === 'ACCOUNT' ||
        type === 'MULTI_VIEWER' ||
        type === 'TRANSCODING_PROFILE'
    "
    >{{ object.name }}</span
>
<span *ngIf="type === 'NETWORK'">{{ object.network_id }}</span>
