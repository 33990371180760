import { MarkerType } from "@xyflow/react";
import { MermaidObject } from "./react-flow-diagram.component";
import { LayoutOptions } from "elkjs";
import { SourceNodeData } from "./react-components/SourceNode";
import { MediaConnectNodeData } from "./react-components/MediaconnectSourceNode";
import { CustomElkFlowEdge, RecursiveElkNode, nodeData } from "./types";

const edgeType = "smart";

const sharedElkOptions: LayoutOptions = {
    "elk.layered.spacing.baseValue": "32",
    "elk.edgeRouting": "ORTHOGONAL",
    "elk.layered.spacing.nodeNodeBetweenLayers": "64",
    "elk.spacing.nodeNode": "64",
    "elk.layered.mergeEdges": "false",
    "elk.layered.mergeHierarchyEdges": "false",
    "elk.layered.considerModelOrder.crossingCounterNodeInfluence": "0.001",
    "elk.layered.considerModelOrder.crossingCounterPortInfluence": "0.001",
    "elk.layered.nodePlacement.bk.fixedAlignment": "BALANCED",
    "elk.layered.nodePlacement.bk.edgeStraightening": "IMPROVE_STRAIGHTNESS"
};

export const elkOptions: LayoutOptions = {
    ...sharedElkOptions,
    "elk.algorithm": "layered",
    "elk.hierarchyHandling": "INCLUDE_CHILDREN",
    "elk.layered.considerModelOrder.strategy": "NODES_AND_EDGES"
};

const topElkOptions: LayoutOptions = {
    ...sharedElkOptions,
    "elk.padding": "[top=32.0,left=16.0,bottom=16.0,right=16.0]"
};
const midElkOptions: LayoutOptions = {
    ...sharedElkOptions,
    "elk.padding": "[top=32.0,left=16.0,bottom=16.0,right=16.0]"
};
const btmElkOptions: LayoutOptions = {
    ...sharedElkOptions,
    "elk.portConstraints": "FIXED_SIDE"
};

type ClustersObj = {
    [key: string]: {
        [key: string]: string[];
    };
};

// Function to find the parent node of the edge
const findEdgeParent = (clusters: ClustersObj, edge: CustomElkFlowEdge) => {
    if (clusters) {
        const common = findClosestCommonParent(clusters, edge.source, edge.target);

        if (!common.commonParentPath || common.commonParentPath === "") {
            return false;
        } else {
            const pathArray = common.commonParentPath.split(".");
            if (pathArray.length === 1) {
                return pathArray[0];
            } else {
                const lastID = pathArray[pathArray.length - 1];
                return lastID;
            }
        }
    }
};

// Function to find the closest common parent path from two paths
const findCommonParentPath = (path1: string, path2: string) => {
    const pathArray1 = path1.split(".");
    const pathArray2 = path2.split(".");
    let commonPath = "";

    for (let i = 0; i < Math.min(pathArray1.length, pathArray2.length); i++) {
        if (pathArray1[i] === pathArray2[i]) {
            commonPath = commonPath ? `${commonPath}.${pathArray1[i]}` : pathArray1[i];
        } else {
            break;
        }
    }

    return commonPath;
};

// Function to find the closest common parent of two target values
const findClosestCommonParent = (obj: ClustersObj, target1: string, target2: string) => {
    const result1 = findValuePathInObject(obj, target1);
    const result2 = findValuePathInObject(obj, target2);

    if (result1.found && result2.found) {
        const commonParentPath = findCommonParentPath(result1.path, result2.path);
        return { found: true, commonParentPath };
    } else {
        return { found: false, commonParentPath: null };
    }
};

// Function to find the path of a target value in the nested object
const findValuePathInObject = (
    obj:
        | ClustersObj
        | {
              [key: string]: string[];
          },
    target: string,
    currentPath = ""
): { found: boolean; path: string } => {
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const value = obj[key];

            let newKey = "";
            if (key === "No Active Broadcaster") newKey = key + "-" + value[0];
            else newKey = key;

            const newPath = currentPath ? `${currentPath}.${newKey}` : newKey;

            if (Array.isArray(value)) {
                if (value.includes(target)) {
                    return { found: true, path: newPath };
                }
            } else if (typeof value === "object" && value !== null) {
                const result = findValuePathInObject(value, target, newPath);
                if (result.found) {
                    return result;
                }
            }
        }
    }
    return { found: false, path: "" };
};

const nodeSettings = (node: RecursiveElkNode, edges: CustomElkFlowEdge[], direction: string, showDetails: boolean, showThumbnails: boolean) => {
    node.width = 280;
    node.height = 240;

    const sourcePorts: { id: string; properties: { side: string } }[] = [];
    const sourceEdges = edges.filter(edge => edge.source === node.id);
    for (const edge of sourceEdges) {
        sourcePorts.push({
            id: edge.sourceHandle ?? "",
            properties: { side: direction === "DOWN" ? "SOUTH" : "EAST" }
        });
    }

    const targetPorts: { id: string; properties: { side: string } }[] = [];
    const targetEdges = edges.filter(edge => edge.target === node.id);
    for (const edge of targetEdges) {
        targetPorts.push({
            id: edge.targetHandle ?? "",
            properties: { side: direction === "DOWN" ? "NORTH" : "WEST" }
        });
    }

    node.data.sourceHandles = [...sourcePorts];
    node.data.targetHandles = [...targetPorts];
    node.data.direction = direction;
    node.ports = [...targetPorts, ...sourcePorts];
    node.properties = {
        "elk.portConstraints": "FIXED_SIDE"
    };

    node.layoutOptions = {
        ...btmElkOptions
    };

    const updatedNode = setNodeTypeAndSize(node, showDetails, showThumbnails);
    node = updatedNode;

    return node;
};

// check if node exists at any level
const nestedNodeExists = (id: string, nestedNodes: (RecursiveElkNode | undefined)[]) => {
    for (const n1 of nestedNodes) {
        if (n1?.id === id) return n1;
        if (n1?.children) {
            for (const n2 of n1?.children) {
                if (n2.id === id) return n2;
                if (n2.children) {
                    for (const n3 of n2?.children) {
                        if (n3.id === id) return n3;
                    }
                }
            }
        }
    }
    return false;
};

const setNodeTypeAndSize = (node: RecursiveElkNode, showDetails?: boolean, showThumbnails?: boolean) => {
    if (!showDetails) node.data.fields = [];
    let height = 60 + node.data?.fields?.length * 25;
    if (node.data.type && node.data.type !== "source" && node.data.type !== "mediaconnect_source") {
        node.type = node.data.type;
        node.height = height;
        node.style = {
            width: 280,
            height: height
        };
    } else if (node.data.type === "source") {
        const nodeData = node.data as SourceNodeData;
        node.type = "source";
        if (!showThumbnails) {
            nodeData.showThumbnail = false;
            height = 63 + node.data.fields.length * 25;
        } else {
            nodeData.showThumbnail = true;
            height = 63 + (!nodeData.failoverChannel ? 158 : 0) + node.data.fields.length * 25;
        }
        node.height = height;
        node.style = {
            width: 280,
            height: height
        };
        node.data = nodeData;
    } else if (node.data.type === "mediaconnect_source") {
        const nodeData = node.data as MediaConnectNodeData;
        node.type = "mediaconnect_source";
        if (!showThumbnails) {
            nodeData.showThumbnail = false;
            height = 63 + node.data.fields.length * 25;
        } else {
            nodeData.showThumbnail = true;
            height = 63 + (nodeData.object.elemental_link_id ? 158 : 0) + node.data.fields.length * 25;
        }
        node.height = height;
        node.style = {
            width: 280,
            height: height
        };
    } else node.type = "group";
    return node;
};

export const formatData = (
    mermaidObject: MermaidObject,
    direction: string,
    showDetails: boolean,
    showBroadcasters: boolean,
    showThumbnails: boolean,
    showClusters: boolean
) => {
    let nodes: RecursiveElkNode[] = [];
    let edges: CustomElkFlowEdge[] = [];

    // get nodes
    if (mermaidObject.objects) {
        const entries = Object.entries(mermaidObject.objects);
        for (const [key, value] of entries) {
            if (!nodes.find(node => node.id === key)) {
                if (!(!showBroadcasters && value.server)) {
                    nodes.push({
                        id: key,
                        x: 0,
                        y: 0,
                        type: value.type,
                        data: {
                            ...(value.data as nodeData)
                        }
                    });
                }
            }
        }
    }

    // get edges
    if (mermaidObject.paths) {
        const entries = Object.entries(mermaidObject.paths);
        for (const [key, value] of entries) {
            for (const [k, v] of Object.entries(value)) {
                if (!edges.find(edge => edge.id === key + "-" + k + "-ID")) {
                    if (nodes.find(node => node.id === key) && nodes.find(node => node.id === k)) {
                        let isAnimated = false;
                        const nodeOne = nodes.find(node => node.id === key);
                        const nodeTwo = nodes.find(node => node.id === k);
                        if (
                            (nodeOne?.data.status === "good" || nodeOne?.data.status === "warning") &&
                            (nodeTwo?.data.status === "good" || nodeTwo?.data.status === "warning")
                        )
                            isAnimated = true;

                        const def = {
                            id: key + "-" + k + "-ID",
                            source: key,
                            target: k,
                            type: edgeType,
                            animated: v.isActive === true ? true : v.isActive === false ? false : isAnimated ? true : false,
                            deletable: false,
                            selectable: false,
                            downstream: v.isDownStream ?? false,
                            sourceHandle: key + "-" + k + "-" + "sourceHandle",
                            targetHandle: key + "-" + k + "-" + "targetHandle",
                            markerEnd: {
                                type: v.isDownStream ? MarkerType.Arrow : MarkerType.ArrowClosed,
                                width: 12,
                                height: 12,
                                color: "#757575",
                                className: v.isDownStream ? "downstream" : ""
                            },
                            label: "",
                            sources: [key],
                            targets: [k]
                        };

                        if (v.tags?.length) {
                            def.label = v.tags.join(", ");
                        }
                        edges.push(def);
                    }
                }
            }
        }
    }

    // make nested structure as required by elkjs
    let children: (RecursiveElkNode | undefined)[] = [];
    let updatedClusters = {};

    if (mermaidObject.clusters) {
        const entries = Object.entries(mermaidObject.clusters);

        for (const [key, value] of entries) {
            if (!children.find(node => node?.id === key)) {
                const grandchildren: RecursiveElkNode[] = [];
                for (const [k, v] of Object.entries(value)) {
                    const ggc: RecursiveElkNode[] = [];
                    for (const i in v) {
                        let node = nodes.find(node => node.id === v[i]);
                        if (node) {
                            node.group = k === "No Active Broadcaster" ? "No Active Broadcaster-" + v[0] : k;
                            const updatedNode = nodeSettings(node, edges, direction, showDetails, showThumbnails);
                            node = updatedNode;
                            if (!ggc.find(node => node.id === v[i])) ggc.push(node);
                        }
                    }

                    grandchildren.push({
                        id: k === "No Active Broadcaster" ? "No Active Broadcaster-" + v[0] : k,
                        data: { label: k === "No Active Broadcaster" ? k : k.split("(")[0] } as nodeData,
                        group: key,
                        x: 0,
                        y: 0,
                        width: 100,
                        height: 100,
                        type: "group",
                        edges: [],
                        children: ggc,
                        layoutOptions: {
                            ...midElkOptions
                        }
                    });
                }

                children.push({
                    id: key,
                    data: { label: "Cluster - " + key.split("(")[0] } as nodeData,
                    x: 0,
                    y: 0,
                    width: 100,
                    height: 100,
                    type: "group",
                    edges: [],
                    children: grandchildren,
                    layoutOptions: {
                        ...topElkOptions
                    }
                });
            }
        }

        if (!showClusters) {
            children = children.flatMap(childNode => (childNode?.type === "group" ? childNode.children : [childNode]));
            const entries = Object.entries(mermaidObject.clusters);
            for (const [key, value] of entries) {
                updatedClusters = { ...updatedClusters, ...value };
            }
        } else updatedClusters = mermaidObject.clusters;
    }

    // add non nested nodes
    if (nodes && nodes.length) {
        for (const node of nodes) {
            const exists = nestedNodeExists(node.id, children);
            if (!exists) {
                const updatedNode = nodeSettings(node, edges, direction, showDetails, showThumbnails);
                if (!showClusters) children.unshift(updatedNode);
                else children.push(updatedNode);
            }
        }
    }

    // add edges to nested nodes
    const nonNestedEdges: CustomElkFlowEdge[] = [];
    for (const edge of edges) {
        const result = findEdgeParent(updatedClusters, edge);
        if (!result) {
            nonNestedEdges.push(edge);
        } else {
            const exists = nestedNodeExists(result, children);
            if (exists) {
                exists?.edges?.push(edge);
            }
        }
    }

    return { children, edges: nonNestedEdges };
};

// Code to update node and edge data
// not currently used, but is useful if we want to update diagram data without updating/changing layout
/* const updateNodeData = async (showDet: boolean, showThumbs: boolean) => {
    setNodes(nds =>
        nds.map(node => {
            const keys = Object.keys(props.objects);
            const index = keys.indexOf(node.id);
            const value = props.objects[keys[index]];
            if (value?.data) {
                node.data = {
                    ...node.data,
                    ...value.data
                };
            }
            const resized = setNodeTypeAndSize(node, showDet, showThumbs);
            return resized;
        })
    );
};

const updateEdgeData = async () => {
    setEdges(edges =>
        edges.map(edge => {
            const keys = Object.keys(props.objects);
            // get source node
            const sourceIndex = keys.indexOf(edge.source);
            const sourceNode = props.objects[keys[sourceIndex]];
            // get target node
            const targetIndex = keys.indexOf(edge.target);
            const targetNode = props.objects[keys[targetIndex]];

            const isAnimated =
                (sourceNode?.data?.status === "good" || sourceNode?.data?.status === "warning") &&
                (targetNode?.data?.status === "good" || targetNode?.data?.status === "warning");

            // get path
            const pathsKeys = Object.keys(props.paths);
            const sI = pathsKeys.indexOf(edge.source);
            const sourcePath = props.paths[pathsKeys[sI]];
            const pathKeys = Object.keys(sourcePath);
            const tI = pathKeys.indexOf(edge.target);
            const targetPath = sourcePath[pathKeys[tI]];

            // update animated setting based on new data
            edge.animated = targetPath.isActive ? true : isAnimated ? true : false;

            return edge;
        })
    );
}; */

// If diagram base object changed, redraw diagram
/* if (object && (props.baseObject?.id !== object.id || props.baseObject?.type !== object.type)) {
    fetchData();
} else {
    if (props && oldProps) {
        const propsEqual = _.isEqual(props.paths, oldProps.paths);
        const objectsEqual = _.isEqual(Object.keys(props.objects), Object.keys(oldProps.objects));
        // check if new/missing nodes or edges, if so run fetchData
        if (propsEqual && objectsEqual) {
            // updates nodes without layout redraw
            updateNodeData(showDetails, showThumbnails);
            // updates edges without layout redraw
            updateEdgeData();
        } else {
            // redraws diagram to update layout
            fetchData();
        }
    }
} */
