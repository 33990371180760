import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { RecoveryState } from "src/app/models/shared";

export enum LiveEventActions {
    NextStage = "next_stage",
    EndEvent = "end_event"
}

@Component({
    selector: "app-zx-action-buttons",
    templateUrl: "./zx-action-buttons.component.html"
})
export class ZxActionButtonsComponent implements OnInit {
    @Output() multiToggleMute: EventEmitter<boolean> = new EventEmitter();
    @Output() multiEdit: EventEmitter<void> = new EventEmitter();
    @Output() multiEnable: EventEmitter<void> = new EventEmitter();
    @Output() multiToggleState: EventEmitter<boolean> = new EventEmitter();
    @Output() multiDelete: EventEmitter<void> = new EventEmitter();
    @Output() multiReset: EventEmitter<void> = new EventEmitter();
    @Output() switchChannel: EventEmitter<void> = new EventEmitter();
    @Output() disasterRecoveryClick: EventEmitter<void> = new EventEmitter();
    @Output() multiLiveEvents: EventEmitter<LiveEventActions> = new EventEmitter();
    @Input() toggleMuteButton = true;
    @Input() toggleStateButton = true;
    @Input() enableButton = true;
    @Input() editButton = true;
    @Input() deleteButton = true;
    @Input() resetButton = false;
    @Input() switchChannelButton = false;
    @Input() canSwitchChannel = false;
    @Input() disasterRecovery: RecoveryState | boolean | undefined = undefined;
    @Input() multiLiveEventButton = false;

    LiveEventActions = LiveEventActions;
    buttonCount = 0;

    ngOnInit() {
        if (this.toggleMuteButton) this.buttonCount++;
        if (this.toggleStateButton) this.buttonCount++;
        if (this.enableButton) this.buttonCount++;
        if (this.editButton) this.buttonCount++;
        if (this.deleteButton) this.buttonCount++;
        if (this.resetButton) this.buttonCount++;
        if (this.switchChannelButton) this.buttonCount++;
        if (this.disasterRecovery) this.buttonCount++;
        if (this.multiLiveEventButton) this.buttonCount++;
    }

    clickMultiToggleMute(mute: boolean) {
        this.multiToggleMute.emit(mute);
    }

    clickMultiEdit() {
        this.multiEdit.emit();
    }

    clickMultiEnable() {
        this.multiEnable.emit();
    }

    clickMultiToggleState(enable: boolean) {
        this.multiToggleState.emit(enable);
    }

    clickMultiDelete() {
        this.multiDelete.emit();
    }

    clickMultiReset() {
        this.multiReset.emit();
    }

    clickSwitchChannel() {
        this.switchChannel.emit();
    }

    clickDisasterRecovery() {
        this.disasterRecoveryClick.emit();
    }

    clickMultiNextStage(action: LiveEventActions) {
        this.multiLiveEvents.emit(action);
    }
}
