import { Tag, Broadcaster, Source, MediaConnectSource } from "../../models/shared";
import { Feeder, Zec } from "../zecs/zecs/zec";
import { Receiver } from "../zecs/zecs/zec";
import { TargetObjectType } from "../channels/channel";
//
import * as L from "leaflet";
import "leaflet.featuregroup.subgroup";
import "leaflet.markercluster";

export class Map {
    _frontData?: {
        numberOfLayers?: number;
    };
    id: number;
    name: string;
    resource_tag_ids: number[];
    resourceTags: Tag[];
    type: string;
    baseMap: string;
    region: string;
    config: {
        mediaconnect_flows?: number[];
        adaptive_channels?: number[];
        delivery_channels?: number[];
        groups?: {
            id: number;
            name: string;
            feeders?: number[];
            receivers?: number[];
            zecs?: number[];
            sources?: number[];
            mediaconnect_sources?: number[];
            broadcasters?: number[];
            targets?: string[];
        }[];
        leafletData?: LayerGroupData[];
    };
    configVersion: number;
    created_at: string;
    updated_at: string;
    start_clustered: number | boolean;
    hasFullDetails?: boolean;
}

export class LayerInfo {
    name: string;
    channel?: boolean;
    channelID?: number;
    channelType?: string;
    layerID?: number;
}

export class LayerGroupData {
    layerInfo: LayerInfo;
    visible: boolean;
    layers: MarkerData[];
}

export class LayerGroupObject {
    name?: string;
    channel?: boolean;
    channelID?: number;
    channelType?: string;
    layerID?: number;
    feeders?: Feeder[];
    receivers?: Receiver[];
    zecs?: Zec[];
    sources?: Source[];
    mediaconnect_sources?: MediaConnectSource[];
    broadcasters?: Broadcaster[];
    targets?: { target: TargetObjectType; type: string }[];
}

export class MarkerData {
    marker: true;
    id: number;
    type: string; // feeder, broadcaster, target, etc...
    subtype: string; // target type, source type, channel type etc...
    latlng: L.LatLng;
    layerInfo: LayerInfo;
}

export class MapPoint {
    latitude: number;
    longitude: number;
}

export class Nominatim {
    address?: {
        village?: string;
        hamlet?: string;
        town?: string;
        city?: string;
        city_district?: string;
        construction?: string;
        continent?: string;
        country?: string;
        country_code?: string;
        house_number?: string | number;
        neighbourhood: string;
        postcode?: string | number;
        public_building?: string;
        state?: string;
        suburb?: string;
    };
    boundingbox: number[] | string[];
    class: string;
    display_name: string;
    icon: string;
    importance: number;
    lat: number;
    licence: string;
    lon: number;
    osm_id: number;
    osm_type: string;
    place_id: number;
    type: string;
}

export class NominatimResponse {
    constructor(
        public latitude: number,
        public longitude: number,
        public displayName: string,
        public address: {
            village?: string;
            hamlet?: string;
            town?: string;
            city?: string;
            city_district?: string;
            construction?: string;
            continent?: string;
            country?: string;
            country_code?: string;
            house_number?: string | number;
            neighbourhood?: string;
            postcode?: string | number;
            public_building?: string;
            state?: string;
            suburb?: string;
        }
    ) {}
}

export class LocationInfo {
    object_id?: number;
    object_type?: string;
    ip_hash?: string;
    isp?: string;
    city?: string;
    region_code?: string;
    region_name?: string;
    country_code?: string;
    country_name?: string;
    continent_code?: string;
    continent_name?: string;
    country_flag?: string;
    display_name: string;
    source: string;
    longitude: number;
    latitude: number;
}

export class DataMarker extends L.Marker {
    data: DataMarkerData;

    constructor(latLng: L.LatLngExpression, options?: L.MarkerOptions, data?: DataMarkerData) {
        super(latLng, options);
        this.setData(data);
    }

    getData() {
        return this.data;
    }

    setData(data: DataMarkerData) {
        this.data = data;
    }
}

export interface DataMarkerData {
    type: string;
    id: number;
    subtype?: string;
    layerInfo: LayerInfo;
}
