import { Component, EventEmitter, Input, Output } from "@angular/core";
import { TableSchema } from "../table-list/table-list.component";
import { KeyMap, SharedResource } from "../../../models/shared";
import { TranslateService } from "@ngx-translate/core";
import { ZxDateTimeDisplayComponent } from "../zx-date-time-display/zx-date-time-display.component";
import { ZxTableActionsComponent } from "src/app/components/shared/zx-table-actions/zx-table-actions.component";
import { assignGenericLinkTextInputsFactory } from "src/app/components/shared/zx-table-actions/zx-table-actions.table-adapter";
import { assignDateTimeDisplayInputsFactory } from "../zx-date-time-display/zx-date-time-display.table-adapter";
import { DatePipe } from "@angular/common";
import { ModalService } from "../modals/modal.service";
import { SharedPageService } from "../../../services/shared-page.service";
import { firstValueFrom } from "rxjs";

@Component({
    selector: "app-shared-resource-list",
    templateUrl: "./shared-resource-list.component.html",
    providers: [DatePipe]
})
export class SharedResourceListComponent {
    @Input() activeSharing: SharedResource[];
    @Output() refreshFunction: EventEmitter<Promise<void>> = new EventEmitter();

    tableColumnsSchema: TableSchema<KeyMap<SharedResource>>[] = [
        {
            header: this.translateService.instant("NAME"),
            columnDef: "name",
            width: 80,
            visible: true,
            sortBy: (sharedResource: SharedResource) => sharedResource.name
        },
        {
            header: this.translateService.instant("START_TIME"),
            columnDef: "startTime",
            width: 80,
            visible: true,
            component: ZxDateTimeDisplayComponent,
            assignComponentsInputs: assignDateTimeDisplayInputsFactory<SharedResource>(
                sharedResource => sharedResource.start_time
            ),
            sortBy: (sharedResource: SharedResource) => new Date(sharedResource.start_time).getTime()
        },
        {
            header: this.translateService.instant("END_TIME"),
            columnDef: "endTime",
            width: 80,
            visible: true,
            component: ZxDateTimeDisplayComponent,
            assignComponentsInputs: assignDateTimeDisplayInputsFactory<SharedResource>(
                sharedResource => sharedResource.end_time
            ),
            sortBy: (sharedResource: SharedResource) => new Date(sharedResource.end_time).getTime()
        },
        {
            header: this.translateService.instant("ACTIONS"),
            columnDef: "actions",
            width: 40,
            align: "right",
            visible: true,
            stickyToLast: true,
            component: ZxTableActionsComponent,
            assignComponentsInputs: assignGenericLinkTextInputsFactory([
                {
                    icon: "arrows-rotate",
                    translateTitle: "REGENERATE_PASSWORD",
                    onClickHandler: (sharedResource: SharedResource) => this.regeneratePassword(sharedResource)
                },
                {
                    icon: "trash-alt",
                    translateTitle: "STOP_SHARING",
                    cssClasses: "text-danger",
                    onClickHandler: (sharedResource: SharedResource) => this.stopSharing(sharedResource)
                }
            ])
        }
    ];

    constructor(
        private translateService: TranslateService,
        private sharedPageService: SharedPageService,
        private modalService: ModalService
    ) {}

    async stopSharing(sharedResource: SharedResource) {
        await this.modalService.confirm(
            "STOP_SHARING",
            "RESOURCE",
            async () => {
                const isDeleted = await this.sharedPageService.deleteSharedResource(sharedResource);
                if (!isDeleted) {
                    return false;
                }
                await this.refreshFunction.emit();
            },
            null,
            { note: "STOP_SHARING_NOTE" }
        );
    }

    async regeneratePassword(sharedResource: SharedResource) {
        await this.modalService.openSharedPageDialog({
            sharedResource,
            isRegeneratePasswordMode: true
        });
    }
}
