import { Component } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { LiveEventDetailsService } from "src/app/modules/live-events/pages/event-details/live-event-details.service";
import { LiveEvent } from "src/app/modules/live-events/pages/events/liveevent";

@Component({
    selector: "app-start-next-live-event-stage-dialog",
    templateUrl: "./start-next-live-event-stage-dialog.component.html"
})
export class StartNextLiveEventStageDialogComponent {
    startNextEvent: LiveEventDetailsService["startNextStage"];
    liveEvent: LiveEvent;
    saving = false;
    constructor(public activeModal: NgbActiveModal) {}

    async submit(keepDuration: boolean) {
        this.saving = true;
        try {
            const res = await this.startNextEvent(this.liveEvent, keepDuration);
            this.saving = false;
            if (res) this.activeModal.close();
        } catch (e) {
            this.saving = false;
        }
    }
}
