import { Injectable, Signal, computed, inject, signal } from "@angular/core";
import { toObservable } from "@angular/core/rxjs-interop";
import { Constants, TimeZoneT } from "../constants/constants";
import { UsersService } from "../pages/account-management/users/users.service";
import moment from "moment";

export enum dateFormats {
    time = "time",
    timeSeconds = "timeSeconds",
    shortDateTimeSeconds = "shortDateTimeSeconds",
    shortDateTime = "shortDateTime",
    longDateTimeSeconds = "longDateTimeSeconds",
    longDate = "longDate"
}

@Injectable({
    providedIn: "root"
})
export class TimeZoneService {
    timeZones = Constants.timeZones;
    scopeName = "timeZone";
    timeFormatScope = "timeFormat";

    private _selectedTimeZone = signal<TimeZoneT | undefined>(undefined);
    selectedTimeZone = this._selectedTimeZone.asReadonly();
    currentTimeZone$ = toObservable(this.selectedTimeZone);

    private _selectedTimeFormat = signal<string | undefined>(undefined);
    selectedTimeFormat = this._selectedTimeFormat.asReadonly();

    private userService = inject(UsersService);

    shortDateTimeFormatUs = "MM/DD/YY, h:mm A";
    shortDateTimeFormatInt = "DD/MM/YY, HH:mm";
    shortDateTimeSecondsFormatUs = "MM/DD/YY, h:mm:ss A";
    shortDateTimeSecondsFormatInt = "DD/MM/YY, HH:mm:ss";
    longDateTimeSecondsFormatUs = "MMM D, YYYY, h:mm:ss A";
    longDateTimeSecondsFormatInt = "D MMM YYYY HH:mm:ss";
    longDateUs = "MMM D, YYYY";
    longDateInt = "D MMM YYYY";
    timeSecondsUs = "h:mm:ss A";
    timeSecondsInt = "HH:mm:ss";
    timeUs = "h:mm a";
    timeInt = "HH:mm";

    async setTimeZone(timeZone: TimeZoneT | undefined) {
        this._selectedTimeZone.set(timeZone);
        const layoutToSave = {
            timeZone: timeZone
        };
        await this.userService.updateLayout(this.scopeName, layoutToSave);

        // save last 5 selected timezones to localstorage
        if (timeZone) {
            const mostRecentTimeZonesString: string = localStorage.getItem("recent.timeZones") ?? "";
            const mostRecentTimeZonesArray: string[] = mostRecentTimeZonesString.split(",");
            mostRecentTimeZonesArray.unshift(timeZone.value);
            if (mostRecentTimeZonesArray.length > 5) mostRecentTimeZonesArray.pop();
            localStorage.setItem("recent.timeZones", mostRecentTimeZonesArray.join(","));
        }
    }

    async getTimeZoneFromDB(): Promise<TimeZoneT> {
        const layout = await this.userService.getLayout(this.scopeName);
        this._selectedTimeZone.set(layout?.timeZone);
        return layout?.timeZone;
    }

    async setTimeFormat(timeFormat: string) {
        this._selectedTimeFormat.set(timeFormat);
        const layoutToSave = {
            timeFormat: timeFormat
        };
        await this.userService.updateLayout(this.timeFormatScope, layoutToSave);
    }

    async getTimeFormatFromDB(): Promise<string> {
        const layout = await this.userService.getLayout(this.timeFormatScope);
        this._selectedTimeFormat.set(layout?.timeFormat ?? (12).toString());
        return layout?.timeFormat;
    }

    getDateTimeFormat(format?: dateFormats): string {
        let f;
        if (format === dateFormats.time) {
            if (this.selectedTimeFormat() === "24") f = this.timeInt;
            else f = this.timeUs;
        } else if (format === dateFormats.timeSeconds) {
            if (this.selectedTimeFormat() === "24") f = this.timeSecondsInt;
            else f = this.timeSecondsUs;
        } else if (format === dateFormats.longDate) {
            if (this.selectedTimeFormat() === "24") f = this.longDateInt;
            else f = this.longDateUs;
        } else if (format === dateFormats.shortDateTime) {
            if (this.selectedTimeFormat() === "24") f = this.shortDateTimeFormatInt;
            else f = this.shortDateTimeFormatUs;
        } else if (format === dateFormats.longDateTimeSeconds) {
            if (this.selectedTimeFormat() === "24") f = this.longDateTimeSecondsFormatInt;
            else f = this.longDateTimeSecondsFormatUs;
        } else if (format === dateFormats.shortDateTimeSeconds) {
            if (this.selectedTimeFormat() === "24") f = this.shortDateTimeSecondsFormatInt;
            else f = this.shortDateTimeSecondsFormatUs;
        } else {
            if (this.selectedTimeFormat() === "24") f = this.longDateTimeSecondsFormatInt;
            else f = this.longDateTimeSecondsFormatUs;
        }
        return f;
    }

    convertDateToTimeZone(dateToConvert: Date | moment.Moment) {
        const timeZoneText = this.selectedTimeZone()?.utc[0];
        // Fallback for when no time zone is selected
        if (!timeZoneText) {
            return moment(dateToConvert);
        }
        return moment(dateToConvert).tz(timeZoneText);
    }

    formatDateToTimeZone(dateToConvert: Date, formatSetting?: dateFormats, formatOverride?: string) {
        let format;
        format = this.getDateTimeFormat(formatSetting);
        if (formatOverride) format = formatOverride;

        const timeZoneText = this.selectedTimeZone()?.utc[0];
        // Fallback for when no time zone is selected
        if (!timeZoneText) {
            return moment(dateToConvert).format(format);
        }
        return moment(dateToConvert).tz(timeZoneText).format(format);
    }

    computeDateToTimeZone(date: string | Date, format?: dateFormats, formatOverride?: string): Signal<string> {
        return computed(() => {
            return this.formatDateToTimeZone(new Date(date), format, formatOverride);
        });
    }

    computeDateToTimeZoneReturnString(date: string | Date, format?: dateFormats, formatOverride?: string): string {
        const computedSignal = computed(() => {
            return this.formatDateToTimeZone(new Date(date), format, formatOverride);
        });
        return computedSignal();
    }
}
