<div class="details-form">
    <div class="form-group">
        <h5 translate>TYPE</h5>
        <div>
            <span translate>{{ zecType | translate }}</span>
        </div>
    </div>
    <app-copy-credentials [canEdit]="canEdit" [user]="zec.api_user" [password]="zec.api_password"></app-copy-credentials>
    <div class="form-group" [ngClass]="{ 'd-none': zec.state === 'pending' || !canEdit }">
        <h5 translate>OPEN</h5>
        <div>
            <a href="{{ zec.configure_link }}" target="_blank">
                <fa-icon icon="external-link-alt" size="sm"></fa-icon>
                {{ zecType | translate }}
            </a>
        </div>
    </div>
    <div class="form-group" *ngIf="zec.status && zec.status.source_ip">
        <h5 translate>SOURCE_IP</h5>
        <div>
            <span>{{ zec.status.source_ip }}</span>
        </div>
    </div>
    <div class="form-group" *ngIf="zec.status && zec.status.hostid">
        <h5 translate>HOST_ID</h5>
        <div>
            <span>{{ zec.status.hostid }}</span>
        </div>
    </div>
    <div class="form-group" *ngIf="zec.status && zec.status.about && zec.status.about.platform">
        <h5 translate>PLATFORM</h5>
        <div>
            <span>{{ zec.status.about.platform }}</span>
        </div>
    </div>
    <app-details-location [location]="zec.location"></app-details-location>
    <div class="form-group" *ngIf="zec.status && zec.status.about && zec.status.about.version">
        <h5 translate>VERSION</h5>
        <div>
            <span>{{ zec.status.about.version | version }}</span>
        </div>
    </div>
    <div class="form-group" *ngIf="zec.accessKey">
        <h5 translate>SSH_KEY</h5>
        <div>
            <span *ngIf="zec.accessKey.readOnly || (!zec.accessKey.readOnly && !zec.accessKey.hasRsaPrivate)">{{ zec.accessKey.name }}</span>
            <a *ngIf="!zec.accessKey.readOnly && zec.accessKey.hasRsaPrivate" href="/api/access_keys/{{ zec.accessKey.id }}/download">
                <fa-icon icon="download" size="sm"></fa-icon>
                {{ zec.accessKey.name }}
            </a>
        </div>
    </div>
    <div class="form-group" *ngIf="zec.status && zec.status.activation_key_link">
        <h5 translate>ACTIVATION_KEY</h5>
        <div>
            <span
                ><a [href]="zec.status?.activation_key_link" target="_blank"><fa-icon icon="external-link-alt" size="sm"></fa-icon> License Details</a></span
            >
        </div>
    </div>
    <div class="form-group" *ngIf="zec.alertingProfile">
        <h5 translate>ALERTING_PROFILE</h5>
        <div>
            <a [routerLink]="['/' + urls.configuration.eventsManagement, zec.alertingProfile.id]">
                {{ zec.alertingProfile.name }}
            </a>
        </div>
    </div>
    <div class="form-group">
        <h5 translate>TERMINATION_PROTECTION</h5>
        <div>
            <span>{{ zec.termination_protection ? ("ENABLED" | translate) : ("DISABLED" | translate) }}</span>
        </div>
    </div>
</div>
