import { Component, Input, OnInit } from "@angular/core";
import { MediaConnectFlow } from "src/app/models/shared";
import {
    DeliveryChannel,
    ChannelTypes,
    isAdaptiveChannel,
    isDeliveryChannel,
    isFailoverChannel,
    isMediaConnect,
    isMediaLive,
    TargetObjectType,
    MediaLiveChannel
} from "src/app/pages/channels/channel";
import { TargetsService } from "src/app/pages/targets/targets.service";

interface ChannelsTargetNames {
    delivery: Partial<keyof DeliveryChannel>[];
    mediaConnect: Partial<keyof MediaConnectFlow>[];
    mediaLive: Partial<keyof MediaLiveChannel>[];
}

export interface SingleTargetModel {
    target: TargetObjectType;
    apiType: string;
}
@Component({
    selector: "app-zx-channels-target-column",
    templateUrl: "./zx-channels-target-column.component.html"
})
export class ZxChannelsTargetColumnComponent implements OnInit {
    @Input() channel: ChannelTypes;
    singleTargetModel: SingleTargetModel;

    readonly channelsTargetNames: ChannelsTargetNames = {
        delivery: ["zixiPull", "zixiPush", "rtmpPush", "udpRtp", "rist", "srt", "ndi"],
        mediaConnect: ["zixiPull", "zixiPush", "udpRtp", "srt"],
        mediaLive: ["mediaLiveHttp", "udpRtp", "rtmpPush"]
    };

    constructor(private targetsService: TargetsService) {}

    ngOnInit(): void {
        if (isAdaptiveChannel(this.channel)) {
            this.updateSingleTargetByTargets(this.channel.publishingTarget);
        }

        if (isMediaLive(this.channel)) {
            const mediaLiveTargets = this.getTargetsFromChannel(this.channelsTargetNames.mediaLive);
            this.updateSingleTargetByTargets(mediaLiveTargets);
        }

        if (isDeliveryChannel(this.channel)) {
            const deliveryTargets = this.getTargetsFromChannel(this.channelsTargetNames.delivery);
            this.updateSingleTargetByTargets(deliveryTargets);
        }

        if (isFailoverChannel(this.channel)) {
            const failOverTargets = this.getTargetsFromChannel(
                this.channelsTargetNames.delivery,
                this.channel.deliveryChannel
            );
            this.updateSingleTargetByTargets(failOverTargets);
        }
        if (isMediaConnect(this.channel)) {
            const mediaConnectTargets = this.getTargetsFromChannel(this.channelsTargetNames.mediaConnect);
            this.updateSingleTargetByTargets(mediaConnectTargets);
        }
    }

    private getTargetsFromChannel(targetKeys: string[], channel: ChannelTypes = this.channel): TargetObjectType[] {
        return targetKeys.map(targetKey => channel[targetKey]).flat();
    }

    private updateSingleTargetByTargets(targets?: TargetObjectType[]) {
        if (targets?.length === 1) {
            this.singleTargetModel = {
                target: targets[0],
                apiType: this.targetsService.getTargetApiType(targets[0])
            };
        }
    }
}
