import { Component, Input, inject } from "@angular/core";
import { TargetsService } from "src/app/pages/targets/targets.service";
import { SharedService } from "src/app/services/shared.service";
import { GridObject } from "../grid-detail.component";
import { GridObjectsTypeGuard } from "src/app/utils/type-guards/grid-objects-type-guard";

@Component({
    selector: "app-grid-objects-column",
    templateUrl: "./grid-objects-column.component.html"
})
export class GridObjectsColumnComponent {
    @Input() searchTerm: string[];
    @Input() object: GridObject;
    GridObjectsTypeGuard = GridObjectsTypeGuard;
    public targetsService = inject(TargetsService);
    public sharedService = inject(SharedService);
}
