<fa-icon
    *ngIf="failoverStatusIcon(source.source)"
    class="me-1"
    class="status-{{ failoverStatusIcon(source.source) | statusClass }}"
    [icon]="failoverStatusIcon(source.source) | statusIcon"
></fa-icon>
<zx-source
    class="d-inline"
    [model]="source.source"
    [showStatusIcon]="false"
    [showStatusText]="false"
    [showOtherIcons]="false"
    [showLink]="true"
    [showTag]="false"
></zx-source>
