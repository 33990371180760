import { Component, Input } from "@angular/core";
import { ZecTypes } from "../../../zec-list/zec-list.component";
import { ClipboardService } from "ngx-clipboard";
import { Router } from "@angular/router";
import { Constants } from "src/app/constants/constants";

@Component({
    selector: "app-zec-details",
    templateUrl: "./zec-details.component.html"
})
export class ZecDetailsComponent {
    @Input() zec: ZecTypes;
    @Input() canEdit: boolean;
    zecType: string;

    urls = Constants.urls;

    constructor(private cbs: ClipboardService, private router: Router) {
        this.zecType = this.router.url.replace("s/", "/").split("/")[2].toUpperCase();
    }
}
