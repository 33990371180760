import {
    Component,
    OnInit,
    OnChanges,
    Input,
    SimpleChanges,
    ViewChildren,
    QueryList,
    OnDestroy,
    ViewChild,
    ElementRef,
    inject
} from "@angular/core";
import { NgbSortableHeader, SortDirection, SortEvent } from "src/app/directives/sortable.directive";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { map, take } from "rxjs/operators";
import { Customer } from "src/app/pages/accounts/customer";
import { SharedService } from "./../../../services/shared.service";
import { Note, User, ZixiObject } from "./../../../models/shared";
import { Constants } from "src/app/constants/constants";
import { ModalService } from "../modals/modal.service";
import { DatePipe } from "@angular/common";
import { UsersService } from "../../../pages/account-management/users/users.service";
import moment from "moment";
import { NavigationService } from "../../navigation/navigation.service";
import { TaskSet } from "src/app/pages/automation/automation";
import { Incident } from "src/app/pages/incidents/incident";

interface State {
    searchTerm: string;
    sortColumn: string;
    sortDirection: SortDirection;
}
@Component({
    selector: "zx-advanced-notes",
    templateUrl: "./zx-advanced-notes.component.html",
    styleUrls: ["./zx-advanced-notes.component.scss"],
    providers: [DatePipe]
})
export class ZxAdvancedNotesComponent implements OnInit, OnChanges, OnDestroy {
    @Input() model: ZixiObject | Customer | TaskSet | Incident;
    @Input() type: string;
    @Input() id: number;
    @Input() canEdit: boolean;

    notes: Note[];

    private state: State = {
        searchTerm: "",
        sortColumn: "created_at",
        sortDirection: "desc"
    };
    searchTermArray: string[];
    sortedByText = "CREATED_AT";
    sortedByColumn: string;

    private notesBS$ = new BehaviorSubject<Note[]>([]);
    private totalBS$ = new BehaviorSubject<number>(0);
    @ViewChildren(NgbSortableHeader) headers: QueryList<NgbSortableHeader>;

    private isDarkModeSubscription: Subscription;
    isDarkMode: boolean;
    //

    status: string;
    saveTimer: number | null;
    noteText = "";
    loading: boolean;
    saving = false;

    dateNow: moment.Moment;
    user: User;
    userName$: Observable<string>;
    newNote = false;
    @ViewChild("add", { static: false }) newNoteField: ElementRef;

    // light 10
    noteColors = [
        "#332288",
        "#0077bb",
        "#aa4499",
        "#44aa99",
        "#ddcc77",
        "#88ccee",
        "#cc6677",
        "#117733",
        "#882255",
        "#999933"
    ];

    // dark 10
    noteColorsDark = [
        "#77aadd",
        "#aa3377",
        "#aaaa00",
        "#44bb99",
        "#ffaabb",
        "#99ddff",
        "#ee8866",
        "#bbcc33",
        "#ee6677",
        "#eedd88"
    ];

    constants = Constants;
    isAdmin: boolean;
    private isAdminSubscription: Subscription;

    private sharedService = inject(SharedService);
    private modalService = inject(ModalService);
    private datePipe = inject(DatePipe);
    private userService = inject(UsersService);
    private navigationService = inject(NavigationService);

    async ngOnInit() {
        this.isDarkModeSubscription = this.navigationService.isDarkMode.pipe().subscribe(bool => {
            this.isDarkMode = bool;
            this.prepNotes();
        });

        // User
        this.userName$ = this.userService.user.pipe(map(u => u.name));
        this.userService
            .getCurrentUser()
            .pipe(take(1))
            .subscribe(user => {
                this.user = user;
            });

        // isAdmin
        this.isAdminSubscription = this.userService.isAdmin.pipe().subscribe(bool => {
            this.isAdmin = bool;
        });

        // Note
        this.loading = true;
        this.status = "";
        this.saveTimer = null;

        const result = await this.sharedService.getNotes(this.type, this.id);
        if (result) {
            this.notes = result;
            this.setOriginalNotes();
            this.prepNotes();
        }

        this.loading = false;
    }

    async refreshNotes() {
        const result = await this.sharedService.getNotes(this.type, this.id);
        if (result) {
            this.notes = result;
            this.setOriginalNotes();
            this.prepNotes();
        }
    }

    ngOnDestroy() {
        this.isAdminSubscription.unsubscribe();
        this.isDarkModeSubscription.unsubscribe();
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes.model) {
            if (changes.model.previousValue && changes.model.currentValue) {
                if (changes.model.previousValue.id !== changes.model.currentValue.id) {
                    this.loading = true;
                    //
                    this.resetSearch();
                    this.newNote = false;
                    this.noteText = "";
                    const result = await this.sharedService.getNotes(this.type, this.id);
                    if (result) {
                        this.notes = result;
                        this.setOriginalNotes();
                        this.prepNotes();
                    }
                    //
                    this.loading = false;
                }
            }
        }
    }

    async onSubmitNew() {
        this.saving = true;
        const model = {
            note: this.noteText
        };
        const result = await this.sharedService.addNote(this.type, this.id, model);
        if (result) {
            this.status = "";
            this.newNote = false;
            await this.refreshNotes();
            this.editNote(this.notes[0]);
        } else this.status = "SAVE_ERROR";
        this.noteText = "";
        this.saving = false;
    }

    startNewNote() {
        if (this.notes && this.notes.length > 0) {
            for (const n of this.notes) {
                n.note = n.originalNote ?? "";
                n.editingNote = false;
            }
        }

        this.newNote = true;
        setTimeout(() => {
            this.newNoteField.nativeElement.focus();
        }, 0);
    }

    cancelNewNote() {
        this.newNote = false;
        this.noteText = "";
    }

    async onSubmit(note: Note) {
        this.saving = true;
        const model = {
            note: note.note
        };
        const result = await this.sharedService.updateNote(this.type, this.id, note.id, model);
        if (result) this.status = "";
        else this.status = "SAVE_ERROR";
        //
        await this.refreshNotes();
        this.saving = false;
    }

    editNote(note: Note) {
        this.newNote = false;
        if (this.notes && this.notes.length > 0) {
            for (const n of this.notes) {
                n.note = n.originalNote ?? "";
                n.editingNote = false;
            }
        }
        note.editingNote = true;
    }

    setOriginalNotes() {
        if (this.notes && this.notes.length > 0) {
            for (const n of this.notes) {
                n.originalNote = n.note;
            }
        }
    }

    cancelEditNote(note: Note) {
        note.note = note.originalNote ?? "";
        note.editingNote = false;
    }

    async deleteNote(note: Note) {
        await this.modalService.confirm(
            "DELETE",
            "NOTE",
            async () => {
                const result = await this.sharedService.deleteNote(this.type, this.id, note);
                if (result) this.refreshNotes();
                else return false;
            },
            (note?.User?.name || note.user) + " on " + this.datePipe.transform(note.created_at, "medium")
        );
    }

    get notes$() {
        return this.notesBS$.asObservable();
    }
    get total$() {
        return this.totalBS$.asObservable();
    }
    get sortColumn() {
        return this.state.sortColumn;
    }
    set sortColumn(sortColumn: string) {
        this._set({ sortColumn });
    }
    get sortDirection() {
        return this.state.sortDirection;
    }
    set sortDirection(sortDirection: SortDirection) {
        this._set({ sortDirection });
    }
    get searchTerm() {
        return this.state.searchTerm;
    }
    set searchTerm(searchTerm: string) {
        this._set({ searchTerm });
        this.searchTermArray = this.sharedService.createArrayFromBooleanSearch(searchTerm);
    }

    private _set(patch: Partial<State>) {
        Object.assign(this.state, patch);
        this.prepNotes();
    }

    onSort({ column, direction }: SortEvent, name: string) {
        this.headers.forEach(header => {
            if (header.sortable !== column) {
                header.direction = "";
            } else {
                header.direction = direction;
            }
        });
        this.sortedByColumn = column;
        this.sortedByText = name;
        this.sortColumn = column;
        this.sortDirection = direction;
    }

    prepNotes() {
        if (this.notes) {
            const { sortColumn, sortDirection, searchTerm } = this.state;
            // set note color based on username
            const array: string[] = this.notes.map(note => note.User?.name || note.user || "N/A");
            const noDupes = [...new Set(array)];
            for (let i = 0; i < noDupes.length; i++) {
                const ns = this.notes.filter(note => (note.User?.name || note.user || "N/A") === noDupes[i]);
                for (const note of ns) {
                    if (this.isDarkMode) note.userColor = this.noteColorsDark[i];
                    else note.userColor = this.noteColors[i];
                }
            }

            // sort
            let notes = this.sharedService.sort(this.notes, sortColumn, sortDirection);

            // filter
            notes = notes.filter(note => this.sharedService.matches(note, searchTerm, this.noteFilter));
            const total = notes.length;

            this.notesBS$.next(notes);
            this.totalBS$.next(total);
        }
    }

    noteFilter = (note: Note, term: string) => {
        return (
            // Note
            note.note.toLowerCase().includes(term.toLowerCase()) ||
            // User Name or User
            (note.User && note.User.name
                ? note.User?.name.toLowerCase().includes(term.toLowerCase())
                : note.user
                ? note.user.toLowerCase().includes(term.toLowerCase())
                : "")
        );
    };

    resetSearch() {
        this.searchTerm = "";
    }

    isSameUser(note: Note) {
        if (this.user && note && note.User) {
            if (this.user.id === note.User?.id) return true;
            else return false;
        } else return false;
    }
}
