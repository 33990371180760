import { ComponentRef } from "@angular/core";
import { ZmEvent } from "src/app/pages/events/event";
import { ZxEventTitleComponent } from "./zx-event-title.component";

export function assignZxEventTitleComponentInputsFactory(
    { instance }: ComponentRef<ZxEventTitleComponent>,
    row: ZmEvent
): void {
    instance.objectId = row.object_id;
    instance.objectType = row.object_type;
    instance.objectName = row.object_name;
    instance.eventType = row.event_type;
    instance.isActiveMute = row.active_mute;
}
