import { Component, OnInit, Input, forwardRef, Injector, ViewChild, inject } from "@angular/core";
import { NgbTimeStruct, NgbDateStruct, NgbDatepicker, NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import { NG_VALUE_ACCESSOR, ControlValueAccessor, NgControl } from "@angular/forms";
import { DatePipe } from "@angular/common";
import { DateTimeModel } from "./date-time.model";
import { noop } from "rxjs";
import * as _ from "lodash";
import { TimeZoneService } from "src/app/services/time-zone.service";
@Component({
    selector: "zx-date-time-picker",
    templateUrl: "./zx-date-time-picker.component.html",
    providers: [
        DatePipe,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ZxDateTimePickerComponent),
            multi: true
        }
    ]
})
export class ZxDateTimePickerComponent implements ControlValueAccessor, OnInit {
    @Input() dateString: string;
    @Input() hourStep = 1;
    @Input() minuteStep = 1;
    @Input() secondStep = 1;
    @Input() seconds = true;
    @Input() disabled = false;
    @Input() onlyPastDates? = false;
    @Input() onlyFutureDates? = false;
    @Input() allDates? = false;
    @Input() hideTimePicker? = false;
    showTimePickerToggle = false;

    @Input() useBrowserTime?: boolean;
    timeZoneOffset: number;

    now = new Date();
    maxDate: NgbDateStruct = { year: this.now.getFullYear(), month: this.now.getMonth() + 1, day: this.now.getDate() };
    minDate: NgbDateStruct = {
        year: this.now.getFullYear() - 1,
        month: this.now.getMonth() + 1,
        day: this.now.getDate()
    };

    public datetime: DateTimeModel;
    private firstTimeAssign = true;
    private timeZoneService = inject(TimeZoneService);
    timeZone = this.timeZoneService.selectedTimeZone();

    @ViewChild("dp", { static: false }) dp: NgbDatepicker;

    private onTouched: () => void = noop;
    private onChange: (_) => void = noop;

    private ngControl: NgControl;

    constructor(private inj: Injector, private calendar: NgbCalendar) {
        // Debounce time change
        this.onTimeChange = _.debounce(this.onTimeChange, 100);
    }

    ngOnInit(): void {
        if (this.onlyFutureDates) {
            this.minDate = this.calendar.getPrev(this.calendar.getToday(), "d", 1);
            this.maxDate = this.calendar.getNext(this.calendar.getToday(), "y", 5);
        }

        if (this.onlyPastDates) {
            this.minDate = this.calendar.getPrev(this.calendar.getToday(), "y", 5);
            this.maxDate = this.calendar.getNext(this.calendar.getToday(), "d", 0);
        }

        if (this.allDates) {
            this.minDate = this.calendar.getPrev(this.calendar.getToday(), "y", 5);
            this.maxDate = this.calendar.getNext(this.calendar.getToday(), "y", 5);
        }

        this.ngControl = this.inj.get(NgControl);
    }

    writeValue(newModel: string) {
        if (newModel) {
            this.datetime = Object.assign(this.datetime, DateTimeModel.fromLocalString(newModel));
            if (this.dp) {
                this.dp.focusDate(this.datetime);
                this.dp.focusSelect();
            }
            // TODO: remove this?
            this.dateString = newModel;
            this.setDateStringModel();
        } else {
            this.datetime = new DateTimeModel();
        }
    }

    registerOnChange(fn): void {
        this.onChange = fn;
    }

    registerOnTouched(fn): void {
        this.onTouched = fn;
    }

    toggleDateTimeState($event) {
        this.showTimePickerToggle = !this.showTimePickerToggle;
        $event.stopPropagation();
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    onInputChange($event) {
        const value = $event.target.value;
        const dt = DateTimeModel.fromLocalString(value);
        if (dt) {
            this.datetime = dt;
            this.setDateStringModel();
        } else if (value.trim() === "") {
            this.datetime = new DateTimeModel();
            this.dateString = "";
            this.onChange(this.dateString);
        } else {
            this.onChange(value);
        }
    }

    isNgbDateStruct(value: any): value is NgbDateStruct {
        return (
            value !== null &&
            typeof value === "object" &&
            typeof value.year === "number" &&
            typeof value.month === "number" &&
            typeof value.day === "number"
        );
    }

    onDateChange($event: string | NgbDateStruct) {
        let timeZone = this.timeZoneService.selectedTimeZone();

        if (timeZone) {
            const timeZoneOffset = -timeZone.offset * 60;
            this.timeZoneOffset = timeZoneOffset;
        }

        let date;
        // new adjustment
        if (this.isNgbDateStruct($event) && !this.useBrowserTime) {
            date = new DateTimeModel({
                ...$event,
                timeZoneOffset: this.timeZoneOffset
            });
        } else {
            date = new DateTimeModel($event);
        }

        if (!date) return;
        if (!this.datetime) this.datetime = date;

        this.datetime.year = date.year;
        this.datetime.month = date.month;
        this.datetime.day = date.day;

        this.setDateStringModel();
    }

    onTimeChange(event: NgbTimeStruct) {
        if (!event) return;
        this.datetime.hour = event.hour;
        this.datetime.minute = event.minute;
        this.datetime.second = event.second;
        this.setDateStringModel();
    }

    setDateStringModel() {
        if (!this.useBrowserTime) this.datetime.timeZoneOffset = this.timeZoneOffset;
        this.dateString = this.datetime.toString();
        this.onChange(this.dateString);
        if (!this.firstTimeAssign) {
            this.onChange(this.dateString);
        } else {
            // Skip very first assignment to null done by Angular
            if (this.dateString !== null) {
                this.firstTimeAssign = false;
            }
        }
    }
}
