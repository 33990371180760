import { Component, Input } from "@angular/core";
import * as _ from "lodash";
import { Constants } from "src/app/constants/constants";
import { Source, MediaConnectSource } from "src/app/models/shared";

@Component({
    selector: "app-source-thumbnail-info",
    templateUrl: "./source-thumbnail-info.component.html"
})
export class SourceThumbnailInfoComponent {
    @Input() source?: Source | MediaConnectSource;
    @Input() showTag?: boolean;
    @Input() searchTerm?: string | string[];
    urls = Constants.urls;
    showCluster = false;
}
