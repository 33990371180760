<spinner-animation *ngIf="loading"></spinner-animation>
<app-table-list
    [ngClass]="{ 'mt-2': !bordered }"
    [data]="events"
    [tableName]="'dynamic-event'"
    [displayTableName]="'EVENTS' | translate"
    [(tableSchema)]="tableColumnsSchema"
    [showFilter]="true"
    [showPagination]="true"
    [showSelectionCheckbox]="false"
    [selectable]="false"
    [hoverable]="false"
    [showReport]="false"
    [autoRows]="false"
    [refreshing]="loading"
    *ngIf="!loading"
>
    <!-- Types -->
    <zx-events-types-filter [filter]="filter" (filterChange)="onFilterChange($event)"></zx-events-types-filter>
    <div
        class="form-group mb-0"
        *ngIf="
            quickReport &&
            (userPermissions.is_zixi_support_write || userPermissions.is_zixi_admin || userPermissions.is_admin || userPermissions.is_objects_manager)
        "
    >
        <a id="quick-report" href="{{ quickReportUrl }}" class="btn btn-outline-primary" title="{{ 'QUICK_REPORT' | translate }}"
            ><fa-icon icon="download" size="md"></fa-icon><span class="d-none d-lg-inline-block ms-1">{{ "QUICK_REPORT" | translate }}</span></a
        >
    </div>
</app-table-list>
