import { Component, Input } from "@angular/core";
import { Constants } from "../../../constants/constants";

@Component({
    selector: "zx-broadcaster-link",
    template: `
        <a
            [routerLink]="['/' + URLS.clusters, clusterId | uId, URLS.broadcasters, id | uId, name]"
            (click)="$event.stopPropagation()"
        >
            <ngb-highlight [title]="name" [result]="name" [term]="searchTerm"></ngb-highlight>
        </a>
    `
})
// Don't pass the entire model as input — only pass the explicitly needed values.
export class ZxBroadcasterLinkComponent {
    // Data
    @Input({ required: true }) id: number;
    @Input({ required: true }) name: string;
    @Input({ required: true }) clusterId: number;
    // Configs
    @Input() searchTerm: string | string[] = "";
    URLS = Constants.urls;
}
