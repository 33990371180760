import {
    ZixiObject,
    ZixiPlus,
    Bitrate,
    Tag,
    ActiveBroadcaster,
    TargetsSummary,
    ChannelSource,
    Broadcaster,
    MediaConnectFlow,
    MediaConnectVpcInterface,
    MediaConnectMediaStream,
    MediaConnectSource,
    Source,
    AzureAccount,
    GcpAccount
} from "../../models/shared";
import { Cluster } from "../clusters/cluster";
import { Receiver } from "../zecs/zecs/zec";
import { LocationInfo } from "../maps/map";
import { SourcePreference } from "src/app/constants/constants";
import { Zec } from "../zecs/zecs/zec";
import { TranscodingProfile } from "../transcoding-profiles/transcoding-profile";
import {
    ObjectsActiveBroadcaster,
    SourceActiveBroadcasterIds,
    DeliveryChannelActiveBroadcasterIds,
    AdaptiveChannelActiveBroadcasterIds,
    PidMappingProfile,
    TargetBondedLink
} from "@zixi/models";

export const isMediaConnect = (channel): channel is MediaConnectFlow => {
    return channel instanceof MediaConnectFlow;
};

export const isMediaLive = (channel): channel is MediaLiveChannel => {
    return channel instanceof MediaLiveChannel;
};

export const isAdaptiveChannel = (channel): channel is AdaptiveChannel => {
    return channel instanceof AdaptiveChannel;
};

export const isDeliveryChannel = (channel): channel is DeliveryChannel => {
    return channel instanceof DeliveryChannel;
};

export const isFailoverChannel = (channel): channel is FailoverChannel => {
    return channel instanceof FailoverChannel;
};

export type ChannelTypes = AdaptiveChannel | DeliveryChannel | MediaConnectFlow | MediaLiveChannel | FailoverChannel;

export class MediaLiveChannel extends ZixiPlus {
    _frontData: {
        sortableStatus: string;
        sortableCluster: string;
        good_sources: number;
        bad_sources: number;
        disabled_sources: number;
        sortableSources: string;
        sortableTargets: number;
        mode: string;
        eventsObjects?: {
            medialive_channels?: number[];
            medialive_http_targets?: number[];
        };
        active_broadcaster: ActiveBroadcaster;
        lastRefresh: string;
    };
    type: string;
    adaptive: false;
    delivery: false;
    mediaconnect: false;
    medialive: true;
    failover: false;
    aws_account_id: number;
    arn: string;
    region: string;
    availability_zone: string;
    role_arn: string;
    targetsSummary: {
        good: number;
        bad: number;
        warning: number;
        disabled: number;
        muted_bad: number;
        pending: number;
    };
    ingest_ip: string;
    egress_ip: string;
    failover_mode: "merge" | "failover" | null;
    processingCluster: undefined;
    broadcaster_cluster_id: undefined;
    cdi: [];
    jpegxs: [];
    mediaStreams: [];
    rtmpPush: RtmpPushTarget[];
    mediaLiveHttp: MediaLiveHttpTarget[];
    udpRtp: UdpRtpTarget[];
    source: MediaConnectSource;
    flow: MediaConnectFlow;
    is_hidden: false;
    is_transcoding: false;
    altChannel: undefined;
    mediaconnect_flow_egress_ip: string;
}

export class AdaptiveChannel extends ZixiPlus {
    _frontData: {
        sortableStatus: string;
        sortableCluster: string;
        good_sources?: number;
        bad_sources?: number;
        disabled_sources?: number;
        sortableSources?: string;
        sortableTargets?: number;
        mode: string;
        transcoded: boolean | number;
        eventsObjects?: {
            adaptive_channel?: number[];
            publishing_target?: number[];
        };
        active_broadcaster?: ActiveBroadcaster;
        lastRefresh: string;
        rowSuccess?: boolean;
        rowLoading?: boolean;
        primaryCluster?: Cluster;
        error?: boolean;
        selected_prefered_source?: number;
    };
    adaptive: true;
    delivery: false;
    mediaconnect: false;
    medialive: false;
    failover: false;
    bitrates: Bitrate[];
    broadcaster_cluster_id: number;
    primary_broadcaster_cluster_id: number;
    broadcaster_id: number;
    primary_target_broadcaster_id: number;
    dash_chunk_duration: number;
    dash_chunks: number;
    hls_chunk_duration: number;
    hls_chunks: number;
    instance_id: string;
    copy_closed_captions: boolean;
    is_source_included: number;
    is_transcoding: number | boolean;
    disable_hls: number | boolean;
    disable_cmaf: number | boolean;
    disable_synchronization: number | boolean;
    log_scte: number;
    processingCluster: Cluster;
    publishingTarget: PublishingTarget[];
    resourceTags: Tag[];
    status?: {
        active_broadcaster: ActiveBroadcaster;
        bitrates: {
            error: {
                error_code: string;
                error_group: string;
                event_type: string;
                frequency: number;
                message: string;
                short_message: string;
            } | null;
            id: number;
            scte: any;
            source_stream_id: string;
        }[];
    };
    targetsSummary: TargetsSummary;
    transcode_crop_height: number;
    transcode_crop_left: number;
    transcode_crop_mode: number;
    transcode_crop_top: number;
    transcode_crop_width: number;
    transcoded_source_bitrate: number;
    type: string;
    warning: number;
    view_url: string;
    webvtt_cc: boolean;
    segment_on_scte35: boolean | 1 | 0;
    skip_cue_in: boolean | 1 | 0;
    drop_first_segment: boolean | 1 | 0;
    order_playlist: boolean | 1 | 0;
    order_ascending: boolean | 1 | 0;
    timecode_mode: "none" | "system" | "sei";
    ignore_transcode_thresholds: boolean | 1 | 0;
    strict_chunks: boolean | 1 | 0;
    is_hidden: false;
    activeBroadcasterObjects?: ObjectsActiveBroadcaster<AdaptiveChannelActiveBroadcasterIds>;
    billing_code?: string;
    billing_password?: string;
    slate_source_id: number | null;
    slateSource?: Source;
    force_i_frame_on_scte: number | boolean;
    transcode_encoder: "any" | "nvidia_hw" | "intel_hw" | "x264";
    transcode_threads: number | null;
    html_overlay_url: string | null;
    support_multiaudio: boolean;
    aws_account_id: undefined;
    region: undefined;
    alt_channel_id: number | null;
    altChannel?: AdaptiveChannel;
    disableRow?: boolean;
    scte_type?: number;
    system_time_pdt_apperance_rate?: number;
}

export class DeliveryChannel extends ZixiPlus {
    _frontData: {
        sortableStatus: string;
        sortableCluster: string;
        good_sources?: number;
        bad_sources?: number;
        disabled_sources?: number;
        sortableSources?: string;
        sortableTargets?: number;
        mode: string;
        eventsObjects?: {
            delivery_channel?: number[];
            zixi_pull?: number[];
            zixi_push?: number[];
            rtmp_push?: number[];
        };
        active_broadcaster?: ActiveBroadcaster;
        lastRefresh: string;
        targetBroadcaster?: Broadcaster;
        rowSuccess?: boolean;
        rowLoading?: boolean;
        primaryCluster?: Cluster;
        error?: boolean;
        selected_prefered_source?: number;
    };
    adaptive: false;
    delivery: true;
    mediaconnect: false;
    medialive: false;
    failover: false;
    bitrates: Bitrate[];
    sources: ChannelSource[];
    broadcaster_cluster_id: number;
    primary_broadcaster_cluster_id: number;
    created_at: string;
    instance_id: string;
    is_public_output: number;
    processingCluster: Cluster;
    public_output_password: string;
    resourceTags: Tag[];
    rtmpPush: RtmpPushTarget[];
    targetsSummary: TargetsSummary;
    type: string;
    udpRtp: UdpRtpTarget[];
    warning: number;
    zixiPull: ZixiPullTarget[];
    zixiPush: ZixiPushTarget[];
    rist: RistTarget[];
    srt: SrtTarget[];
    ndi: NdiTarget[];
    view_url?: string;
    is_transcoding: false;
    is_hidden: boolean;
    target_broadcaster_id: number | null;
    primary_target_broadcaster_id: number | null;
    activeBroadcasterObjects?: ObjectsActiveBroadcaster<DeliveryChannelActiveBroadcasterIds>[];
    aws_account_id: undefined;
    region: undefined;
    failover_channel_id: number | null;
    failoverChannel?: FailoverChannel;
    external_id?: string;
    alt_channel_id: number | null;
    altChannel?: DeliveryChannel;
    disableRow?: boolean;
}

export class FailoverChannel extends ZixiPlus {
    _frontData: {
        sortableStatus: string;
        sortableCluster: string;
        good_sources?: number;
        bad_sources?: number;
        disabled_sources?: number;
        sortableSources?: string;
        sortableTargets?: number;
        mode: string;
        eventsObjects?: {
            failover_channel: number[];
            source?: number[];
            zixi_pull?: number[];
            zixi_push?: number[];
            rtmp_push?: number[];
        };
        active_broadcaster?: Broadcaster;
        lastRefresh: string;
        rowSuccess?: boolean;
        rowLoading?: boolean;
        primaryCluster?: Cluster;
        error?: boolean;
        selected_prefered_source?: number;
    };
    adaptive: false;
    delivery: false;
    mediaconnect: false;
    medialive: false;
    failover: true;
    failover_source_id: number;
    slate_source_id: number | null;
    delivery_channel_id: number;
    deliveryChannel: DeliveryChannel;
    failoverSource: Source;
    sources: ChannelSource[];
    broadcaster_cluster_id: number;
    primary_broadcaster_cluster_id: number;
    is_enabled: number;
    created_at: string;
    updated_at: string;
    resourceTags: Tag[];
    targetsSummary: TargetsSummary;
    type: string;
    udpRtp: UdpRtpTarget[];
    zixiPull: ZixiPullTarget[];
    zixiPush: ZixiPushTarget[];
    rtmpPush: RtmpPushTarget[];
    rist: RistTarget[];
    srt: SrtTarget[];
    ndi: NdiTarget[];
    processingCluster: Cluster;
    activeBroadcasterObjects: ObjectsActiveBroadcaster<SourceActiveBroadcasterIds>;
    is_hidden: false;
    is_transcoding: false;
    billing_code?: string;
    billing_password?: string;
    aws_account_id: undefined;
    region: undefined;
    external_id?: string;
    alt_channel_id: number | null;
    altChannel?: FailoverChannel;
    disableRow?: boolean;

    /* front end local */
    error_concealment: 0 | 1 | boolean;
    error_concealment_stream_id: string | null;
    error_concealment_continuous_timeline: 0 | 1 | boolean;
    error_concealment_replace_frames: 0 | 1 | boolean;
    error_concealment_delay_ms: number;
    error_concealment_cbr_padding_kbps: number | null;
    error_concealment_cbr_padding_pcr_interval_ms: 40 | 100;
    error_concealment_fix_cc: 0 | 1 | boolean;
}

// 11 Types
export enum TargetApiType {
    Pull = "pull",
    Push = "push",
    Rtmp = "rtmp",
    Rist = "rist",
    UdpRtp = "udp_rtp",
    Http = "http",
    MediaLiveHttp = "medialive_http",
    Srt = "srt",
    Ndi = "ndi",
    CDI = "cdi",
    JPEGXS = "jpegxs",
    Entitlement = "entitlement",
    Mediaconnect = "mediaconnect"
}

export type AuthMethod = null | "basic" | "digest" | "oauth2";

export type TargetObjectType =
    | UdpRtpTarget
    | NdiTarget
    | RistTarget
    | RtmpPushTarget
    | ZixiPullTarget
    | ZixiPushTarget
    | PublishingTarget
    | SrtTarget
    | MediaconnectCDITarget
    | MediaconnectJPEGXSTarget
    | MediaLiveHttpTarget
    | MediaconnectEntitlementTarget;

export type TargetPidMappingTypes =
    | UdpRtpTarget
    | RistTarget
    | RtmpPushTarget
    | ZixiPullTarget
    | ZixiPushTarget
    | SrtTarget;

export class AnyTarget {
    _frontData: {
        saving?: boolean;
        processing?: string;
        hasError?: boolean;
        error?: { status: number; statusText: string; message: string };
        active_broadcaster?: ActiveBroadcaster;
        sortableTarget: string;
        lastRefresh: string;
    };
    id: string;
    objId: number;
    entitlement?: boolean;
    type: string;
    adaptive?: boolean;
    delivery?: boolean;
    push?: boolean;
    pull?: boolean;
    udp_rtp?: boolean;
    rtmp?: boolean;
    rist?: boolean;
    srt?: boolean;
    ndi?: boolean;
    cdi?: boolean;
    jpegxs?: boolean;
    medialive_http?: boolean;
    mediaconnect?: boolean;
    medialive?: boolean;
    target: TargetObjectType;
    previewable: boolean;
    output_target: string;
    channel_name: string;
    channel_type: string;
    type_name: string;
    order: string;
    status: string;
    apiType: TargetApiType;
    latitude?: number;
    longitude?: number;
    dynamic: boolean;
}

export class PublishingTarget extends ZixiObject {
    mediaconnect_flow_id: null;
    delivery_channel_id: null;
    adaptive_channel_id: number;
    medialive_channel_id: number;

    id: number;
    name: string;
    type: "s3" | "http" | "gcp" | "azure" | "https" | "mediastore" | "youtube";
    aws_access_key_id?: string;
    aws_secret_key?: string;
    youtube_stream_key?: string;
    ingest_url: string;
    playback_url: string;
    delete_outdated: number;
    ignore_certificate: number;
    aws_region: string;
    is_enabled: number;
    created_at: string;
    updated_at: string;
    instance_id: string;
    encapsulation: string;
    active_mute: boolean;
    muted: number;
    muted_until: string;
    flapping: string;
    active_flapping: boolean;
    generalStatus: string;
    status: {
        active_broadcaster?: ActiveBroadcaster;
        bitrates: Bitrate;
        hls_check_result: {
            gk7Ll: {
                checked_media_seq: number;
            };
        };
        trace: boolean;
    };
    resourceTags: Tag[];
    acknowledged: number;
    adaptiveChannel: AdaptiveChannel;
    mediaconnectFlow: undefined;
    mediaLiveChannel: MediaLiveChannel;
    gcp_account_id: number;
    gcpAccount: GcpAccount;
    azure_account_id: number;
    azureAccount: AzureAccount;
    location?: { ip?: LocationInfo; address?: LocationInfo };
    dynamic_targets: null;
    zixi_pull_parent_id: null;
    parentZixiPull?: null;
    childZixiPulls: null;
    monitor_missing_subtitles: 0 | 1 | boolean;
    path_method: number | null;
    billing_code: string | null;
    billing_password: string | null;
    record_dvr: 0 | 1;
    propagate_tags: 0 | 1;
    transcodingProfile: null;
    preferred_source: undefined;
    upload_type?: 0 | 1 | 2;
    auth_method?: AuthMethod;
    auth_user_name?: string;
    auth_password?: string;
    external_id?: string;
    primary_channel_id: number | null;
    activeBroadcasterObjects?: ObjectsActiveBroadcaster<AdaptiveChannelActiveBroadcasterIds>;
}

export class MediaLiveHttpTarget extends ZixiObject {
    medialive_channel_id: number;
    adaptive_channel_id: null;
    delivery_channel_id: null;
    mediaconnect_flow_id: null;
    id: number;
    name: string;
    type: string;
    ingest_url: string;
    delete_outdated: number;
    ignore_certificate: number;
    aws_region: string;
    destination: "s3" | "mediastore" | "http";
    is_enabled: number;
    created_at: string;
    updated_at: string;
    instance_id: string;
    active_mute: boolean;
    muted: number;
    muted_until: string;
    flapping: string;
    active_flapping: boolean;
    generalStatus: string;
    status: {
        active_broadcaster?: ActiveBroadcaster;
        bitrates: Bitrate;
        hls_check_result: {
            gk7Ll: {
                checked_media_seq: number;
            };
        };
        trace: boolean;
    };
    resourceTags: Tag[];
    acknowledged: number;
    adaptiveChannel: AdaptiveChannel;
    mediaLiveChannel: MediaLiveChannel;
    location?: { ip?: LocationInfo; address?: LocationInfo };
    dynamic_targets: null;
    zixi_pull_parent_id: null;
    parentZixiPull?: null;
    childZixiPulls: null;
    transcodingProfiles: TranscodingProfile[];
    transcodingProfile: null;
    preferred_source: undefined;
    activeBroadcasterObjects?: ObjectsActiveBroadcaster<AdaptiveChannelActiveBroadcasterIds>;
}

export class RistTarget extends ZixiObject {
    mediaconnect_flow_id: number;
    delivery_channel_id: number;
    adaptive_channel_id: null;
    medialive_channel_id: null;
    bind_cidr: string;
    bind_port: number;
    deliveryChannel: DeliveryChannel;
    mediaconnectFlow: MediaConnectFlow;
    mediaLiveChannel: MediaLiveChannel;
    dont_fragment: number;
    host: string;
    internal_destination_ip: string; // ignore for now
    internal_destination_port: number; // ignore for now
    internal_source_ip: string; // ignore for now
    internal_source_port: number; // ignore for now
    null_packet_suppression: number;
    port: number;
    profile: string;
    pull_mode: number;
    reduced_headers: number; // ignore for now
    remux_buffer_ms: number;
    remux_disable_padding: number;
    remux_kbps: number | null;
    remux_mode: "remux" | "pad";
    smoothing: number;
    smpte_2022_fec: number;
    smpte_2022_fec_cols: number;
    smpte_2022_fec_rows: number;
    use_dtls: number;
    type: string;
    status?: {
        active_broadcaster?: ActiveBroadcaster;
        trace: boolean;
        dtls: boolean;
        dtls_cert: string;
    };
    location?: { ip?: LocationInfo; address?: LocationInfo };
    dynamic_targets: null;
    zixi_pull_parent_id: null;
    parentZixiPull?: null;
    childZixiPulls: null;
    billing_code: string | null;
    billing_password: string | null;
    preferred_source: SourcePreference | number;
    vpc: MediaConnectVpcInterface[];
    mtu?: number;
    autopull_mtu?: number;
    transcodingProfile: null;
    pid_mapping?: PidMappingProfile;
    external_id?: string;
    primary_channel_id: number | null;
    primary_source_id: SourcePreference | number | null;
    pois_latency_offset: number | null;
    activeBroadcasterObjects?: ObjectsActiveBroadcaster<AdaptiveChannelActiveBroadcasterIds>;
}

export class NdiTarget extends ZixiObject {
    mediaconnect_flow_id: null;
    delivery_channel_id: number;
    adaptive_channel_id: null;
    medialive_channel_id: null;
    mediaLiveChannel: MediaLiveChannel;
    deliveryChannel: DeliveryChannel;
    type: string;
    status?: {
        active_broadcaster?: ActiveBroadcaster;
        trace: false;
    };
    location?: { ip?: LocationInfo; address?: LocationInfo };
    zixi_pull_parent_id: null;
    parentZixiPull?: null;
    childZixiPulls: null;
    preferred_source: SourcePreference | number;
    transcodingProfile: null;
    primary_channel_id: number | null;
    primary_source_id: SourcePreference | number | null;
    activeBroadcasterObjects?: ObjectsActiveBroadcaster<AdaptiveChannelActiveBroadcasterIds>;
}

export class SrtTarget extends ZixiObject {
    mediaconnect_flow_id: number;
    delivery_channel_id: number;
    adaptive_channel_id: null;
    medialive_channel_id: null;
    mediaLiveChannel: MediaLiveChannel;
    deliveryChannel: DeliveryChannel;
    mediaconnectFlow: MediaConnectFlow;
    bind_cidr: string;
    host: string;
    port: number;
    remux_kbps: number | null;
    remux_mode: "remux" | "pad";
    encryption?: "" | "aes128" | "aes192" | "aes256";
    latency: number;
    password: string;
    pull_mode: number; // 0/1
    use_private_ip: number; // 0/1
    type: string;
    hostPrefix: string;
    status?: {
        active_broadcaster?: ActiveBroadcaster;
        trace: boolean;
    };
    location?: { ip?: LocationInfo; address?: LocationInfo };
    dynamic_targets: null;
    zixi_pull_parent_id: null;
    parentZixiPull?: null;
    childZixiPulls: null;
    billing_code: string | null;
    billing_password: string | null;
    preferred_source: SourcePreference | number;
    single_output: 0 | 1 | boolean;
    vpc: MediaConnectVpcInterface[];
    transcodingProfile: null;
    pid_mapping?: PidMappingProfile;
    srt_stream_id: string | null;
    external_id?: string;
    primary_channel_id: number | null;
    primary_source_id: SourcePreference | number | null;
    pois_latency_offset: number | null;
    activeBroadcasterObjects?: ObjectsActiveBroadcaster<AdaptiveChannelActiveBroadcasterIds>;
}

export class UdpRtpTarget extends ZixiObject {
    mediaconnect_flow_id: number;
    delivery_channel_id: number;
    medialive_channel_id: number;
    adaptive_channel_id: null;
    mediaLiveChannel: MediaLiveChannel;
    deliveryChannel: DeliveryChannel;
    mediaconnectFlow: MediaConnectFlow;

    bind_cidr: string;
    dont_fragment: number;
    host: string;
    port: number;
    remux_buffer_ms: number;
    remux_disable_padding: number;
    remux_kbps: number | null;
    remux_mode: "remux" | "pad";
    ttl: number;
    rtp: number;
    smoothing: number;
    smpte_2022_fec: number;
    smpte_2022_fec_cols: number;
    smpte_2022_fec_rows: number;
    type: string;
    status?: {
        active_broadcaster?: ActiveBroadcaster;
        trace: boolean;
    };
    location?: { ip?: LocationInfo; address?: LocationInfo };
    dynamic_targets: null;
    zixi_pull_parent_id: null;
    parentZixiPull?: null;
    childZixiPulls: null;
    billing_code: string | null;
    billing_password: string | null;
    preferred_source: SourcePreference | number;
    mtu?: number;
    autopull_mtu?: number;
    vpc: MediaConnectVpcInterface[];
    transcodingProfile: null;
    pid_mapping?: PidMappingProfile;
    external_id?: string;
    primary_channel_id: number | null;
    primary_source_id: SourcePreference | number | null;
    pois_latency_offset: number | null;
    activeBroadcasterObjects?: ObjectsActiveBroadcaster<AdaptiveChannelActiveBroadcasterIds>;
}

export class RtmpPushTarget extends ZixiObject {
    mediaconnect_flow_id: null;
    delivery_channel_id: number | null;
    adaptive_channel_id: null;
    medialive_channel_id: number | null;
    mediaLiveChannel: MediaLiveChannel | null;
    alt_target: string | null;
    deliveryChannel: DeliveryChannel | undefined;
    mediaconnectFlow: undefined;
    ignore_cert: number;
    instance_id: string;
    kbps: number;
    password: string;
    stream_name: string;
    target: string;
    type: string;
    username: string;
    status?: {
        active_broadcaster?: ActiveBroadcaster;
        trace: boolean;
    };
    location?: { ip?: LocationInfo; address?: LocationInfo };
    dynamic_targets: null;
    zixi_pull_parent_id: null;
    parentZixiPull?: null;
    childZixiPulls: null;
    billing_code: string | null;
    billing_password: string | null;
    preferred_source: SourcePreference | number;
    single_output: 0 | 1;
    transcodingProfile: TranscodingProfile | null;
    pid_mapping?: PidMappingProfile;
    external_id?: string;
    primary_channel_id: number | null;
    primary_source_id: SourcePreference | number | null;
    pois_latency_offset: number | null;
    activeBroadcasterObjects?: ObjectsActiveBroadcaster<AdaptiveChannelActiveBroadcasterIds>;
}

export class ZixiPullTarget extends ZixiObject {
    mediaconnect_flow_id: number;
    delivery_channel_id: number;
    adaptive_channel_id: null;
    medialive_channel_id: null;
    mediaLiveChannel: MediaLiveChannel;
    broadcaster: Broadcaster;
    broadcaster_id: number;
    zec_id: number;
    zec: Zec;
    deliveryChannel: DeliveryChannel;
    mediaconnectFlow: MediaConnectFlow;
    merge_mode: "rtp" | "none" | "content" | null;
    merge_window_ms: number;
    input_nic: string;
    instance_id: string;
    latency: number;
    output_id: string;
    output_name: string;
    password: string;
    receiver_id: number;
    receiver_name: string;
    receiver: Receiver;
    source_host: string;
    status?: {
        active_broadcasters?: ActiveBroadcaster[];
        trace: boolean;
        dtls: boolean;
        dtls_cert: string;
        ip: string;
        app?: string;
        build?: number;
        device?: string;
    };
    stream_id: string;
    type: string;
    use_private_ip: number;
    zixi_encryption_key: string;
    location?: { ip?: LocationInfo; address?: LocationInfo };
    dynamic_targets: boolean;
    zixi_pull_parent_id: number;
    disable_redirection: 1 | 0 | boolean;
    parentZixiPull?: ZixiPullTarget;
    childZixiPulls?: ZixiPullTarget[];
    billing_code: string | null;
    billing_password: string | null;
    preferred_source: SourcePreference | number;
    mtu?: number;
    autopull_mtu?: number;
    transcodingProfile: null;
    pid_mapping?: PidMappingProfile;
    external_id?: string;
    primary_channel_id: number | null;
    primary_source_id: SourcePreference | number | null;
    pois_latency_offset: number | null;
    frame_thinning_latency: number;

    activeBroadcasterObjects?: ObjectsActiveBroadcaster<AdaptiveChannelActiveBroadcasterIds>;

    hostPrefix?: string;
}

export class ZixiPushTarget extends ZixiObject {
    mediaconnect_flow_id: number;
    delivery_channel_id: number;
    adaptive_channel_id: null;
    medialive_channel_id: null;
    mediaLiveChannel: MediaLiveChannel;
    alt_target: string | null;
    aws_account_id: number;
    deliveryChannel: DeliveryChannel;
    mediaconnectFlow: MediaConnectFlow;
    instance_id: string;
    is_bonding: number;
    latency: number;
    mediaconnect_flow_arn: string;
    password: string;
    region: string;
    stream_id: string;
    target: string;
    type: string;
    zixi_encryption_key: string;
    ignore_dtls_cert: number;
    status?: {
        active_broadcaster?: ActiveBroadcaster;
        trace: boolean;
        dtls: boolean;
        dtls_cert: string;
        app?: string;
        build?: number;
        device?: string;
    };
    location?: { ip?: LocationInfo; address?: LocationInfo };
    dynamic_targets: null;
    zixi_pull_parent_id: null;
    parentZixiPull?: null;
    childZixiPulls: null;
    billing_code: string | null;
    billing_password: string | null;
    preferred_source: SourcePreference | number;
    vpc: MediaConnectVpcInterface[];
    mtu?: number;
    autopull_mtu?: number;
    transcodingProfile: null;
    pid_mapping?: PidMappingProfile;
    external_id?: string;
    primary_channel_id: number | null;
    primary_source_id: SourcePreference | number | null;
    pois_latency_offset: number | null;
    bondedLinks?: TargetBondedLink[];
    activeBroadcasterObjects?: ObjectsActiveBroadcaster<AdaptiveChannelActiveBroadcasterIds>;

    traffic_shaping_kbps: number | null;
    max_fec_overhead_percent: number | null;
    frame_thinning_latency: number;
}

export class MediaconnectCDITarget extends ZixiObject {
    mediaconnect_flow_id: number;
    delivery_channel_id: null;
    adaptive_channel_id: null;
    medialive_channel_id: null;
    mediaLiveChannel: MediaLiveChannel;
    aws_account_id: number;
    mediaconnectFlow: MediaConnectFlow;
    instance_id: string;
    latency: number;
    mediaconnect_flow_arn: string;
    region: string;
    type: "cdi";
    output_arn: string;
    destination_ip: string;
    destination_port: number;
    vpc: MediaConnectVpcInterface[];
    mediaStreams: MediaConnectMediaStream[];
    location?: null;
    parentZixiPull?: null;
    zixi_pull_parent_id: null;
    childZixiPulls: null;
    transcodingProfile: null;
    preferred_source: undefined;
    activeBroadcasterObjects?: ObjectsActiveBroadcaster<AdaptiveChannelActiveBroadcasterIds>;
}

export class MediaconnectJPEGXSTarget extends ZixiObject {
    mediaconnect_flow_id: number;
    delivery_channel_id: null;
    adaptive_channel_id: null;
    medialive_channel_id: null;
    mediaLiveChannel: MediaLiveChannel;
    aws_account_id: number;
    mediaconnectFlow: MediaConnectFlow;
    instance_id: string;
    latency: number;
    mediaconnect_flow_arn: string;
    region: string;
    type: "jpegxs";
    output_arn: string;
    vpc: MediaConnectVpcInterface[];
    mediaStreams: MediaConnectMediaStream[];
    location?: null;
    parentZixiPull?: null;
    zixi_pull_parent_id: null;
    childZixiPulls: null;
    transcodingProfile: null;
    preferred_source: undefined;
    activeBroadcasterObjects?: ObjectsActiveBroadcaster<AdaptiveChannelActiveBroadcasterIds>;
}

export class MediaconnectEntitlementTarget extends ZixiObject {
    entitlement_arn: string;
    delivery_channel_id: null;
    adaptive_channel_id: null;
    parentZixiPull: null;
    childZixiPulls: null;
    location: null;
    medialive_channel_id: null;
    mediaLiveChannel: null;
    mediaconnect_flow_id: number;
    subscriber_fee_precent: number;
    subscriber_id: number;
    zixi_pull_parent_id: null;
    transcodingProfile: null;
    preferred_source: undefined;
    activeBroadcasterObjects?: ObjectsActiveBroadcaster<AdaptiveChannelActiveBroadcasterIds>;
}
