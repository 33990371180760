import { Injectable } from "@angular/core";
import { Observable, ReplaySubject, from } from "rxjs";
import { map, share } from "rxjs/operators";

import { TranslateService } from "@ngx-translate/core";
import { AlertingProfile, AlertingRule } from "./events-management";
import { AuthService } from "../../../services/auth.service";
import { ZenApiService } from "src/app/services/zen-rpc-service";
import { InferredAPIRequests } from "@zixi/zen-rpc";
import { ALERTING_RULE_OBJECT_TYPE } from "@zixi/models";

@Injectable({
    providedIn: "root"
})
export class EventsManagementService {
    alertingProfiles: Observable<AlertingProfile[]>;
    private alertingProfiles$: ReplaySubject<AlertingProfile[]>;
    private dataStore: {
        alertingProfiles: AlertingProfile[];
    };

    constructor(
        private authService: AuthService,
        private translate: TranslateService,
        private zenApiService: ZenApiService
    ) {
        this.reset();

        this.authService.isLoggedIn.subscribe(isLoggedIn => {
            if (!isLoggedIn) this.reset();
        });
    }

    private reset() {
        this.dataStore = {
            alertingProfiles: []
        };

        this.alertingProfiles$ = new ReplaySubject(1) as ReplaySubject<AlertingProfile[]>;
        this.alertingProfiles = this.alertingProfiles$.asObservable();
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    private prepAlertingProfile(alertingProfile: AlertingProfile) {} // eslint-disable-line @typescript-eslint/no-empty-function

    private updateStore(alertingProfile: AlertingProfile, merge: boolean): void {
        this.prepAlertingProfile(alertingProfile);

        const currentIndex = this.dataStore.alertingProfiles.findIndex(p => p.id === alertingProfile.id);
        if (currentIndex === -1) {
            this.dataStore.alertingProfiles.push(alertingProfile);
            return;
        } else if (merge) {
            const current = this.dataStore.alertingProfiles[currentIndex];

            Object.assign(current, alertingProfile);

            const relationships = [];
            relationships.forEach(overwrite => {
                if (current[overwrite.id] == null) current[overwrite.obj] = null;
            });
        } else {
            this.dataStore.alertingProfiles[currentIndex] = alertingProfile;
        }
    }

    refreshAlertingProfiles(): Observable<AlertingProfile[]> {
        const alertingProfiles$ = from(this.zenApiService.client.alertingProfile.getList()).pipe(share());

        alertingProfiles$.subscribe({
            next: data => {
                const alertingProfiles = data.body.result;

                this.dataStore.alertingProfiles.forEach((existing, existingIndex) => {
                    const newIndex = alertingProfiles.findIndex(p => p.id === existing.id);
                    if (newIndex === -1) this.dataStore.alertingProfiles.splice(existingIndex, 1);
                });

                alertingProfiles.forEach(p => this.updateStore(p, true));

                this.alertingProfiles$.next(Object.assign({}, this.dataStore).alertingProfiles);
            },
            // eslint-disable-next-line no-console
            error: error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_PID_MAPPING_PROFILES"), error)
        });
        return alertingProfiles$.pipe(map(r => r.body.result));
    }

    getAlertingProfile(id: number): Observable<AlertingProfile> {
        const alertingProfile$ = from(this.zenApiService.client.alertingProfile.getById({ params: { id } })).pipe(
            share()
        );
        return alertingProfile$.pipe(map(r => r.body.result));
    }

    setProfileWithOrderedRules(profile: AlertingProfile): AlertingProfile {
        //add property "name" to be sort by
        profile.alertingRules = this.sortRules(profile.alertingRules);
        return profile;
    }

    sortRules(rules: AlertingRule[]): AlertingRule[] {
        return rules
            .map(r => {
                r.name =
                    "" +
                    this.translate.instant(r.object_type.toUpperCase()) +
                    this.translate.instant(r.group.toUpperCase()) +
                    this.translate.instant(r.code.toUpperCase());

                return r;
            })
            .sort((a, b) => {
                return a.name > b.name ? 1 : -1;
            });
    }

    async addAlertingProfile(body: InferredAPIRequests["alertingProfile"]["create"]["body"]) {
        try {
            const result = await this.zenApiService.client.alertingProfile.create({ body });

            const alertingProfile = result.body.result;

            this.updateStore(alertingProfile, false);

            this.alertingProfiles$.next(Object.assign({}, this.dataStore).alertingProfiles);
            return alertingProfile;
        } catch (error) {
            return false;
        }
    }

    async deleteAlertingProfile(id: number) {
        try {
            const result = await this.zenApiService.client.alertingProfile.destroy({ params: { id } });

            const deletedId = result.body.result;
            const index = this.dataStore.alertingProfiles.findIndex(p => p.id === deletedId);
            if (index !== -1) this.dataStore.alertingProfiles.splice(index, 1);
            this.alertingProfiles$.next(Object.assign({}, this.dataStore).alertingProfiles);

            return true;
        } catch (error) {
            return false;
        }
    }

    async updateAlertingProfile(id: number, body: InferredAPIRequests["alertingProfile"]["update"]["body"]) {
        try {
            const result = await this.zenApiService.client.alertingProfile.update({ params: { id }, body });

            const updatedAlertingProfile = result.body.result;
            this.updateStore(updatedAlertingProfile, true);

            this.alertingProfiles$.next(Object.assign({}, this.dataStore).alertingProfiles);
            return result.body.success;
        } catch (error) {
            return false;
        }
    }

    // GET /api/alerting_profiles/:id/rules
    async getAlertingRules(profileID: number) {
        try {
            const result = await this.zenApiService.client.alertingRule.list({ params: { id: profileID } });

            const rules = result.body.result;

            return rules;
        } catch (error) {
            return false;
        }
    }

    // POST /api/alerting_profiles/:id/rules
    async addAlertingRule(
        profileID: number,
        body: InferredAPIRequests["alertingRule"]["upsert"]["body"]
    ): Promise<AlertingRule> {
        try {
            const result = await this.zenApiService.client.alertingRule.upsert({
                params: { id: profileID },
                body
            });
            const rules = result.body.result;
            return rules;
        } catch (error) {
            return error.error;
        }
    }

    // DELETE /api/alerting_profiles/:id/rules/:rule_id
    async deleteAlertingRule(profileID: number, ruleID: number): Promise<AlertingRule | false> {
        try {
            const result = await this.zenApiService.client.alertingRule.destroy({
                params: { id: profileID, rule_id: ruleID }
            });
            const rule = result.body.result;
            return rule;
        } catch (error) {
            return false;
        }
    }

    // /api/alerting_profiles?object_id=ID&object_type=TYPE
    async getEventAlertingProfile(id: number, type: string) {
        try {
            const result = await this.zenApiService.client.alertingProfile.getList({
                query: { object_id: id, object_type: type }
            });

            const alertingProfiles = result.body.result;
            return alertingProfiles[0];
        } catch (error) {
            return false;
        }
    }
    // /api/alerting_profiles/rules
    async getDefaultRules() {
        try {
            const result = await this.zenApiService.client.alertingRule.getDefaultRules();
            const defaultRules = result.body.result;
            return defaultRules;
        } catch (error) {
            return [];
        }
    }

    convertRuleToServer(rule: AlertingRule) {
        return {
            code: rule.code,
            disabled: !!rule.disabled,
            error_notify: !!rule.error_notify,
            error_threshold: rule.error_threshold,
            group: rule.group,
            object_type: rule.object_type as ALERTING_RULE_OBJECT_TYPE,
            ok_notify: !!rule.ok_notify,
            stateful: !!rule.stateful,
            state_error_window: rule.state_error_window,
            state_warning_window: rule.state_warning_window,
            warning_threshold: rule.warning_threshold,
            force_notify: !!rule.force_notify,
            error_escalation_delay: rule.error_escalation_delay,
            warning_escalation_delay: rule.warning_escalation_delay,
            error_deescalation_delay: rule.error_deescalation_delay,
            warning_deescalation_delay: rule.warning_deescalation_delay
        };
    }
}
