import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from "@angular/core";
import { Subscription } from "rxjs";
import { ControlContainer, NgForm } from "@angular/forms";

import { ClustersService } from "../../../pages/clusters/clusters.service";
import { Cluster } from "../../../pages/clusters/cluster";

@Component({
    selector: "zx-clusters-select",
    templateUrl: "./zx-clusters-select.component.html",
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class ZxClustersSelectComponent implements OnInit, OnDestroy {
    @Input() name: string;
    @Input() model: number[];
    @Input() required?: boolean;
    @Input() disabled?: boolean;
    @Input() clusterList?: Cluster[];
    @Output() modelChange = new EventEmitter();

    loading: boolean;
    clusters: Cluster[];
    private clustersSubscription: Subscription;

    constructor(private cs: ClustersService) {}

    modelChanged() {
        this.modelChange.emit(this.model);
    }

    ngOnInit() {
        this.loading = true;
        if (!this.clusterList) {
            this.cs.refreshClusters();
            this.clustersSubscription = this.cs.clusters.subscribe(clusters => {
                this.clusters = clusters;
                this.loading = false;
            });
        } else {
            this.clusters = [...this.clusterList];
            this.loading = false;
        }
    }

    ngOnDestroy() {
        this.clustersSubscription?.unsubscribe();
    }
}
