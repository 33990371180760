<form>
    <div class="modal-header">
        <h3 class="modal-title">{{ "SCTE_35" | translate }} - {{ log.created_at | dateTimeZone : dateFormats.longDateTimeSeconds }}</h3>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div *ngIf="loading">
            <div class="text-center">
                <div class="spinner-border m-5" role="status">
                    <span class="sr-only">{{ "LOADING" | translate }}...</span>
                </div>
            </div>
        </div>
        <div *ngIf="!loading">
            <button type="button" class="btn btn-outline-secondary mb-2" (click)="copyDetails()">
                <fa-icon icon="copy" size="sm" class="me-1"></fa-icon>{{ "COPY" | translate }}
            </button>
            <pre class="mb-0">{{ logDetails | json }}</pre>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="activeModal.close()" ngbAutofocus>
            {{ "CLOSE" | translate }}
        </button>
    </div>
</form>
