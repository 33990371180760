import { Component, OnInit, Input, inject } from "@angular/core";
import { Constants } from "./../../../constants/constants";
import { RecoveryState, Source, MediaConnectSource } from "src/app/models/shared";
import { SourcesService } from "src/app/pages/sources/sources.service";
import { SourcesTypeGuard } from "src/app/utils/type-guards/source-type-guard";

@Component({
    selector: "zx-source",
    template: `
        <div class="ellipsis d-inline" title="{{ model | errorMessage }}" *ngIf="model.name">
            <span *ngIf="showTag" class="badge badge-primary me-1 tag" title="{{ 'SOURCE' | translate }}">S</span>
            <zx-status-full
                [icon]="showStatusIcon"
                [model]="model"
                [status]="status || (model.generalStatus | statusClass)"
                [showStatusText]="showStatusText"
                [showOtherIcons]="showOtherIcons"
                [lockIconText]="sourcesService.DTLSNote(model)"
            ></zx-status-full>
            <fa-icon
                *ngIf="showOtherIcons && SourcesTypeGuard.isSourceType(model) && sourcesService.isAltPath(model)"
                class="me-1"
                icon="alt"
                size="sm"
                title="{{ 'ALT_PATH_ACTIVE' | translate }}"
            ></fa-icon>
            <!-- Link -->
            <a
                *ngIf="showLink"
                [routerLink]="['/' + urls.sources, model.mediaconnect ? 'mediaconnect' : (model.id | uId), model.name]"
                (click)="$event.stopPropagation()"
                ><ngb-highlight title="{{ model.name }}" [result]="model.name" [term]="searchTerm"></ngb-highlight>
            </a>
            <!-- No Link -->
            <span *ngIf="!showLink"
                ><ngb-highlight title="{{ model.name }}" [result]="model.name" [term]="searchTerm"></ngb-highlight>
            </span>
        </div>
    `
})
export class ZxSourceComponent implements OnInit {
    @Input() model: Source | MediaConnectSource;
    @Input() status?: string;
    @Input() showLink?: boolean;
    @Input() showTag?: boolean;
    @Input() showStatusText?: boolean = false;
    @Input() showStatusIcon?: boolean = true;
    @Input() showOtherIcons?: boolean = true;
    @Input() searchTerm?: string | string[];

    public sourcesService = inject(SourcesService);
    SourcesTypeGuard = SourcesTypeGuard;

    urls = Constants.urls;

    recoveryState = RecoveryState;

    ngOnInit() {
        if (!this.model) return;
        if (this.model.readOnly) this.showLink = false;
    }
}
