import { Component, Input } from "@angular/core";
import { Source } from "src/app/models/shared";
import { AnyTarget } from "src/app/pages/channels/channel";

@Component({
    selector: "app-target-source-preference",
    templateUrl: "./target-source-preference.component.html"
})
export class TargetSourcePreferenceComponent {
    @Input() target: AnyTarget;
    @Input() preferredSource: Source;
}
