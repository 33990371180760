import { Component, OnInit, OnDestroy, inject } from "@angular/core";
import { Router, NavigationStart } from "@angular/router";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";

import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { SCTELog } from "../scte";
import { TranslateService } from "@ngx-translate/core";
import { dateFormats } from "src/app/services/time-zone.service";

@Component({
    selector: "app-log-detail-dialog",
    templateUrl: "./log-detail-dialog.component.html"
})
export class LogDetailDialogComponent implements OnInit, OnDestroy {
    log: SCTELog;
    logDetails: Record<string, unknown> | string;
    loading: boolean;

    dateFormats = dateFormats;
    private routeSubscription: Subscription;
    public activeModal = inject(NgbActiveModal);
    private router = inject(Router);
    private translate = inject(TranslateService);

    constructor() {
        this.routeSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationStart))
            .subscribe(() => {
                // Close modal on navigation event
                this.activeModal.close();
            });
    }

    async ngOnInit() {
        this.loading = true;
        if (this.log.parsed_report) {
            this.logDetails = this.log.parsed_report;
        } else {
            this.logDetails = this.translate.instant("API_ERRORS.COULD_NOT_LOAD_SCTE_LOG");
        }
        this.loading = false;
    }

    ngOnDestroy() {
        this.routeSubscription.unsubscribe();
    }

    copyDetails() {
        // JS hack
        const el = document.createElement("textarea");
        el.value = JSON.stringify(this.logDetails);
        el.setAttribute("readonly", "");
        el.style.position = "absolute";
        el.style.left = "-9999px";
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
    }
}
