import { Component, Input, OnChanges, OnInit, SimpleChanges, inject } from "@angular/core";
import { UrlBuilderService } from "src/app/services/url-builder.service";
import { TranslateService } from "@ngx-translate/core";
import { DesnakePipe } from "src/app/pipes/desnake.pipe";
import { Router } from "@angular/router";

@Component({
    selector: "zx-event-title",
    templateUrl: "./zx-event-title.component.html"
})
export class ZxEventTitleComponent implements OnChanges, OnInit {
    title: string;
    @Input() eventType: string;
    @Input() isActiveMute: boolean;
    @Input() objectId: number;
    @Input() objectType: string;
    @Input() objectName: string;
    private router = inject(Router);
    private desnakePipe = inject(DesnakePipe);
    private translateService = inject(TranslateService);
    private urlBuilderService = inject(UrlBuilderService);

    ngOnInit(): void {
        this.setTitle();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.objectType || changes.objectName) {
            this.setTitle();
        }
    }

    private setTitle(): void {
        this.title = getZxEventTitleText(this.objectType, this.objectName, this.translateService, this.desnakePipe);
    }

    public async navigateToObjectDetails($event): Promise<void> {
        const url = await this.urlBuilderService.getObjectUrl(this.objectType, this.objectId, this.objectName);
        if (url) {
            if ($event.ctrlKey) {
                window.open(url.join("/"), "_blank");
            } else this.router.navigate(url);
        }
    }
}

export function getZxEventTitleText(
    objectType: string,
    objectName: string,
    translateService: TranslateService,
    desnakePipe: DesnakePipe
): string {
    const objectTypeTitle = getObjectTypeTitle(objectType, translateService, desnakePipe);
    return `${objectTypeTitle} - ${objectName}`;
}

function getObjectTypeTitle(objectType: string, translateService: TranslateService, desnakePipe: DesnakePipe): string {
    switch (objectType) {
        case "groups":
            return translateService.instant("USER_GROUP");
        case "srt_targets":
            return `${translateService.instant("SRT")} ${translateService.instant("TARGET")}`;
        case "ndi_targets":
            return `${translateService.instant("NDI")} ${translateService.instant("TARGET")}`;
        default:
            return desnakePipe.transform(objectType);
    }
}
