import { Cluster } from "src/app/pages/clusters/cluster";
import { Broadcaster, Source, MediaConnectSource, MediaConnectFlow } from "src/app/models/shared";
import { AdaptiveChannel, DeliveryChannel, FailoverChannel, TargetObjectType } from "src/app/pages/channels/channel";

// === Type Guards in TypeScript ===
// - Type Guards are techniques that allow you to narrow down the type of a variable
//   within a certain block of code, so you can safely access properties specific to that type.
// - Here, I use the 'type' property (a virtual property sent from the server and defined in the entity)
//   to narrow the channel type to a specific one.

// === Grid object types are set in the Grid backend controller within the build function of the GridHelper ===

function isClusterType(gridObject: { type: string }): gridObject is Cluster {
    return gridObject.type === "broadcaster_cluster";
}

function isBroadcasterType(gridObject: { type: string }): gridObject is Broadcaster {
    return gridObject.type === "broadcaster";
}

function isSourceType(gridObject: { type: string }): gridObject is Source {
    return gridObject.type === "source";
}

function isMediaConnectSourceType(gridObject: { type: string }): gridObject is MediaConnectSource {
    return gridObject.type === "mediaconnect_sources";
}

function isAdaptiveChannelType(gridObject: { type: string }): gridObject is AdaptiveChannel {
    return gridObject.type === "adaptive_channel";
}

function isDeliveryChannelType(gridObject: { type: string }): gridObject is DeliveryChannel {
    return gridObject.type === "delivery_channel";
}

function isFailoverChannelType(gridObject: { type: string }): gridObject is FailoverChannel {
    return gridObject.type === "failover_channel";
}

function isMediaConnectFlowType(gridObject: { type: string }): gridObject is MediaConnectFlow {
    return gridObject.type === "mediaconnect_flows";
}

// @ts-ignore - This error occurs because `TargetObjectType` does not have a `category` property, but it does when it is used as a grid object.
function isTargetCategory(gridObject: { category?: string }): gridObject is TargetObjectType {
    return gridObject.category === "target";
}

export const GridObjectsTypeGuard = {
    type: {
        isCluster: isClusterType,
        isBroadcaster: isBroadcasterType,
        isSource: isSourceType,
        isMediaConnectSource: isMediaConnectSourceType,
        isAdaptiveChannel: isAdaptiveChannelType,
        isDeliveryChannel: isDeliveryChannelType,
        isFailoverChannel: isFailoverChannelType,
        isMediaConnectFlow: isMediaConnectFlowType
    },
    category: {
        isTarget: isTargetCategory
    }
};
