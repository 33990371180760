<div class="ellipsis">
    <zx-target
        [model]="{ target: target, apiType: targetsService.getTargetApiType(target) }"
        [showStatusIcon]="true"
        [showOtherIcons]="true"
        [showStatusText]="false"
        [showLink]="true"
        [showTag]="true"
    ></zx-target>
</div>
