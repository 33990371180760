import { Component, OnChanges, Input, SimpleChanges, inject } from "@angular/core";
import { AnyTarget } from "../../../channels/channel";
import { TargetsService } from "../../targets.service";
import { ClipboardService } from "ngx-clipboard";
import { Constants } from "../../../../constants/constants";
import { TargetsTypeGuard } from "src/app/utils/type-guards/targets-type-guard";
import { firstValueFrom } from "rxjs";
import { SourcesService } from "src/app/pages/sources/sources.service";
import { Source } from "src/app/models/shared";

@Component({
    selector: "app-target-details",
    templateUrl: "./target-details.component.html"
})
export class TargetDetailsComponent implements OnChanges {
    @Input() target: AnyTarget;
    @Input() canEdit: boolean;
    @Input() preferredSource?: Source;
    pwVisible = false;
    keyVisible = false;
    arnVisible = false;
    urls = Constants.urls;
    TargetsTypeGuard = TargetsTypeGuard;

    public targetsService = inject(TargetsService);
    private clipboardService = inject(ClipboardService);
    private sourcesService = inject(SourcesService);

    ngOnChanges(changes: SimpleChanges) {
        if (changes.target) {
            if (changes.target.previousValue && changes.target.currentValue) {
                if (changes.target.previousValue.id !== changes.target.currentValue.id) {
                    this.pwVisible = false;
                    this.keyVisible = false;
                    this.arnVisible = false;
                }
            }
        }
    }

    copyString(string: string) {
        this.clipboardService.copy(string);
    }
}
