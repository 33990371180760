import { Component, Input } from "@angular/core";
import { Bitrate } from "src/app/models/shared";

@Component({
    selector: "app-channel-bitrates-source-column",
    templateUrl: "./channel-bitrates-source-column.component.html"
})
export class ChannelBitratesSourceColumnComponent {
    @Input() bitrate: Bitrate;
}
