import { Component, Input, inject } from "@angular/core";
import { ChannelsService } from "../../channels.service";
import { ChannelTypes } from "../../channel";
import { ChannelsTypeGuard } from "src/app/utils/type-guards/channels-type-guard";

@Component({
    selector: "app-channel-alerts",
    templateUrl: "./channel-alerts.component.html"
})
export class ChannelAlertsComponent {
    @Input() channel: ChannelTypes;
    @Input() canEdit: boolean;
    private cs = inject(ChannelsService);
    ChannelsTypeGuard = ChannelsTypeGuard;

    public refreshChannel = () => {
        this.cs.refreshChannel(this.channel, true);
    };
}
