<div>
    <div class="modal-header">
        <h3 class="modal-title">{{ "CONFIGURATION_HELP.TITLE" | translate }}&nbsp;-&nbsp;{{ zec.name }}</h3>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="details-form">
            <ol class="mb-0">
                <li>{{ "CONFIGURATION_HELP.LOGIN" | translate }} {{ "ZEC" | translate }} {{ "UI" | translate }}</li>
                <li translate>CONFIGURATION_HELP.GO_SETTINGS_1</li>
                <li>
                    {{ "CONFIGURATION_HELP.CLICK_ADD" | translate }}<br />
                    <ul *ngIf="multiIOSupport">
                        <li *ngFor="let tunnel_server of zec?.Customer?.tunnel_servers">
                            {{ "HOST" | translate }}: <b>{{ tunnel_server.dns_prefix }}{{ tunnelersHost }}</b
                            ><button
                                class="btn btn-sm btn-xs btn-copy"
                                (click)="copyString(tunnel_server.dns_prefix + tunnelersHost)"
                                type="button"
                                title="{{ 'COPY' | translate }}"
                            >
                                <fa-icon icon="clipboard" size="md"></fa-icon>
                            </button>
                        </li>
                    </ul>
                    <ul *ngIf="!multiIOSupport">
                        <li>
                            {{ "HOST" | translate }}: <b>{{ zec.Customer.dns_prefix }}{{ tunnelersHost }}</b
                            ><button
                                class="btn btn-sm btn-xs btn-copy"
                                (click)="copyString(zec?.Customer.dns_prefix + tunnelersHost)"
                                type="button"
                                title="{{ 'COPY' | translate }}"
                            >
                                <fa-icon icon="clipboard" size="md"></fa-icon>
                            </button>
                        </li>
                    </ul>
                </li>
                <li>
                    {{ "CONFIGURATION_HELP.CLICK_EDIT" | translate }}<br />
                    {{ "USER" | translate }}: <b>{{ zec.tunnel_username }}</b
                    ><button class="btn btn-sm btn-xs btn-copy" (click)="copyString(zec.tunnel_username)" type="button" title="{{ 'COPY' | translate }}">
                        <fa-icon icon="clipboard" size="md"></fa-icon></button
                    ><br />
                    <a *ngIf="!zec.accessKey?.readOnly && zec.accessKey.hasRsaPrivate" href="/api/access_keys/{{ zec.accessKey?.id }}/download">
                        <fa-icon icon="download" size="sm"></fa-icon>
                        {{ zec.accessKey?.name }}
                    </a>
                </li>
                <li>
                    {{ "CONFIGURATION_HELP.CONFIRM_SOURCE" | translate }}<br />
                    {{ "REMOTE_SOURCE_PORT" | translate }}: <b>{{ zec.tunnel?.port }}</b
                    ><button
                        class="btn btn-sm btn-xs btn-copy"
                        (click)="zec.tunnel && copyString(zec.tunnel.port.toString())"
                        type="button"
                        title="{{ 'COPY' | translate }}"
                    >
                        <fa-icon icon="clipboard" size="md"></fa-icon>
                    </button>
                </li>
            </ol>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="activeModal.close()" ngbAutofocus>
            {{ "CLOSE" | translate }}
        </button>
    </div>
</div>
