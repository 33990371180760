<div class="details-form">
    <div class="form-group" *ngIf="!source.medialive_channel_id">
        <h5 translate>AWS_MEDIACONNECT_FLOW</h5>
        <div>
            <span *ngIf="!source.mediaconnect_flow_id">-</span>
            <zx-mediaconnect-flow
                [model]="source.mediaconnectFlow"
                [showStatusIcon]="false"
                [showStatusText]="false"
                [showLink]="true"
                [showTag]="false"
                *ngIf="source.mediaconnect_flow_id"
            ></zx-mediaconnect-flow>
        </div>
    </div>
    <div class="form-group" *ngIf="source.medialive_channel_id">
        <h5 translate>AWS_MEDIALIVE_CHANNEL</h5>
        <div>
            <zx-medialive-channel
                [model]="source.mediaLiveChannel"
                [showStatusIcon]="false"
                [showStatusText]="false"
                [showLink]="true"
                [showTag]="false"
            ></zx-medialive-channel>
        </div>
    </div>
    <div class="form-group">
        <h5 translate>TYPE</h5>
        <div>
            <span>{{ "TO_MEDIACONNECT" | translate }}</span>
        </div>
    </div>
    <div class="form-group" *ngIf="source.awsAccount">
        <h5 translate>AWS_ACCOUNT</h5>
        <div>
            <div class="ellipsis">
                <span>{{ source.awsAccount.name }}</span>
            </div>
        </div>
    </div>
    <div class="form-group" *ngIf="source.elemental_link_region">
        <h5 translate>REGION</h5>
        <div>
            <div class="ellipsis">
                <span>{{ source.elemental_link_region }}</span>
            </div>
        </div>
    </div>
    <div class="form-group" *ngIf="source.feeder_id || source.broadcaster_id || source.input_id || source.elemental_link_id">
        <h5 translate>INPUT</h5>
        <div>
            <zx-feeder *ngIf="source.feeder_id" [model]="source.feeder" [showTag]="true" [showLink]="true" [showStatusIcon]="false"></zx-feeder>
            <zx-broadcaster
                *ngIf="source.broadcaster_id"
                [model]="source.broadcaster"
                [showTag]="true"
                [showLink]="true"
                [showStatusIcon]="false"
            ></zx-broadcaster>
            <span *ngIf="source.input_id"><span *ngIf="source.broadcaster_id || source.feeder_id">&nbsp;/&nbsp;</span>{{ source.input_id }}</span>
            <span *ngIf="source.elemental_link_id">{{ "ELEMENTAL_LINK" | translate }}&nbsp;/&nbsp;{{ source.elemental_link_id }}</span>
        </div>
    </div>
    <div class="form-group" *ngIf="source.alertingProfile">
        <h5 translate>ALERTING_PROFILE</h5>
        <div>
            <span>{{ source.alertingProfile.name }}</span>
        </div>
    </div>
    <div class="form-group" *ngIf="source.max_bitrate">
        <h5 translate>MAX_BITRATE</h5>
        <div>
            <span>{{ source.max_bitrate | bitrate }}</span>
        </div>
    </div>
    <app-generic-detail
        [header]="source.protocol === 'cdi' || source.protocol === 'st2110-jpegxs' ? 'MAX_SYNC_BUFFER' : 'LATENCY'"
        [content]="source.latency ?? ''"
        unit="ms"
    ></app-generic-detail>
    <app-generic-detail header="INGEST_PORT" [content]="source.ingest_port !== 0 && source.ingest_port ? source.ingest_port : ''"></app-generic-detail>
    <app-generic-detail header="WHITELIST_CIDR" [content]="source.whitelist_cidr ?? ''"></app-generic-detail>
</div>
