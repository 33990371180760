<div ngbDropdown display="dynamic">
    <button
        class="btn btn-link p-0 pe-2 ps-1"
        id="timeDropdown"
        ngbDropdownToggle
        [title]="
            timeZoneService.selectedTimeZone()?.text
                ? (timeZoneService.selectedTimeZone()?.text | translate)
                : ('BROWSER_TIME' | translate) +
                  ' (' +
                  (intl.DateTimeFormat().resolvedOptions().timeZoneName || intl.DateTimeFormat().resolvedOptions().timeZone) +
                  ')'
        "
    >
        <fa-icon icon="clock" size="md"></fa-icon>
        <span *ngIf="timeZoneService.selectedTimeZone()?.text" class="d-none d-lg-inline-block ms-1">{{ timeZoneService.selectedTimeZone()?.text }} </span>
        <span *ngIf="!timeZoneService.selectedTimeZone()?.text" class="d-none d-lg-inline-block ms-1">
            {{ "BROWSER_TIME" | translate }} ({{
                intl.DateTimeFormat().resolvedOptions().timeZoneName || intl.DateTimeFormat().resolvedOptions().timeZone
            }})</span
        >
    </button>
    <div ngbDropdownMenu aria-labelledby="timeDropdown" class="shadow-sm minw-320px">
        <div class="px-2 py-2">
            <label for="time_zone">{{ "TIME_ZONE" | translate }}</label>
            <ng-select
                id="time_zone"
                name="time_zone"
                bindLabel="text"
                [items]="orderedTimeZones"
                [clearable]="true"
                placeholder="{{ 'BROWSER_TIME' | translate }} ({{
                    intl.DateTimeFormat().resolvedOptions().timeZoneName || intl.DateTimeFormat().resolvedOptions().timeZone
                }})"
                [ngModel]="timeZoneService.selectedTimeZone()"
                (change)="timeZoneService.setTimeZone($event)"
                title="{{ timeZoneService.selectedTimeZone()?.text }}"
                class="bright"
            >
                <ng-template ng-label-tmp let-item="item">
                    <span title="{{ item.text }}">{{ item.text }}</span>
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-index="index">
                    <span title="{{ item.text }}">{{ item.text }}</span>
                </ng-template>
            </ng-select>
        </div>
        <div class="px-2 pb-2">
            <label class="w-100" for="timeFormat">{{ "DATETIME_FORMAT" | translate }}</label>
            <mat-button-toggle-group id="timeFormat" name="timeFormat" [ngModel]="timeZoneService.selectedTimeFormat()" (change)="timeFormatChange($event)">
                <mat-button-toggle value="12" title="{{ 'MM/DD/YY_12_HOUR' | translate }}">
                    {{ "MM/DD/YY_12_HOUR" | translate }}
                </mat-button-toggle>
                <mat-button-toggle value="24" title="{{ 'DD/MM/YY_24_HOUR' | translate }}">
                    {{ "DD/MM/YY_24_HOUR" | translate }}
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
    </div>
</div>
