import { Component, Input, inject } from "@angular/core";
import { TimeZoneService, dateFormats } from "src/app/services/time-zone.service";

@Component({
    selector: "zx-date-time-zone",
    template: ` {{ timeZoneService.computeDateToTimeZone(this.dateTime, this.dateFormat, this.dateOverride)() }} `
})
export class DateTimeZoneComponent {
    timeZoneService = inject(TimeZoneService);
    @Input() dateTime: string;
    @Input() dateFormat?: dateFormats;
    @Input() dateOverride?: string;
}
