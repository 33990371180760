<div class="details-form">
    <div class="row" *ngIf="TargetsTypeGuard.isZixiPull(target.target) && target.target.zixi_pull_parent_id !== null">
        <div class="col">
            <app-target-parent [target]="target.target"></app-target-parent>
        </div>
    </div>
    <!-- Parent -->
    <div class="form-group" *ngIf="target.target.parentZixiPull">
        <h5 translate>PARENT_TARGET</h5>
        <div>
            <span>{{ target.target.parentZixiPull.name }}</span>
        </div>
    </div>
    <!-- Type -->
    <div class="form-group">
        <h5 translate>TYPE</h5>
        <div>
            <span>{{ target.type_name | uppercase | translate }}</span>
        </div>
    </div>
    <div class="form-group" *ngIf="target.target.alertingProfile">
        <h5 translate>ALERTING_PROFILE</h5>
        <div>
            <a [routerLink]="['/' + urls.configuration.eventsManagement, target.target.alertingProfile.id]">
                {{ target.target.alertingProfile.name }}
            </a>
        </div>
    </div>
    <app-details-location [location]="target.target.location"></app-details-location>
    <!-- Active Broadcaster -->
    <div class="form-group" *ngIf="target._frontData?.active_broadcaster as activeBroadcaster">
        <h5 translate>ACTIVE_BROADCASTER</h5>
        <div class="ellipsis">
            <zx-broadcaster-link
                [id]="activeBroadcaster.id"
                [name]="activeBroadcaster.name"
                [clusterId]="target.target.deliveryChannel?.broadcaster_cluster_id || target.target.activeBroadcasterObjects.cluster_id"
            ></zx-broadcaster-link>
        </div>
    </div>
    <!-- Active Broadcasters -->
    <div class="form-group" *ngIf="target._frontData?.active_broadcaster && !target._frontData?.active_broadcaster.id">
        <h5 translate>ACTIVE_BROADCASTERS</h5>
        <div>
            <div class="ellipsis" *ngFor="let broadcaster of target.target.status?.active_broadcasters">
                <zx-broadcaster
                    [model]="broadcaster"
                    [showTag]="false"
                    [showLink]="true"
                    [showStatusIcon]="false"
                    [clusterId]="target.target.deliveryChannel?.broadcaster_cluster_id"
                ></zx-broadcaster>
            </div>
        </div>
    </div>

    <!-- PID Mapping !-->
    <div class="form-group" *ngIf="TargetsTypeGuard.hasPidMapping(target.target) && target.target?.pid_mapping">
        <h5 translate>PID_MAPPING</h5>
        <div>
            <a [routerLink]="['/' + urls.transformation.pid_mapping_profiles, target.target.pid_mapping.name]">
                {{ target.target.pid_mapping.name }}
            </a>
        </div>
    </div>

    <!-- NDI -->
    <div *ngIf="TargetsTypeGuard.isNdi(target.target)">
        <div class="form-group" *ngIf="target.target.delivery_channel_id != null">
            <h5 translate>CHANNEL</h5>
            <div class="ellipsis">
                <zx-delivery [model]="target.target.deliveryChannel" [showLink]="true"></zx-delivery>
            </div>
        </div>
        <!-- No Channel-->
        <app-generic-detail *ngIf="target.target.delivery_channel_id == null" header="CHANNEL" content="-"></app-generic-detail>
        <app-target-source-preference [target]="target" [preferredSource]="preferredSource"></app-target-source-preference>
    </div>

    <!-- HTTP -->
    <div *ngIf="TargetsTypeGuard.isPublishing(target.target)">
        <!-- Channel -->
        <div class="form-group">
            <h5 translate>CHANNEL</h5>
            <div class="ellipsis">
                <zx-adaptive [model]="target.target.adaptiveChannel" [showLink]="true" *ngIf="target.target.adaptive_channel_id != null"></zx-adaptive>
                <span *ngIf="target.target.adaptive_channel_id == null">-</span>
            </div>
        </div>
        <!-- s3/mediastore URI -->
        <div class="form-group" *ngIf="target.target.type === 's3' || target.target.type === 'mediastore'">
            <h5 translate *ngIf="target.target.type === 's3'">S3_URI</h5>
            <h5 translate *ngIf="target.target.type === 'mediastore'">MEDIASTORE_URI</h5>
            <div>
                <span>{{ target.target.ingest_url }}</span>
            </div>
        </div>
        <!-- Encapsulation -->
        <div class="form-group">
            <h5 translate>ENCAPSULATION</h5>
            <div>
                <span>{{ target.target.encapsulation | uppercase | translate }}</span>
            </div>
        </div>

        <app-generic-detail header="AZURE_ACCOUNT" [content]="target.target?.azureAccount?.name ?? ''"></app-generic-detail>
        <app-generic-detail header="GCP_ACCOUNT" [content]="target.target?.gcpAccount?.name ?? ''"></app-generic-detail>

        <!-- s3/mediastore Region -->
        <div class="form-group" *ngIf="target.target.type === 's3' || target.target.type === 'mediastore'">
            <h5 translate>REGION</h5>
            <div>
                <span>{{ target.target.aws_region }}</span>
            </div>
        </div>
        <!-- s3/mediastore Access Key ID -->
        <div class="form-group" *ngIf="target.target.type === 's3' || target.target.type === 'mediastore'">
            <h5 translate>ACCESS_KEY_ID</h5>
            <div>
                <span>{{ target.target.aws_access_key_id }}</span>
            </div>
        </div>

        <app-generic-detail
            header="AUTH_METHOD"
            [content]="target.target?.auth_method ? (target.target.auth_method | uppercase | translate) : ''"
        ></app-generic-detail>
        <app-generic-detail header="AUTH_USER" [content]="target.target?.auth_user_name ?? ''"></app-generic-detail>

        <!-- Ingest URL -->
        <div class="form-group" *ngIf="target.target.type !== 's3' && target.target.type !== 'mediastore'">
            <h5 translate>INGEST_URL</h5>
            <div>
                <span>{{ target.target.ingest_url }}</span>
            </div>
        </div>
        <!-- Playback URL -->
        <div class="form-group" *ngIf="target.target.playback_url && target.target.playback_url !== ''">
            <h5 translate>PLAYBACK_URL</h5>
            <div>
                <span>{{ target.target.playback_url }}</span>
            </div>
        </div>
        <!-- Play HLS -->
        <div
            class="form-group"
            *ngIf="!target.target.playback_url && (target.target.type === 's3' || target.target.type === 'mediastore' || target.target.type === 'azure')"
        >
            <h5 translate>PLAY_HLS</h5>
            <div>
                <a href="javascript:void(0)" (click)="targetsService.openTargetHLSPreview(target.target)" class="btn btn-play btn-primary"
                    ><fa-icon icon="play" size="xs"></fa-icon
                ></a>
            </div>
        </div>
        <!-- Play Target-->
        <div class="form-group" *ngIf="target.target.playback_url">
            <h5 translate>PLAY_TARGET</h5>
            <div>
                <a href="javascript:void(0)" (click)="targetsService.openTargetPreview(target.target)" class="btn btn-play btn-primary"
                    ><fa-icon icon="play" size="xs"></fa-icon
                ></a>
            </div>
        </div>
        <!-- Upload DVR playlist -->
        <app-generic-detail
            header="UPLOAD_DVR_PLAYLIST"
            [content]="target.target.record_dvr ? ('ENABLED' | translate) : ('DISABLED' | translate)"
        ></app-generic-detail>
        <!-- Propagate Tags -->
        <app-generic-detail
            header="PROPAGATE_TAGS_TO_AWS"
            [content]="target.target.propagate_tags ? ('ENABLED' | translate) : ('DISABLED' | translate)"
        ></app-generic-detail>
        <!-- Delete Outdated -->
        <app-generic-detail
            header="DELETE_OUTDATED"
            [content]="target.target.delete_outdated ? ('ENABLED' | translate) : ('DISABLED' | translate)"
        ></app-generic-detail>
        <!-- Ignore Certificate -->
        <app-generic-detail
            header="IGNORE_CERTIFICATE"
            [content]="target.target.ignore_certificate ? ('ENABLED' | translate) : ('DISABLED' | translate)"
        ></app-generic-detail>
        <app-generic-detail
            header="ENFORCE_SUBTITLES"
            [content]="target.target.monitor_missing_subtitles ? ('ENABLED' | translate) : ('DISABLED' | translate)"
        ></app-generic-detail>
    </div>

    <!-- Pull -->
    <div *ngIf="TargetsTypeGuard.isZixiPull(target.target)">
        <!-- Channel -->
        <div class="form-group" *ngIf="target.target.mediaconnect_flow_id == null">
            <h5 translate>CHANNEL</h5>
            <div class="ellipsis">
                <zx-delivery [model]="target.target.deliveryChannel" [showLink]="true" *ngIf="target.target.delivery_channel_id != null"></zx-delivery>
                <span *ngIf="target.target.delivery_channel_id == null">-</span>
            </div>
        </div>
        <!-- MediaConnect Flow -->
        <div class="form-group" *ngIf="target.target.mediaconnect_flow_id != null">
            <h5 translate>AWS_MEDIACONNECT_FLOW</h5>
            <div class="ellipsis">
                <zx-mediaconnect-flow [model]="target.target.mediaconnectFlow" [showLink]="true"></zx-mediaconnect-flow>
            </div>
        </div>
        <app-target-source-preference [target]="target" [preferredSource]="preferredSource"></app-target-source-preference>
        <!-- ZEC -->
        <div class="form-group" *ngIf="target.target.zec_id">
            <h5 translate>ZEC</h5>
            <div>
                <div class="ellipsis">
                    <zx-zec [model]="target.target.zec" [showStatusIcon]="true" [showStatusText]="false" [showLink]="true" [showTag]="false"></zx-zec>
                </div>
            </div>
        </div>
        <!-- Receiver -->
        <div class="form-group" *ngIf="target.target.receiver_id">
            <h5 translate>RECEIVER</h5>
            <div>
                <div class="ellipsis">
                    <zx-receiver
                        [model]="target.target.receiver"
                        [showStatusIcon]="true"
                        [showStatusText]="false"
                        [showLink]="true"
                        [showTag]="false"
                    ></zx-receiver>
                    <span *ngIf="target.target.output_id">&nbsp;/&nbsp;{{ target.target.output_name }}</span>
                </div>
            </div>
        </div>
        <!-- Broadcaster -->
        <div class="form-group" *ngIf="target.target.broadcaster_id">
            <h5 translate>BROADCASTER</h5>
            <div>
                <div class="ellipsis">
                    <zx-broadcaster
                        [model]="target.target.broadcaster"
                        [showStatusIcon]="true"
                        [showStatusText]="false"
                        [showLink]="true"
                        [showTag]="false"
                    ></zx-broadcaster>
                    <span *ngIf="target.target.output_id">&nbsp;/&nbsp;{{ target.target.output_name }}</span>
                </div>
            </div>
        </div>
        <!-- Receiver Name/ID -->
        <div class="form-group" *ngIf="target.target.receiver_name">
            <h5 translate>RECEIVER_NAME_ID</h5>
            <div>
                <span>{{ target.target.receiver_name }}</span>
            </div>
        </div>
        <!-- Remote IP -->
        <div class="form-group" *ngIf="target.target.status?.ip">
            <h5 translate>CLIENT_IP</h5>
            <div>
                <span>{{ target.target.status.ip }}</span>
            </div>
        </div>
        <!-- Password -->
        <div class="form-group" *ngIf="target.target.password && canEdit">
            <h5 translate>PASSWORD</h5>
            <div>
                <a href="javascript:void(0)" (click)="pwVisible = true" *ngIf="!pwVisible"> {{ "SHOW" | translate }} </a
                ><span *ngIf="pwVisible">{{ target.target.password }}</span> /
                <a href="javascript:void(0)" (click)="copyString(target.target.password)">
                    {{ "COPY" | translate }}
                </a>
            </div>
        </div>
        <!-- Encryption Key -->
        <div class="form-group" *ngIf="target.target.zixi_encryption_key && canEdit">
            <h5 translate>ENCRYPTION_KEY</h5>
            <div>
                <a href="javascript:void(0)" (click)="keyVisible = true" *ngIf="!keyVisible">
                    {{ "SHOW" | translate }}
                </a>
                <span *ngIf="keyVisible">{{ target.target.zixi_encryption_key }}</span> /
                <a href="javascript:void(0)" (click)="copyString(target.target.zixi_encryption_key)">
                    {{ "COPY" | translate }}
                </a>
            </div>
        </div>
        <app-generic-detail
            header="PREFER_PRIVATE_BROADCASTER_IP"
            [content]="target.target.use_private_ip ? ('ENABLED' | translate) : ('DISABLED' | translate)"
        ></app-generic-detail>
        <!-- Latency -->
        <div class="form-group">
            <h5 translate>LATENCY</h5>
            <div>
                <span *ngIf="target.target.latency">{{ target.target.latency }} ms</span>
                <span *ngIf="!target.target.latency">{{ "REMOTE_CONFIGURATION" | translate }}</span>
            </div>
        </div>
        <!-- APPLICATION -->
        <app-generic-detail header="APPLICATION" [content]="target.target?.status?.app"></app-generic-detail>
        <!-- Build number -->
        <app-generic-detail header="BUILD" [content]="target.target?.status?.build"></app-generic-detail>
        <!-- DEVICE -->
        <app-generic-detail header="DEVICE" [content]="target.target?.status?.device"></app-generic-detail>
    </div>

    <!-- SRT -->
    <div *ngIf="TargetsTypeGuard.isSrt(target.target)">
        <!-- TODO: add push/pull subtype -->
        <!-- Channel -->
        <div class="form-group" *ngIf="target.target.mediaconnect_flow_id == null">
            <h5 translate>CHANNEL</h5>
            <div class="ellipsis">
                <zx-delivery [model]="target.target.deliveryChannel" [showLink]="true" *ngIf="target.target.delivery_channel_id != null"></zx-delivery>
                <span *ngIf="target.target.delivery_channel_id == null">-</span>
            </div>
        </div>
        <!-- MediaConnect Flow -->
        <div class="form-group" *ngIf="target.target.mediaconnect_flow_id != null">
            <h5 translate>AWS_MEDIACONNECT_FLOW</h5>
            <div class="ellipsis">
                <zx-mediaconnect-flow [model]="target.target.mediaconnectFlow" [showLink]="true"></zx-mediaconnect-flow>
            </div>
        </div>
        <app-target-source-preference [target]="target" [preferredSource]="preferredSource"></app-target-source-preference>
        <!-- Single Output -->
        <app-generic-detail
            *ngIf="target.target.pull_mode === 0"
            header="SINGLE_OUTPUT"
            [content]="target.target.single_output ? ('ENABLED' | translate) : ('DISABLED' | translate)"
        ></app-generic-detail>
        <!-- Port -->
        <app-generic-detail *ngIf="target.target.pull_mode !== 0" header="PORT" [content]="target.target.port"></app-generic-detail>
        <!-- Bind CIDR -->
        <app-generic-detail *ngIf="target.target.bind_cidr" header="BIND_TO_LOCAL_NIC_BY_CIDR" [content]="target.target.bind_cidr"></app-generic-detail>
        <!-- Target -->
        <div class="form-group" *ngIf="target.target.pull_mode === 0">
            <h5 translate>TARGET</h5>
            <div>
                <span>{{ target.target.host }}:{{ target.target.port }}</span>
            </div>
        </div>
        <!-- Stream ID -->
        <div class="form-group" *ngIf="target.target.pull_mode === 0 && target.target.srt_stream_id">
            <h5 translate>STREAM_ID</h5>
            <div>
                <span>{{ target.target.srt_stream_id }}</span>
            </div>
        </div>
        <!-- Latency -->
        <app-generic-detail header="LATENCY" [content]="target.target.latency ?? ''" unit="ms"></app-generic-detail>
        <!-- Password -->
        <div class="form-group" *ngIf="target.target.password && canEdit">
            <h5 translate>PASSWORD</h5>
            <div>
                <a href="javascript:void(0)" (click)="pwVisible = true" *ngIf="!pwVisible"> {{ "SHOW" | translate }} </a
                ><span *ngIf="pwVisible">{{ target.target.password }}</span> /
                <a href="javascript:void(0)" (click)="copyString(target.target.password)">
                    {{ "COPY" | translate }}
                </a>
            </div>
        </div>
        <!-- Encryption -->
        <div class="form-group">
            <h5 translate>ENCRYPTION</h5>
            <div>
                <span>{{ (target.target.encryption ? target.target.encryption : "DISABLED") | translate }}</span>
            </div>
        </div>
        <app-generic-detail
            *ngIf="target.target.pull_mode === 1 && !target.target.mediaconnect_flow_id"
            header="PREFER_PRIVATE_BROADCASTER_IP"
            [content]="target.target.use_private_ip ? ('ENABLED' | translate) : ('DISABLED' | translate)"
        ></app-generic-detail>
    </div>

    <!-- Push -->
    <div *ngIf="TargetsTypeGuard.isZixiPush(target.target)">
        <!-- Channel -->
        <div class="form-group" *ngIf="target.target.mediaconnect_flow_id == null">
            <h5 translate>CHANNEL</h5>
            <div class="ellipsis">
                <zx-delivery [model]="target.target.deliveryChannel" [showLink]="true" *ngIf="target.target.delivery_channel_id != null"></zx-delivery>
                <span *ngIf="target.target.delivery_channel_id == null">-</span>
            </div>
        </div>
        <!-- MediaConnect Flow -->
        <div class="form-group" *ngIf="target.target.mediaconnect_flow_id != null">
            <h5 translate>AWS_MEDIACONNECT_FLOW</h5>
            <div class="ellipsis">
                <zx-mediaconnect-flow [model]="target.target.mediaconnectFlow" [showLink]="true"></zx-mediaconnect-flow>
            </div>
        </div>
        <app-target-source-preference [target]="target" [preferredSource]="preferredSource"></app-target-source-preference>
        <!-- Target Host -->
        <div class="form-group" *ngIf="target.target.target">
            <h5 translate>TARGET_HOST</h5>
            <div>
                <span>{{ target.target.target }}</span>
            </div>
        </div>
        <!-- Alt Target Host -->
        <div class="form-group" *ngIf="target.target.alt_target">
            <h5 translate>ALT_TARGET_HOST</h5>
            <div>
                <span>{{ target.target.alt_target }}</span>
            </div>
        </div>
        <!-- Stream ID -->
        <div class="form-group">
            <h5 translate>STREAM_ID</h5>
            <div>
                <span>{{ target.target.stream_id }}</span>
            </div>
        </div>
        <!-- Password -->
        <div class="form-group" *ngIf="target.target.password && canEdit">
            <h5 translate>PASSWORD</h5>
            <div>
                <a href="javascript:void(0)" (click)="pwVisible = true" *ngIf="!pwVisible"> {{ "SHOW" | translate }} </a
                ><span *ngIf="pwVisible">{{ target.target.password }}</span> /
                <a href="javascript:void(0)" (click)="copyString(target.target.password)">
                    {{ "COPY" | translate }}
                </a>
            </div>
        </div>
        <!-- Encryption Key -->
        <div class="form-group" *ngIf="target.target.zixi_encryption_key && canEdit">
            <h5 translate>ENCRYPTION_KEY</h5>
            <div>
                <a href="javascript:void(0)" (click)="keyVisible = true" *ngIf="!keyVisible">
                    {{ "SHOW" | translate }}
                </a>
                <span *ngIf="keyVisible">{{ target.target.zixi_encryption_key }}</span> /
                <a href="javascript:void(0)" (click)="copyString(target.target.zixi_encryption_key)">
                    {{ "COPY" | translate }}
                </a>
            </div>
        </div>
        <!-- Latency -->
        <app-generic-detail header="LATENCY" [content]="target.target.latency ?? ''" unit="ms"></app-generic-detail>
        <!-- APPLICATION -->
        <app-generic-detail header="APPLICATION" [content]="target.target?.status?.app"></app-generic-detail>
        <!-- Build number -->
        <app-generic-detail header="BUILD" [content]="target.target?.status?.build"></app-generic-detail>
        <!-- DEVICE -->
        <app-generic-detail header="DEVICE" [content]="target.target?.status?.device"></app-generic-detail>
        <!-- Ignore DTLS certificate -->
        <div class="form-group">
            <h5 translate>IGNORE_DTLS_CERTIFICATE</h5>
            <div>
                <span>{{ (target.target.ignore_dtls_cert ? "ENABLED" : "DISABLED") | translate }}</span>
            </div>
        </div>
    </div>

    <!-- UDP/RTP/RIST -->
    <div *ngIf="TargetsTypeGuard.isUdpRtp(target.target) || TargetsTypeGuard.isRist(target.target)">
        <!-- Channel -->
        <div class="form-group" *ngIf="target.target.delivery_channel_id != null">
            <h5 translate>CHANNEL</h5>
            <div class="ellipsis">
                <zx-delivery [model]="target.target.deliveryChannel" [showLink]="true" *ngIf="target.target.delivery_channel_id != null"></zx-delivery>
            </div>
        </div>
        <!-- MediaConnect Flow -->
        <div class="form-group" *ngIf="target.target.mediaconnect_flow_id != null">
            <h5 translate>AWS_MEDIACONNECT_FLOW</h5>
            <div class="ellipsis">
                <zx-mediaconnect-flow [model]="target.target.mediaconnectFlow" [showLink]="true"></zx-mediaconnect-flow>
            </div>
        </div>
        <!-- MediaLive Channel -->
        <div class="form-group" *ngIf="target.target.medialive_channel_id != null">
            <h5 translate>MEDIALIVE</h5>
            <div class="ellipsis">
                <zx-medialive-channel [model]="target.target.mediaLiveChannel" [showLink]="true"></zx-medialive-channel>
            </div>
        </div>
        <!-- No Channel-->
        <div
            class="form-group"
            *ngIf="target.target.mediaconnect_flow_id == null && target.target.medialive_channel_id == null && target.target.delivery_channel_id == null"
        >
            <h5 translate>CHANNEL</h5>
            <div>
                <span>-</span>
            </div>
        </div>
        <app-target-source-preference [target]="target" [preferredSource]="preferredSource"></app-target-source-preference>
        <!-- Target -->
        <div class="form-group">
            <h5 translate>TARGET</h5>
            <div>
                <span>{{ target.target.host }}:{{ target.target.port }}</span>
            </div>
        </div>
        <!-- Local NIC CIDR -->
        <app-generic-detail *ngIf="target.target.bind_cidr" header="LOCAL_NIC_CIDR" [content]="target.target.bind_cidr"></app-generic-detail>
        <app-generic-detail *ngIf="TargetsTypeGuard.isUdpRtp(target.target)" header="TTL" [content]="target.target.ttl ?? ''"></app-generic-detail>
        <!-- SMPTE 2022 FEC -->
        <div class="form-group" *ngIf="target.target.smpte_2022_fec > 0">
            <h5 translate>SMPTE_2022_FEC</h5>
            <div>
                <span> {{ target.target.smpte_2022_fec }}D ({{ target.target.smpte_2022_fec_rows }}x{{ target.target.smpte_2022_fec_cols }})</span>
            </div>
        </div>
        <!-- Smoothing -->
        <app-generic-detail header="SMOOTHING" [content]="target.target.smoothing ?? ''" unit="ms"></app-generic-detail>
        <div class="form-group" *ngIf="target.target.remux_kbps">
            <h5 translate>REMUX</h5>
            <div>
                <span>{{ target.target.remux_kbps | number : "1.0-0" }} kbps</span>
            </div>
        </div>
        <app-generic-detail header="REMUX_MODE" [content]="(target.target.remux_mode.toUpperCase() | translate) ?? ''"></app-generic-detail>
    </div>
    <!-- RTMP -->
    <div *ngIf="TargetsTypeGuard.isRtmpPush(target.target)">
        <!-- Channel -->
        <div class="form-group" *ngIf="target.target.medialive_channel_id == null">
            <h5 translate>CHANNEL</h5>
            <div class="ellipsis">
                <zx-delivery [model]="target.target.deliveryChannel" [showLink]="true" *ngIf="target.target.delivery_channel_id != null"></zx-delivery>
                <span *ngIf="target.target.delivery_channel_id == null">-</span>
            </div>
        </div>
        <!-- MediaLive Channel -->
        <div class="form-group" *ngIf="target.target.medialive_channel_id != null">
            <h5 translate>AWS_MEDIALIVE_CHANNEL</h5>
            <div class="ellipsis">
                <zx-medialive-channel [model]="target.target.mediaLiveChannel" [showLink]="true"></zx-medialive-channel>
            </div>
        </div>
        <app-target-source-preference [target]="target" [preferredSource]="preferredSource"></app-target-source-preference>
        <div class="form-group" *ngIf="target.target.transcodingProfile">
            <h5 translate>TRANSCODING_PROFILE</h5>
            <div>
                <a [routerLink]="['/' + urls.transformation.transcoding_profiles, target.target.transcodingProfile.name]">
                    {{ target.target.transcodingProfile.name }}
                </a>
            </div>
        </div>

        <app-generic-detail
            header="SINGLE_OUTPUT"
            [content]="target.target.single_output ? ('ENABLED' | translate) : ('DISABLED' | translate)"
        ></app-generic-detail>

        <app-generic-detail header="TARGET" [content]="target.target.target ?? ''"></app-generic-detail>
        <app-generic-detail header="ALT_TARGET" [content]="target.target.alt_target ?? ''"></app-generic-detail>
        <app-generic-detail header="STREAM_NAME" [content]="target.target.stream_name ?? ''"></app-generic-detail>
        <app-generic-detail *ngIf="canEdit" header="USERNAME" [content]="target.target.username ?? ''"></app-generic-detail>
        <!-- Password -->
        <div class="form-group" *ngIf="target.target.password && canEdit">
            <h5 translate>PASSWORD</h5>
            <div>
                <a href="javascript:void(0)" (click)="pwVisible = true" *ngIf="!pwVisible"> {{ "SHOW" | translate }} </a
                ><span *ngIf="pwVisible">{{ target.target.password }}</span> /
                <a href="javascript:void(0)" (click)="copyString(target.target.password)">
                    {{ "COPY" | translate }}
                </a>
            </div>
        </div>
        <!-- Bitrate -->
        <div class="form-group">
            <h5 translate>BITRATE</h5>
            <div>
                <span>{{ target.target.kbps || "Auto Detect" }} <span *ngIf="target.target.kbps">kbps</span></span>
            </div>
        </div>
    </div>

    <!-- CDI -->
    <div *ngIf="TargetsTypeGuard.isMediaconnectCDI(target.target)">
        <!-- MediaConnect Flow -->
        <div class="form-group" *ngIf="target.target.mediaconnect_flow_id != null">
            <h5 translate>AWS_MEDIACONNECT_FLOW</h5>
            <div class="ellipsis">
                <zx-mediaconnect-flow [model]="target.target.mediaconnectFlow" [showLink]="true"></zx-mediaconnect-flow>
            </div>
        </div>
        <!-- MediaStreams -->
        <div class="form-group" *ngIf="target.target.mediaStreams && target.target.mediaStreams.length > 0">
            <h5 translate>MEDIACONNECT_MEDIA_STREAMS</h5>
            <div>
                <span *ngFor="let strm of target.target.mediaStreams"> {{ strm.name }} ({{ strm.stream_type }})<br /> </span>
            </div>
        </div>
        <!-- Target -->
        <div class="form-group">
            <h5 translate>TARGET</h5>
            <div>
                <span>{{ target.target.destination_ip }}:{{ target.target.destination_port }}</span>
            </div>
        </div>
    </div>

    <!-- JPEGXS -->
    <div *ngIf="TargetsTypeGuard.isMediaconnectJPEGXS(target.target)">
        <!-- MediaConnect Flow -->
        <div class="form-group" *ngIf="target.target.mediaconnect_flow_id != null">
            <h5 translate>AWS_MEDIACONNECT_FLOW</h5>
            <div class="ellipsis">
                <zx-mediaconnect-flow [model]="target.target.mediaconnectFlow" [showLink]="true"></zx-mediaconnect-flow>
            </div>
        </div>
        <!-- MediaStreams -->
        <div class="form-group" *ngIf="target.target.mediaStreams && target.target.mediaStreams.length > 0">
            <h5 translate>MEDIACONNECT_MEDIA_STREAMS</h5>
            <div>
                <span *ngFor="let strm of target.target.mediaStreams"> {{ strm.name }} ({{ strm.stream_type }}) :{{ strm.port }}<br /> </span>
            </div>
        </div>
        <!-- VPCs -->
        <div class="form-group" *ngIf="target.target.vpc && target.target.vpc.length > 0">
            <h5 translate>VPC</h5>
            <div>
                <span *ngFor="let v of target.target.vpc"> {{ v.name }} - {{ v.destination_ip }}<br /></span>
            </div>
        </div>
    </div>

    <!-- MEDIALIVEHTTP -->
    <div *ngIf="TargetsTypeGuard.isMediaLiveHttp(target.target)">
        <!-- Channel -->
        <div class="form-group" *ngIf="target.target.medialive_channel_id == null">
            <h5 translate>CHANNEL</h5>
            <div class="ellipsis">
                <zx-delivery [model]="target.target.deliveryChannel" [showLink]="true" *ngIf="target.target.delivery_channel_id != null"></zx-delivery>
                <span *ngIf="target.target.delivery_channel_id == null">-</span>
            </div>
        </div>
        <!-- MediaLive Channel -->
        <div class="form-group" *ngIf="target.target.medialive_channel_id != null">
            <h5 translate>AWS_MEDIALIVE_CHANNEL</h5>
            <div class="ellipsis">
                <zx-medialive-channel [model]="target.target.mediaLiveChannel" [showLink]="true"></zx-medialive-channel>
            </div>
        </div>
        <!-- s3/mediastore URI -->
        <div
            class="form-group"
            *ngIf="target.target.destination === 's3' || target.target.destination === 'mediastore' || target.target.destination === 'http'"
        >
            <h5 translate *ngIf="target.target.destination === 's3'">S3_URI</h5>
            <h5 translate *ngIf="target.target.destination === 'mediastore'">MEDIASTORE_URI</h5>
            <h5 translate *ngIf="target.target.destination === 'http'">HTTP_URI</h5>
            <div>
                <span>{{ target.target.ingest_url }}</span>
            </div>
        </div>
    </div>

    <!-- Mediaconnect Entitlement -->
    <div *ngIf="TargetsTypeGuard.isMediaconnectEntitlement(target.target)">
        <app-generic-detail header="ENTITLEMENT_ARN" [content]="target.target.entitlement_arn ?? ''"></app-generic-detail>
        <app-generic-detail header="DATA_SUBSCRIBER_FEE" [content]="target.target.subscriber_fee_precent" unit="%"></app-generic-detail>
        <app-generic-detail header="SUBSCRIBER_ACCOUNT_ID" [content]="target.target.subscriber_id ?? ''"></app-generic-detail>
    </div>

    <!-- Advanced -->
    <div>
        <div *ngIf="TargetsTypeGuard.isZixiPull(target.target)">
            <app-generic-detail header="HITLESS_FAILOVER_LATENCY" [content]="target.target.merge_window_ms ?? ''" unit="ms"></app-generic-detail>
            <app-generic-detail header="USE_NAME_AS_STREAM_ID" [content]="target.target.stream_id ? ('ENABLED' | translate) : ''"></app-generic-detail>
            <app-generic-detail header="DISABLE_REDIRECTION" [content]="target.target.disable_redirection ? ('YES' | translate) : ''"></app-generic-detail>
        </div>
        <div>
            <app-copy-credentials
                *ngIf="TargetsTypeGuard.hasBillingCode(target.target) && target.target.billing_code"
                [user]="target.target.billing_code"
                [password]="target.target.billing_password"
                [showPasswordStrength]="false"
                [label]="'BILLING_CREDENTIALS' | translate"
                [userLabel]="'COPY_CODE' | translate"
                [canEdit]="canEdit"
            ></app-copy-credentials>
        </div>
        <app-generic-detail *ngIf="TargetsTypeGuard.hasMTU(target.target)" header="MTU" [content]="target.target.mtu ?? ''"></app-generic-detail>
        <app-generic-detail
            *ngIf="TargetsTypeGuard.hasExternalID(target.target)"
            header="EXTERNAL_ID"
            [content]="target.target.external_id ?? ''"
        ></app-generic-detail>
        <app-generic-detail
            *ngIf="TargetsTypeGuard.hasPOISLatency(target.target)"
            header="POIS_LAT"
            [content]="target.target.pois_latency_offset ?? ''"
            unit="ms"
        ></app-generic-detail>
    </div>
</div>
