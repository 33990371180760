import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class DiagramService {
    private showDownstream$ = new BehaviorSubject<boolean>(
        localStorage.getItem("diagramDownstream") === "false" ? false : true
    );

    get getShowDownstream() {
        return this.showDownstream$.asObservable();
    }

    setShowDownstream(value: boolean) {
        this.showDownstream$.next(value);
    }
}
