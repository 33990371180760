import { Component, OnInit, OnDestroy, OnChanges, Input, SimpleChanges, inject } from "@angular/core";
//
import * as _ from "lodash";
import { TargetObjectType } from "src/app/pages/channels/channel";
import { TargetsService } from "src/app/pages/targets/targets.service";
import { Thumb } from "src/app/pages/sources/sources.service";
import { BaseThumbnailComponent } from "src/app/pages/sources/source/base-thumbnail/base-thumbnail.component";

@Component({
    selector: "app-target-thumbnail",
    templateUrl: "./target-thumbnail.component.html"
})
export class TargetThumbnailComponent extends BaseThumbnailComponent implements OnInit, OnDestroy, OnChanges {
    @Input() target: TargetObjectType | null;

    private targetsService = inject(TargetsService);

    async ngOnInit() {
        if (this.useService) {
            this.thumbnailsSubscription = this.targetsService.thumbnails.subscribe(async thumbnails => {
                this.thumbnailData = thumbnails.find(
                    (t: Thumb) =>
                        t.id === this.target?.id && t.type === this.targetsService.getTargetApiType(this.target)
                );
                if (this.thumbnailData) {
                    this.setOverlay();
                    this.setThumbnail();
                }
            });
        } else {
            this.setOverlay();
            this.setThumbnail();
        }
    }

    ngOnDestroy() {
        this.target = null;
        this.thumbnailData = null;
        this.thumbnailsSubscription?.unsubscribe();
        this.stopPreviewRefresh();
    }

    isTargetDown(target: TargetObjectType) {
        if (!target?.status?.stats?.net_bitrate) return true;
        return false;
    }

    async setThumbnail() {
        if (!this.target || !this.target.is_enabled || this.isTargetDown(this.target)) {
            // Not Enabled
            this.imgSource = "assets/images/no_preview.png";
            this.pts = null;
            this.hasAudioLevels = false;
            this.hasDecodeErrorMsg = "";
            this.initialized = true;
            this.loading = false;
            this.canLivePlay = false;
        } else {
            await this.loadPreview();
            if (!this.useService) {
                this.stopPreviewRefresh();
                this.timeoutId = setTimeout(async () => {
                    if (this.target) await this.setThumbnail();
                }, this.refreshInterval);
            }
            this.rotateAudio();
        }
    }

    async loadPreview() {
        if (!this.target) return;
        if (!this.ctx) return;

        if (this.useService) {
            const { body, headers } = this.thumbnailData?.thumbnail || {};
            this.processData(body, headers);
            return;
        } else {
            try {
                const result = await this.targetsService.getTargetThumbnail(this.target);
                this.processData(result?.body, result?.headers);
            } catch (e) {
                this.imgSource = "assets/images/no_preview.png";
                this.gotResult = true;
                this.pts = null;
                this.hasAudioLevels = false;
                this.hasDecodeErrorMsg = "";
                this.initialized = true;
                this.loading = false;
                this.canLivePlay = false;
                return;
            }
        }
    }

    async ngOnChanges(changes: SimpleChanges) {
        // a hack due to widgets "dynamic components" in grids
        const isAllFirstChange = Object.keys(changes).every(key => changes[key].firstChange);
        if (isAllFirstChange) return;

        // Check for source changed
        if (changes.target && changes.target.previousValue?.id !== changes.target.currentValue?.id) {
            if (changes.target.currentValue === null) {
                this.stopPreviewRefresh();
                this.loading = false;
                this.initialized = false;
            } else {
                this.stopPreviewRefresh();
                this.loading = true;
                this.initialized = false;
                this.imgSource = "assets/images/thumb-stream.png";
                this.pts = null;
                this.hasAudioLevels = false;
                this.hasDecodeErrorMsg = "";
            }
            if (this.useService && this.thumbnailData?.id === changes.target?.currentValue?.id) this.setThumbnail();
        }

        if (changes.refreshInterval && changes.refreshInterval.currentValue !== changes.refreshInterval.previousValue) {
            this.stopPreviewRefresh();
            this.setThumbnail();
        }
    }

    protected getActiveBroadcasterId() {
        return this.target?.activeBroadcasterObjects?.bx_id;
    }
}
