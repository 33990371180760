import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";
import {
    ReactFlow,
    Background,
    Controls,
    MiniMap,
    Panel,
    ReactFlowProvider,
    useEdgesState,
    useNodesState,
    useReactFlow,
    Node,
    XYPosition
} from "@xyflow/react";
import ELK from "elkjs";
import _ from "lodash";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { BroadcasterNode } from "./react-components/BroadcasterNode";
import { SourceNode } from "./react-components/SourceNode";
import { MediaConnectSourceNode } from "./react-components/MediaconnectSourceNode";
import { TargetNode } from "./react-components/TargetNode";
import { ChannelNode } from "./react-components/ChannelNode";
import { GroupNode } from "./react-components/GroupNode";
import ElkEdge from "./react-components/ElkEdge";
import { MermaidObject } from "./react-flow-diagram.component";
import { elkOptions, formatData } from "./ReactFlowFunctions";
import { RecursiveElkNode, FlattenedNode, CustomElkFlowEdge } from "./types";
import { MediaConnectSource, Source } from "src/app/models/shared";

const edgeTypes = {
    smart: ElkEdge
};

const elk = new ELK();

class LayoutFlattener {
    flattenNodes(layout: { children?: RecursiveElkNode[] }): FlattenedNode[] {
        const result: FlattenedNode[] = [];

        const processNode = (node: RecursiveElkNode, parentAbsPosition: XYPosition = { x: 0, y: 0 }, parentId?: string): void => {
            const absPosition = {
                x: node.x + parentAbsPosition.x,
                y: node.y + parentAbsPosition.y
            };

            result.push({
                id: node.id,
                position: { x: node.x, y: node.y },
                absPosition,
                data: node.data,
                width: node.width,
                height: node.height,
                type: node.type,
                ports: node.ports,
                properties: node.properties,
                layoutOptions: node.layoutOptions,
                parentId,
                extent: parentId ? "parent" : undefined
            });

            node.children?.forEach(child => processNode(child, absPosition, node.id));
        };

        layout?.children?.forEach(child => processNode(child));
        return result;
    }

    flattenEdges(layout: { children?: RecursiveElkNode[]; edges?: CustomElkFlowEdge[] }): CustomElkFlowEdge[] {
        const nestedEdges: CustomElkFlowEdge[] = [];
        const collectEdges = (node: RecursiveElkNode): void => {
            if (node.edges?.length) {
                nestedEdges.push(...node.edges);
            }
            node.children?.forEach(child => collectEdges(child));
        };
        layout?.children?.forEach(child => collectEdges(child));
        // top level and nested egdges together
        const allEdges: CustomElkFlowEdge[] = [...(layout.edges ?? []), ...nestedEdges];
        // pass edge section data
        const flatEdges = allEdges.map(e => ({
            ...e,
            data: { container: e.container, sections: e.sections, downstream: e.downstream }
        }));
        return flatEdges;
    }
}

// uses elkjs to give each node a layouted position
export const getLayoutedNodes = async (nodes: RecursiveElkNode[], edges: CustomElkFlowEdge[], direction: string) => {
    const graph = {
        id: "root",
        layoutOptions: {
            "elk.direction": direction,
            ...elkOptions
        },
        children: nodes,
        edges: edges.map(e => ({
            ...e,
            sources: [e.sourceHandle || e.source],
            targets: [e.targetHandle || e.target]
        }))
    };

    const layoutedGraph = await elk.layout(graph);
    const typedLayout = layoutedGraph as RecursiveElkNode;
    const layoutFlattener = new LayoutFlattener();
    const flatNodes = layoutFlattener.flattenNodes(typedLayout);
    const flatEdges = layoutFlattener.flattenEdges(typedLayout);

    return {
        nodes: flatNodes,
        edges: flatEdges
    };
};

const nodeTypes = {
    feeder: BroadcasterNode,
    receiver: BroadcasterNode,
    zec: BroadcasterNode,
    broadcaster: BroadcasterNode,
    source: SourceNode,
    mediaconnect_source: MediaConnectSourceNode,
    target: TargetNode,
    channel: ChannelNode,
    group: GroupNode
};

const ReactDiagramComponent: React.FC<MermaidObject> = props => {
    const { getNodes, getEdges, setNodes, setEdges } = useReactFlow();
    const [nodes, , onNodesChange] = useNodesState([]);
    const [edges, , onEdgesChange] = useEdgesState([]);
    const [direction, setDirection] = useState(localStorage.getItem("diagramOrientation") ?? "DOWN");
    const [showDetails, setShowDetails] = useState(localStorage.getItem("diagramDetails") === "false" ? false : true);
    const [showBroadcasters, setShowBroadcasters] = useState(localStorage.getItem("diagramBroadcasters") === "false" ? false : true);
    const [showClusters, setShowClusters] = useState(localStorage.getItem("diagramClusters") === "false" ? false : true);
    const [showThumbnails, setShowThumbnails] = useState(localStorage.getItem("diagramThumbnails") === "false" ? false : true);
    const [showDownstream, setShowDownstream] = useState(localStorage.getItem("diagramDownstream") === "false" ? false : true);
    const [isSource, setIsSource] = useState(false);
    const diagramService = (window as any).diagramService;

    if (props.baseObject instanceof Source || props.baseObject instanceof MediaConnectSource) {
        if (isSource === false) {
            setIsSource(true);
        }
    }

    const layoutNodes = async (dataNodes, dataEdges, direction: string) => {
        const { nodes, edges } = await getLayoutedNodes(dataNodes, dataEdges, direction);
        setNodes(nodes as unknown[] as Node[]);
        setEdges(edges);
    };

    // Update
    useEffect(() => {
        const fetchData = async () => {
            const { children: dataNodes, edges: dataEdges } = formatData(props, direction, showDetails, showBroadcasters, showThumbnails, showClusters);
            await layoutNodes(dataNodes, dataEdges, direction);
        };

        fetchData();
    }, [props, direction, showDetails, showBroadcasters, showThumbnails, showClusters]);

    const changeLayout = useCallback(
        (direction: string) => {
            (async () => {
                // Set direction
                setDirection(direction);
                const { children: dataNodes, edges: dataEdges } = formatData(props, direction, showDetails, showBroadcasters, showThumbnails, showClusters);
                await layoutNodes(dataNodes, dataEdges, direction);
                localStorage.setItem("diagramOrientation", direction);
            })();
        },
        [props, showDetails, showBroadcasters, showThumbnails, showClusters, showDownstream]
    );

    const onInit = () => {
        // lock
        const buttons = document.getElementsByClassName("react-flow__controls-button react-flow__controls-interactive");
        const buttonToClick = buttons[0] as HTMLElement;
        buttonToClick.click();
    };

    // Calculate the initial layout on mount.
    useLayoutEffect(() => {
        changeLayout(direction);
    }, []);

    const toggleDetails = useCallback(
        (showDetails: boolean) => {
            (async () => {
                // Set direction
                setShowDetails(showDetails => !showDetails);
                const { children: dataNodes, edges: dataEdges } = formatData(props, direction, showDetails, showBroadcasters, showThumbnails, showClusters);
                await layoutNodes(dataNodes, dataEdges, direction);
                localStorage.setItem("diagramDetails", showDetails ? "true" : "false");
            })();
        },
        [props, direction, showBroadcasters, showThumbnails, showClusters, showDownstream]
    );

    const toggleBroadcasters = useCallback(
        (showBroadcasters: boolean) => {
            (async () => {
                setShowBroadcasters(showBroadcasters => !showBroadcasters);
                const { children: dataNodes, edges: dataEdges } = formatData(props, direction, showDetails, showBroadcasters, showThumbnails, showClusters);
                await layoutNodes(dataNodes, dataEdges, direction);
                localStorage.setItem("diagramBroadcasters", showBroadcasters ? "true" : "false");
            })();
        },
        [props, direction, showDetails, showThumbnails, showClusters, showDownstream]
    );

    const toggleThumbnails = useCallback(
        (showThumbnails: boolean) => {
            (async () => {
                // Set direction
                setShowThumbnails(showThumbnails => !showThumbnails);
                const { children: dataNodes, edges: dataEdges } = formatData(props, direction, showDetails, showBroadcasters, showThumbnails, showClusters);
                await layoutNodes(dataNodes, dataEdges, direction);
                localStorage.setItem("diagramThumbnails", showThumbnails ? "true" : "false");
            })();
        },
        [props, direction, showBroadcasters, showDetails, showClusters, showDownstream]
    );

    const toggleClusters = useCallback(
        (showClusters: boolean) => {
            (async () => {
                // Set direction
                setShowClusters(showClusters => !showClusters);
                const { children: dataNodes, edges: dataEdges } = formatData(props, direction, showDetails, showBroadcasters, showThumbnails, showClusters);
                await layoutNodes(dataNodes, dataEdges, direction);
                localStorage.setItem("diagramClusters", showClusters ? "true" : "false");
            })();
        },
        [props, direction, showBroadcasters, showDetails, showThumbnails, showDownstream]
    );

    const toggleDownstream = useCallback(
        (showDownstream: boolean) => {
            (async () => {
                // Set direction
                setShowDownstream(showDownstream => !showDownstream);
                diagramService.setShowDownstream(showDownstream);
                const { children: dataNodes, edges: dataEdges } = formatData(props, direction, showDetails, showBroadcasters, showThumbnails, showClusters);
                await layoutNodes(dataNodes, dataEdges, direction);
                localStorage.setItem("diagramDownstream", showDownstream ? "true" : "false");
            })();
        },
        [props, direction, showBroadcasters, showDetails, showClusters, showThumbnails, diagramService]
    );

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div style={{ width: "100%", height: "100%" }}>
            {nodes && edges && (
                <ReactFlow
                    onInit={onInit}
                    nodes={nodes}
                    edges={edges}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    fitView
                    minZoom={0.1}
                    maxZoom={1.25}
                    nodeTypes={nodeTypes}
                    edgeTypes={edgeTypes}
                    attributionPosition="bottom-right"
                    defaultEdgeOptions={{
                        zIndex: 1001
                    }}
                >
                    <Panel position="top-right">
                        <div className="diagramSettings">
                            <Button
                                id="settings-button"
                                aria-controls={open ? "setting-menu" : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? "true" : undefined}
                                onClick={handleClick}
                                variant="outlined"
                                color="secondary"
                                size="small"
                            >
                                <i className="fa fa-cog fa-sm"></i>
                            </Button>
                            <Menu
                                id="settings-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    "aria-labelledby": "settings-button"
                                }}
                            >
                                <MenuItem className="text-secondary" onClick={() => toggleBroadcasters(!showBroadcasters)}>
                                    {showBroadcasters && <i className="fa fa-square-check fa-md fa-fw me-1"></i>}
                                    {!showBroadcasters && <i className="far fa-square fa-md fa-fw me-1"></i>}Show Broadcasters
                                </MenuItem>
                                <MenuItem className="text-secondary" onClick={() => toggleClusters(!showClusters)}>
                                    {showClusters && <i className="fa fa-square-check fa-md fa-fw me-1"></i>}
                                    {!showClusters && <i className="far fa-square fa-md fa-fw me-1"></i>}Show Clusters
                                </MenuItem>
                                <MenuItem className="text-secondary" onClick={() => toggleDetails(!showDetails)}>
                                    {showDetails && <i className="fa fa-square-check fa-md fa-fw me-1"></i>}
                                    {!showDetails && <i className="far fa-square fa-md fa-fw me-1"></i>}Show Details
                                </MenuItem>
                                <MenuItem className="text-secondary" onClick={() => toggleThumbnails(!showThumbnails)}>
                                    {showThumbnails && <i className="fa fa-square-check fa-md fa-fw me-1"></i>}
                                    {!showThumbnails && <i className="far fa-square fa-md fa-fw me-1"></i>}Show Thumbnails
                                </MenuItem>
                                {isSource && (
                                    <MenuItem className="text-secondary" onClick={() => toggleDownstream(!showDownstream)}>
                                        {showDownstream && <i className="fa fa-square-check fa-md fa-fw me-1"></i>}
                                        {!showDownstream && <i className="far fa-square fa-md fa-fw me-1"></i>}Show Downstream Objects
                                    </MenuItem>
                                )}
                                <MenuItem className="text-secondary" onClick={() => changeLayout("RIGHT")}>
                                    <i className="fa fa-arrow-alt-from-left fa-sm fa-fw me-1"></i>Horizontal Layout
                                </MenuItem>
                                <MenuItem className="text-secondary" onClick={() => changeLayout("DOWN")}>
                                    <i className="fa fa-arrow-alt-from-top fa-sm fa-fw me-1"></i>Vertical Layout
                                </MenuItem>
                            </Menu>
                        </div>
                    </Panel>
                    <Background />
                    <Controls position="top-left" showInteractive={true} />
                    <MiniMap position="bottom-left" pannable zoomable nodeClassName={nodeClassNameMiniMap} />
                </ReactFlow>
            )}
        </div>
    );
};

// Set node class name on Mini Map
const nodeClassNameMiniMap = node => {
    return node.type + " " + node.data.status;
};

export default (props: MermaidObject) => (
    <ReactFlowProvider>
        <ReactDiagramComponent {...props} />
    </ReactFlowProvider>
);
