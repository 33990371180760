import { Component, OnInit, OnDestroy, OnChanges, Input, SimpleChanges, inject } from "@angular/core";
//
import { SourcesService, Thumb } from "../../sources.service";
import { Source, MediaConnectSource } from "../../../../models/shared";
import * as _ from "lodash";
import { UsersService } from "../../../account-management/users/users.service";
import { map } from "rxjs";
import mpegts from "mpegts.js";
import { BaseThumbnailComponent } from "../base-thumbnail/base-thumbnail.component";
import { SourcesTypeGuard } from "src/app/utils/type-guards/source-type-guard";

@Component({
    selector: "app-source-thumbnail",
    templateUrl: "./source-thumbnail.component.html"
})
export class SourceThumbnailComponent extends BaseThumbnailComponent implements OnInit, OnDestroy, OnChanges {
    @Input() source: Source | MediaConnectSource | null;
    @Input() set fullyLoaded(b: boolean) {
        if (this.source) {
            if (b === true) this.setThumbnail();
            else this.loading = true;
        }
    }
    SourcesTypeGuard = SourcesTypeGuard;
    private sourcesService = inject(SourcesService);
    private userService = inject(UsersService);

    get isSourceHideThumbnail() {
        if (this.source && this.SourcesTypeGuard.isSourceType(this.source)) return this.source.hide_thumbnail;
        if (this.source && this.SourcesTypeGuard.isMediaConnectSource(this.source))
            return !this.source.mediaconnectFlow?.enable_thumbnails;
        return false;
    }

    get isSourcePreviewUrl() {
        if (this.source && this.SourcesTypeGuard.isSourceType(this.source)) return !!this.source.preview_url;
        return true;
    }

    async ngOnInit() {
        this.canAccountLivePlay$ = this.userService.user.pipe(map(u => !!u.proxy_play_allowed));

        if (this.useService) {
            this.thumbnailsSubscription = this.sourcesService.thumbnails.subscribe(async thumbnails => {
                this.thumbnailData = thumbnails.find((t: Thumb) => t.id === this.source?.id);
                if (this.thumbnailData) {
                    this.setOverlay();
                    this.setThumbnail();
                }
            });
        } else {
            this.setOverlay();
            this.setThumbnail();
        }
        if (this.isSourceHideThumbnail) this.overlay = false;
    }

    ngOnDestroy() {
        this.source = null;
        this.thumbnailData = null;
        this.thumbnailsSubscription?.unsubscribe();
        this.stopPreviewRefresh();
    }

    async setThumbnail() {
        if (!this.source || !this.source.is_enabled) {
            // Not Enabled
            this.imgSource = "assets/images/no_preview.png";
            this.pts = null;
            this.hasAudioLevels = false;
            this.hasDecodeErrorMsg = "";
            this.initialized = true;
            this.loading = false;
            this.canLivePlay = false;
        } else if (!this.isSourcePreviewUrl) {
            // No Preview URL
            this.imgSource = "assets/images/no_preview.png";
            this.pts = null;
            this.hasAudioLevels = false;
            this.hasDecodeErrorMsg = "";
            this.initialized = true;
            this.loading = false;
            this.canLivePlay = false;
        } else if (this.isSourceHideThumbnail) {
            this.imgSource = "assets/images/preview_disabled.jpg";
            this.pts = null;
            this.hasAudioLevels = false;
            this.hasDecodeErrorMsg = "";
            this.initialized = true;
            this.loading = false;
            this.canLivePlay = false;
        } else {
            await this.loadPreview();
            if (!this.useService) {
                this.stopPreviewRefresh();
                this.timeoutId = setTimeout(async () => {
                    if (this.source) await this.setThumbnail();
                }, this.refreshInterval);
            }
            this.rotateAudio();
        }
    }

    async loadPreview() {
        if (!this.source) return;
        if (!this.ctx) return;

        if (this.useService) {
            const { body, headers } = this.thumbnailData?.thumbnail || {};
            this.processData(body, headers);
            return;
        } else {
            try {
                const result = await this.sourcesService.getSourceThumbnail(this.source.id);
                this.processData(result?.body, result?.headers);
                return;
            } catch (e) {
                this.imgSource = "assets/images/no_preview.png";
                this.gotResult = true;
                this.pts = null;
                this.hasAudioLevels = false;
                this.hasDecodeErrorMsg = "";
                this.initialized = true;
                this.loading = false;
                this.canLivePlay = false;
                return;
            }
        }
    }

    async ngOnChanges(changes: SimpleChanges) {
        // a hack due to widgets "dynamic components" in grids
        const isAllFirstChange = Object.keys(changes).every(key => changes[key].firstChange);
        if (isAllFirstChange) return;

        if (
            changes.source &&
            changes.source.previousValue?.id !== changes.source.currentValue?.id &&
            this.allowLivePlay
        ) {
            this.stopStream();
        }

        // Check for source changed
        if (
            changes.source &&
            (changes.source.previousValue?.id !== changes.source.currentValue?.id ||
                !!changes.source.previousValue?.preview_url !== !!changes.source.currentValue?.preview_url)
        ) {
            if (changes.source.currentValue === null) {
                this.stopPreviewRefresh();
                this.loading = false;
                this.initialized = false;
            } else {
                this.stopPreviewRefresh();
                this.loading = true;
                this.initialized = false;
                this.imgSource = "assets/images/thumb-stream.png";
                this.pts = null;
                this.hasAudioLevels = false;
                this.hasDecodeErrorMsg = "";
            }
            if (this.useService && this.thumbnailData?.id === changes.source?.currentValue?.id) this.setThumbnail();
        }

        if (changes.refreshInterval && changes.refreshInterval.currentValue !== changes.refreshInterval.previousValue) {
            this.stopPreviewRefresh();
            this.setThumbnail();
        }
    }

    protected getActiveBroadcasterId() {
        if (this.source && this.SourcesTypeGuard.isSourceType(this.source)) {
            return this.source?.activeBroadcasterObjects?.bx_id;
        }
    }

    playStream() {
        if (!this.canLivePlay) return;

        this.livePlayer = mpegts.createPlayer(
            {
                type: "mse", // could also be mpegts, m2ts, flv
                isLive: true,
                url: "/play/sources/" + this.source?.id + ".ts"
            },
            {
                isLive: true,
                liveBufferLatencyChasing: true
            }
        );

        this.livePlayer.on(mpegts.Events.ERROR, function (data, detail, info) {
            this.videoErrorMsg = "Error" + " - " + info?.msg || "Unexpected Error";
        });
        this.livePlayer.on(mpegts.ErrorTypes.NETWORK_ERROR, function (data, detail, info) {
            this.videoErrorMsg = "Network Error" + " - " + info?.msg || "Unexpected Network Error";
        });
        this.livePlayer.on(mpegts.ErrorTypes.MEDIA_ERROR, function (data, detail, info) {
            this.videoErrorMsg = "Media Error" + " - " + info?.msg || "Unexpected Media Error";
        });
        this.livePlayer.on(mpegts.ErrorTypes.OTHER_ERROR, function (data, detail, info) {
            this.videoErrorMsg = "Other Error" + " - " + info?.msg || "Unexpected Error";
        });

        setTimeout(() => {
            if (mpegts.getFeatureList().mseLivePlayback) {
                const videoElement = document.getElementById("videoElement") as HTMLMediaElement;
                this.livePlayer?.attachMediaElement(videoElement);
                this.livePlayer?.load();
                this.livePlayer?.play();
            }
        }, 0);
    }

    stopStream() {
        this.videoErrorMsg = null;
        this.livePlayer?.destroy();
        this.livePlayer = null;
    }
}
