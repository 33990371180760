import { Injectable } from "@angular/core";
import { urlBuilder } from "@zixi/shared-utils";
import { Constants } from "../constants/constants";
import { OpenIssue } from "../models/status";
import { BroadcastersService } from "../components/broadcasters/broadcasters.service";
import { Router } from "@angular/router";
import { ZmEvent } from "../pages/events/event";
import { AutomationService } from "../pages/automation/automation.service";
import { firstValueFrom } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class UrlBuilderService {
    constructor(private bs: BroadcastersService, private router: Router, private as: AutomationService) {}

    async objectToUrl(model: OpenIssue | ZmEvent): Promise<string[] | void> {
        const modelAsAny = model as any;
        const id = <number>modelAsAny.object_id ?? <number>modelAsAny.id;
        const name = <string>modelAsAny.object_name ?? <string>modelAsAny.name;
        const type = <string>modelAsAny.object_type ?? <string>modelAsAny.type;
        const url = await this.getObjectUrl(type, id, name);
        // eslint-disable-next-line no-console
        if (!url) return console.error(`Couldn't find ${type}'s URL`);
        return url;
    }

    async navigateToObject(model: ZmEvent | OpenIssue) {
        const url = await this.objectToUrl(model);
        if (url) this.router.navigate(url);
    }

    async getObjectUrl(objectType: string, objectId: number, objectName: string): Promise<string[] | undefined> {
        switch (objectType) {
            case "feeder":
                return urlBuilder.getRegularZecUrl(objectId, Constants.urls.feeders, objectName);
            case "receiver":
                return urlBuilder.getRegularZecUrl(objectId, Constants.urls.receivers, objectName);
            case "zec":
                return urlBuilder.getRegularZecUrl(objectId, Constants.urls.zecs, objectName);
            case "source":
                return urlBuilder.getRegularSourceUrl(objectId, objectName);
            case "broadcaster":
                let clusterId: number;
                const bx = this.bs.getCachedBroadcaster(objectId);
                if (bx) {
                    clusterId = bx.broadcaster_cluster_id;
                } else {
                    const bx = await firstValueFrom(this.bs.refreshBroadcaster(objectId));
                    clusterId = bx.broadcaster_cluster_id;
                }
                return urlBuilder.getRegularBroadcasterUrl(clusterId, objectId, objectName);
            case "adaptive_channel":
                return urlBuilder.getRegularChannelUrl(objectId, Constants.urls.channelTypes.adaptive, objectName);
            case "failover_channel":
            case "medialive_channels":
            case "delivery_channel":
                return urlBuilder.getRegularChannelUrl(objectId, Constants.urls.channelTypes.delivery, objectName);
            case "mediaconnect_flows":
                return urlBuilder.getRegularChannelUrl(objectId, Constants.urls.channelTypes.mediaconnect, objectName);
            case "mediaconnect_sources":
                return [Constants.urls.sources, "mediaconnect", objectName];
            case "http":
            case "publishing_target":
            case "s3":
            case "gcp":
            case "azure":
            case "mediastore":
            case "youtube":
                return urlBuilder.getRegularTargetUrl(objectId, Constants.urls.targetTypes.http, objectName);
            case "zixi_pull":
                return urlBuilder.getRegularTargetUrl(objectId, Constants.urls.targetTypes.pull, objectName);
            case "zixi_push":
            case "push":
            case "mediaconnect":
                return urlBuilder.getRegularTargetUrl(objectId, Constants.urls.targetTypes.push, objectName);
            case "rtmp_push":
                return urlBuilder.getRegularTargetUrl(objectId, Constants.urls.targetTypes.rtmp, objectName);
            case "udp_rtp":
                return urlBuilder.getRegularTargetUrl(objectId, Constants.urls.targetTypes.udp_rtp, objectName);
            case "srt_targets":
                return urlBuilder.getRegularTargetUrl(objectId, Constants.urls.targetTypes.srt, objectName);
            case "ndi_targets":
                return urlBuilder.getRegularTargetUrl(objectId, Constants.urls.targetTypes.ndi, objectName);
            case "rist":
                return urlBuilder.getRegularTargetUrl(objectId, Constants.urls.targetTypes.rist, objectName);
            case "remote_access":
                return [Constants.urls.remote_access, objectName];
            case "task_sets":
                let tsName;
                let ts = this.as.getCachedTaskSet(null, objectId);
                if (ts) {
                    tsName = ts.name;
                } else {
                    ts = await firstValueFrom(this.as.refreshTaskSet(objectId));
                    tsName = ts.name;
                }
                return [Constants.urls.automation, tsName];
            default:
                return undefined;
        }
    }

    encodeRFC3986URIComponent(str: string) {
        return encodeURIComponent(str).replace(/[!'()*]/g, c => `%${c.charCodeAt(0).toString(16).toUpperCase()}`);
    }
}
