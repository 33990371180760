<div>
    <div class="modal-header">
        <span *ngIf="saving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
        <h3 class="modal-title">Keep start time or duration</h3>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body p-0 text-center">
        <button type="button" class="btn btn-outline-secondary mx-2 my-3" [disabled]="saving" (click)="submit(false)">Keep start time</button>
        <button type="button" class="btn btn-outline-secondary mx-2 my-3" [disabled]="saving" (click)="submit(true)">Keep duration</button>
    </div>
    <app-error [marginBottom]="true" [marginTop]="false"></app-error>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" [disabled]="saving" (click)="activeModal.close()">
            <fa-icon icon="times"></fa-icon>{{ "CANCEL" | translate }}
        </button>
    </div>
</div>
