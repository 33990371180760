import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import _ from "lodash";
import { Subscription } from "rxjs";
import { Source } from "src/app/models/shared";
import { SourcesService } from "src/app/pages/sources/sources.service";
import { SharedService } from "src/app/services/shared.service";

export type SelectedSource = {
    source: Source;
    priority: number;
    backup?: boolean;
    preferred?: boolean;
    min_bitrate: number;
};

@Component({
    selector: "app-source-selection",
    templateUrl: "./source-selection.component.html"
})
export class SourceSelectionComponent implements OnInit, OnDestroy, OnChanges {
    @Input() formSubmitted: boolean;
    @Input() selectedFailoverSources: SelectedSource[];
    @Input() requireSlate: boolean;
    @Input() maxSourceSelection: number;
    @Input() isFailoverForm?: boolean;
    @Output() selectedFailoverSourcesChange = new EventEmitter();

    sources: Source[];

    autoPullDisabled: boolean;
    failoverSourceFilter: string;
    sourcesLoading = false;
    isEdit = false;
    page = 1;
    pageSize = 10;
    slatesCount = 0;

    get isFailoverSlateCountValid(): boolean {
        return this.slatesCount < 2 && this.isFailoverForm;
    }

    private sourcesSubscription: Subscription;

    constructor(private ss: SourcesService, private sharedService: SharedService) {}

    selectedFailoverSourcesChanged() {
        this.selectedFailoverSourcesChange.emit(
            this.selectedFailoverSources.map(s => ({
                source: s.source,
                priority: s.priority,
                min_bitrate: s.min_bitrate
            }))
        );
    }

    ngOnInit(): void {
        //  Adjust checkboxes values.
        for (const src of this.selectedFailoverSources) {
            this.calucateCheckboxes(src);
        }

        // Sources
        this.sourcesLoading = true;
        this.ss.refreshSources(true);
        this.sourcesSubscription = this.ss.sources.subscribe((sources: Source[]) => {
            this.sources = sources
                ?.filter(s => !s.is_hidden)
                ?.filter(s => !_.find(this.selectedFailoverSources, fs => fs.source?.id === s.id));
            this.sourcesLoading = false;
        });
    }

    selectFailoverSource(source: Source) {
        this.selectedFailoverSources.push({
            source,
            priority: 2,
            backup: false,
            preferred: true,
            min_bitrate: 0
        });

        this.sources = this.sources.filter(s => {
            return s.id !== source.id;
        });

        this.autoPullCheck();
        this.countSlates();
        this.selectedFailoverSourcesChanged();
    }

    deselectFailoverSource(failoverSource: SelectedSource) {
        this.sources.push(failoverSource.source);
        this.sources = this.sharedService.sort(this.sources || [], "name", "asc");
        this.selectedFailoverSources = this.selectedFailoverSources.filter(fs => {
            return fs.source.id !== failoverSource.source.id;
        });

        this.autoPullCheck();
        this.countSlates();
        this.selectedFailoverSourcesChanged();
    }

    autoPullCheck(): boolean {
        this.autoPullDisabled = _.some(this.selectedFailoverSources, fs => fs.source.disable_autopull);
        return this.autoPullDisabled;
    }

    ngOnDestroy(): void {
        this.sourcesSubscription.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges) {
        changes;
        for (const src of this.selectedFailoverSources) {
            this.calucateCheckboxes(src);
        }
    }

    preferredPriorityChanged(failoverSource: SelectedSource) {
        if (failoverSource.preferred) failoverSource.backup = false;
        failoverSource.priority = this.calculatePriority(failoverSource);
        this.countSlates();
        this.selectedFailoverSourcesChanged();
    }

    backupPriorityChanged(failoverSource: SelectedSource) {
        if (failoverSource.backup) failoverSource.preferred = false;
        failoverSource.priority = this.calculatePriority(failoverSource);
        this.countSlates();
        this.selectedFailoverSourcesChanged();
    }

    calculatePriority(failoverSource: SelectedSource): number {
        if (failoverSource.preferred && failoverSource.backup) {
            // eslint-disable-next-line no-console
            console.log("ERROR: Both priorities selected");
        }
        if (failoverSource.preferred) return 2;
        if (failoverSource.backup) return 0;
        return 1;
    }

    calucateCheckboxes(src: SelectedSource) {
        switch (src.priority) {
            case 0:
                {
                    src.backup = true;
                    src.preferred = false;
                }
                break;
            case 1:
                {
                    src.backup = false;
                    src.preferred = false;
                }
                break;
            case 2:
                {
                    src.preferred = true;
                    src.backup = false;
                }
                break;
        }
    }

    countSlates() {
        this.slatesCount = 0;
        for (const src of this.selectedFailoverSources) {
            if (src.priority === 0) this.slatesCount++;
        }
    }

    validSourceCount(): boolean {
        return this.selectedFailoverSources.length > 0;
    }

    validSlatesCount(): boolean {
        return !this.requireSlate || this.slatesCount > 0;
    }
}
