import { Component, Input, OnChanges, inject } from "@angular/core";
import { ClipboardService } from "ngx-clipboard";

@Component({
    selector: "app-copy-credentials",
    templateUrl: "./copy-credentials.component.html"
})
export class CopyCredentialsComponent implements OnChanges {
    @Input() canEdit? = true;
    @Input() showPasswordStrength? = true;
    @Input() user?: string;
    @Input() password?: string;
    @Input() label? = "API Credentials";
    @Input() userLabel? = "Copy User";
    @Input() passwordLabel? = "Copy Password";

    pwVisible = false;

    private cbs = inject(ClipboardService);

    copy(pw: string) {
        this.cbs.copy(pw.toString());
    }

    ngOnChanges() {
        this.pwVisible = false;
    }
}
