import { Input, ViewChild, ElementRef, HostListener, Directive } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Observable, Subscription } from "rxjs";
import mpegts from "mpegts.js";
import { Constants } from "../../../../constants/constants";
import { Thumb } from "src/app/pages/sources/sources.service";

@Directive()
export abstract class BaseThumbnailComponent {
    // --- Shared Inputs ---
    @Input() bordered?: boolean = false;
    @Input() info?: boolean = false;
    @Input() overlay?: boolean = false;
    @Input() infoBtm?: boolean;
    @Input() showTag?: boolean;
    @Input() grid?: boolean;
    @Input() refreshInterval = 8000;
    @Input() bitrateOverlay?: boolean = false;
    @Input() alertOverlay?: boolean = true;
    @Input() audioOverlay?: boolean = true;
    @Input() timeOverlay?: boolean = true;
    @Input() useService?: boolean = false;
    @Input() isSmall?: boolean = false;
    @Input() searchTerm?: string | string[];
    @Input() allowLivePlay?: boolean;

    // --- Shared State ---
    timeoutId: NodeJS.Timeout | null;
    gotResult = false;
    loading = true;
    initialized = false;
    superWide = false;
    hasAudioLevels = false;
    livePlayer: mpegts.Player | null = null;
    isHorizontal = false;
    imgSource: string | SafeResourceUrl = "assets/images/thumb-stream.png";
    urls = Constants.urls;
    canLivePlay = false;
    canAccountLivePlay$: Observable<boolean>;

    // --- Shared Template References ---
    @ViewChild("thumbnailContainer", { static: true }) thumbnailContainer: ElementRef;
    @ViewChild("thumbnail") thumbnail: ElementRef;
    @ViewChild("overlayAudio", { static: true }) overlayAudio: ElementRef<HTMLCanvasElement>;

    // --- Shared Internal Variables ---
    ctx: CanvasRenderingContext2D;
    gradient: CanvasGradient;
    pts: number | null = null;
    hasDecodeErrorMsg = "";
    videoErrorMsg: any = null;
    thumbnailData: Thumb | null | undefined;
    thumbnailsSubscription?: Subscription;

    // Inject the sanitizer via the constructor so we can sanitize URLs.
    constructor(protected sanitizer: DomSanitizer) {}

    @HostListener("window:resize", [])
    protected onResize() {
        this.rotateAudio();
    }

    // common functions
    protected setOverlay() {
        if (!this.overlayAudio) return;
        // Audio Levels
        this.overlayAudio.nativeElement.width = 23;
        this.overlayAudio.nativeElement.height = 100;
        const ctx = this.overlayAudio.nativeElement.getContext("2d");
        if (!ctx) return;
        this.ctx = ctx;
        this.gradient = this.ctx.createLinearGradient(0, 0, 0, 100); // top to bottom gradient
        this.gradient.addColorStop(0, Constants.colors.error);
        this.gradient.addColorStop(0.33, Constants.colors.warning);
        this.gradient.addColorStop(0.66, Constants.colors.good);
        this.ctx.fillStyle = this.gradient;
    }

    protected stopPreviewRefresh(): void {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
            this.timeoutId = null;
        }
    }

    protected rotateAudio(): void {
        setTimeout(async () => {
            if (this.thumbnailContainer && this.thumbnailContainer.nativeElement) {
                this.isHorizontal = this.thumbnailContainer.nativeElement.offsetHeight < 100;
            }
        });
    }

    protected processData(body, headers) {
        if (body) {
            this.hasDecodeErrorMsg = headers?.get("Zixi-Decode-Error-Message") ?? "";
            if (body.byteLength < 100) {
                if (this.hasDecodeErrorMsg) {
                    if (this.imgSource === "assets/images/thumb-stream.png") {
                        this.imgSource = "assets/images/no_preview.png";
                    }
                    this.pts = null;
                    this.hasAudioLevels = false;
                    this.initialized = true;
                    this.loading = false;
                    this.canLivePlay = false;
                    return;
                } else {
                    throw new Error("Invalid thumbnail response");
                }
            }

            const blob = new Blob([body], { type: "Content-Type" });
            this.imgSource = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob));
            this.initialized = true;
            this.canLivePlay = !!this.getActiveBroadcasterId();

            const pts = headers?.get("Zixi-Frame-PTS");
            if (pts) this.pts = parseInt(pts);
            const audioLevels = headers?.get("Zixi-Audio-Levels");
            if (audioLevels) {
                this.hasAudioLevels = true;
                this.updateAudioLevels(JSON.parse(audioLevels));
            } else {
                this.hasAudioLevels = false;
            }
            this.loading = false;
        } else {
            this.imgSource = "assets/images/no_preview.png";
            this.gotResult = true;
            this.pts = null;
            this.hasAudioLevels = false;
            this.hasDecodeErrorMsg = "";
            this.initialized = true;
            this.loading = false;
            this.canLivePlay = false;
            return;
        }
    }

    updateAudioLevels(levels: number[]): void {
        if (!this.ctx || !this.overlayAudio) return;
        this.ctx.clearRect(0, 0, 23, 100);
        for (let i = 0; i < levels.length; i++) {
            // values are in dBFS [-100,0], convert it to [0,100]
            levels[i] += 100;
            const val = levels[i] / 100;
            const x = i * 10 + 3 * i; // each bar is 10 pixel width, + 3 pixel gap
            let top = this.overlayAudio.nativeElement.height - val * this.overlayAudio.nativeElement.height;
            const height = val * this.overlayAudio.nativeElement.height;

            // draw levels, height of each "row" is 5 pixels + 2 pixels empty
            const h = height % 7;
            top += h;

            this.ctx.fillStyle = this.gradient;
            let bottom = this.overlayAudio.nativeElement.height;
            while (bottom > top) {
                this.ctx.fillRect(x, bottom - 5, 10, 5);
                bottom -= 7;
            }
            if (h > 2) {
                this.ctx.fillRect(x, bottom - h, 10, h);
            }
        }
    }

    protected onImgLoad(): void {
        if (this.thumbnail && this.thumbnail.nativeElement) {
            const height = this.thumbnail.nativeElement.height;
            const width = this.thumbnail.nativeElement.width;
            this.superWide = width / height > 16 / 9;
        }
    }

    protected getSanitizedUrl(url: string): SafeResourceUrl {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }

    protected abstract getActiveBroadcasterId(): number | null | undefined;
}
