<div class="form-group">
    <h5 translate>SOURCE_PREFERENCE</h5>
    <div>
        <span *ngIf="target.target.preferred_source === 0">{{ "PREFER_ANY_SOURCE" | translate }}</span>
        <span *ngIf="target.target.preferred_source === -2">{{ "PRIMARY_ONLY_SOURCE" | translate }}</span>
        <span *ngIf="target.target.preferred_source === -3">{{ "BACKUP_ONLY_SOURCE" | translate }}</span>
        <span *ngIf="target.target.preferred_source === -1">{{ "PREFER_PRIMARY_SOURCE" | translate }}</span>
        <span *ngIf="target.target.preferred_source === -4">{{ "PREFER_BACKUP_SOURCE" | translate }}</span>
        <div *ngIf="target.target.preferred_source > 0 && preferredSource">
            <zx-source [model]="preferredSource" [showStatusIcon]="true" [showStatusText]="false" [showLink]="true" [showTag]="false"></zx-source>
        </div>
    </div>
</div>
