import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from "@angular/core";
import { Subscription } from "rxjs";
import { ControlContainer, NgForm } from "@angular/forms";

import { User } from "./../../../models/shared";
import { UsersService } from "../../../pages/account-management/users/users.service";

@Component({
    selector: "zx-users-select",
    templateUrl: "./zx-users-select.component.html",
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class ZxUsersSelectComponent implements OnInit, OnDestroy {
    @Input() name: string;
    @Input() model: User[];
    @Input() required?: boolean;
    @Output() modelChange = new EventEmitter();

    loading: boolean;
    users: User[];
    private usersSubscription: Subscription;

    constructor(private gs: UsersService) {}

    modelChanged() {
        this.modelChange.emit(this.model);
    }

    ngOnInit() {
        this.loading = true;
        this.gs.refreshUsers();
        this.usersSubscription = this.gs.users.subscribe(users => {
            this.users = [...users];
            this.loading = false;
        });
    }

    ngOnDestroy() {
        this.usersSubscription.unsubscribe();
    }
}
