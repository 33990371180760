import { Component, Input, inject } from "@angular/core";
import * as _ from "lodash";
import { Constants } from "src/app/constants/constants";
import { TargetObjectType } from "src/app/pages/channels/channel";
import { TargetsService } from "../../../targets.service";

@Component({
    selector: "app-target-thumbnail-info",
    templateUrl: "./target-thumbnail-info.component.html"
})
export class TargetThumbnailInfoComponent {
    @Input() target: TargetObjectType;
    @Input() showTag?: boolean;
    @Input() searchTerm?: string | string[];
    urls = Constants.urls;
    showCluster = false;

    public targetsService = inject(TargetsService);
}
