import { Component, Input, inject } from "@angular/core";
import { Router } from "@angular/router";
import { ClipboardService } from "ngx-clipboard";
import { Broadcaster, Tag, UserPermissions, BroadcasterAutoRecoveryParamsModel } from "src/app/models/shared";
import { SharedService } from "src/app/services/shared.service";
import { Cluster } from "../../../cluster";
import { urlBuilder } from "@zixi/shared-utils";
import { ModalService } from "src/app/components/shared/modals/modal.service";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { ClustersService } from "../../../clusters.service";
import { BroadcastersService } from "src/app/components/broadcasters/broadcasters.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "zx-broadcaster-actions-col",
    templateUrl: "./broadcaster-actions-col.component.html"
})
export class ZxBroadcasterActionsColComponent {
    @Input() broadcaster: Broadcaster;
    @Input() cluster: Cluster;
    @Input() resourceTags: Tag[];
    @Input() userPermissions: UserPermissions;
    public broadcastersService = inject(BroadcastersService);

    constructor(
        private router: Router,
        private cbs: ClipboardService,
        public sharedService: SharedService,
        private modalService: ModalService,
        private mixpanelService: MixpanelService,
        private cs: ClustersService,
        private translate: TranslateService
    ) {}

    copyPassword(pw: string, event: MouseEvent) {
        event.stopPropagation();
        this.cbs.copy(pw);
    }

    openLink(broadcaster: Broadcaster) {
        window.open(broadcaster.configure_link, "_blank");
    }

    canEditBroadcaster() {
        return this.sharedService.canEditZixiObject(this.cluster, this.resourceTags, this.userPermissions);
    }

    editBroadcaster(broadcaster: Broadcaster) {
        this.router.navigate(
            urlBuilder.getBroadcasterActionUrl(this.cluster.id, broadcaster.id, broadcaster.name, "edit")
        );
    }

    async toggle(broadcaster: Broadcaster) {
        if (broadcaster.is_enabled === 1) {
            await this.disableBroadcater(broadcaster);
        } else {
            await this.enableBroadcaster(broadcaster);
        }
    }

    async enableBroadcaster(broadcaster: Broadcaster) {
        const model = {
            is_enabled: 1
        };

        await this.modalService.confirm(
            "ENABLE",
            "BROADCASTER",
            async () => {
                const result = await this.broadcastersService.updateBroadcaster(broadcaster, model);
                if (result !== false) {
                    this.mixpanelService.sendEvent(this.translate.instant("ENABLE").toLowerCase() + " broadcaster", {
                        id: broadcaster.id
                    });
                    this.cs.refreshCluster(this.cluster.id, true);
                    return true;
                } else {
                    return false;
                }
            },
            broadcaster.name
        );
    }

    async disableBroadcater(broadcaster: Broadcaster) {
        type DisableRequest = {
            is_enabled: 0;
        } & Partial<BroadcasterAutoRecoveryParamsModel>;

        const model: DisableRequest = {
            is_enabled: 0
        };

        await this.modalService.confirmDeleteDisableBroadcasterWithRecovery(
            "DISABLE",
            broadcaster.name,
            broadcaster.id,
            this.cluster.broadcasters,
            async (autorecover: boolean, target_broadcater_id: number | null) => {
                if (autorecover) {
                    model.auto_recovery = { exclude_broadcaster_id: broadcaster.id };
                    if (target_broadcater_id) {
                        model.auto_recovery.target_broadcaster_id = target_broadcater_id;
                    }
                }

                const result = await this.broadcastersService.updateBroadcaster(broadcaster, model);
                if (result !== false) {
                    this.mixpanelService.sendEvent(this.translate.instant("ENABLE").toLowerCase() + " broadcaster", {
                        id: broadcaster.id
                    });
                    this.cs.refreshCluster(this.cluster.id, true);
                    return true;
                } else {
                    return false;
                }
            }
        );
    }

    async toggleBroadcasterMute(broadcaster: Broadcaster) {
        const model = {
            muted: !broadcaster.active_mute,
            muted_until: null,
            flapping: null
        };

        const result = await this.broadcastersService.updateBroadcaster(broadcaster, model);
        if (result !== false) {
            this.cs.refreshCluster(this.cluster.id, true);
            return true;
        } else {
            return false;
        }
    }

    async toggleMaintenance(broadcaster: Broadcaster) {
        const model = {
            maintenance: !broadcaster.maintenance
        };

        const result = await this.broadcastersService.updateBroadcaster(broadcaster, model);
        if (result !== false) {
            this.cs.refreshCluster(this.cluster.id, true);
            return true;
        } else {
            return false;
        }
    }

    async deleteBroadcaster(broadcaster: Broadcaster) {
        await this.modalService.confirmDeleteDisableBroadcasterWithRecovery(
            "DELETE",
            broadcaster.name,
            broadcaster.id,
            this.cluster.broadcasters,
            async (autorecover: boolean, target_broadcater_id: number | null) => {
                let additionalData: { exclude_broadcaster_id?: number; target_broadcaster_id?: number };

                if (autorecover) {
                    additionalData = { exclude_broadcaster_id: broadcaster.id };
                    if (target_broadcater_id) {
                        additionalData.target_broadcaster_id = target_broadcater_id;
                    }
                }

                const id = broadcaster.id;
                const result = await this.broadcastersService.deleteBroadcaster(
                    broadcaster,
                    additionalData ? { auto_recovery: additionalData } : undefined
                );
                if (result !== false) {
                    this.mixpanelService.sendEvent("delete broadcaster", { id });
                    this.cs.refreshCluster(this.cluster.id, true);
                } else {
                    return false;
                }
            }
        );
    }

    get Math() {
        return Math;
    }
}
