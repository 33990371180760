import { Component, Input } from "@angular/core";
import { Bitrate } from "src/app/models/shared";
import { Constants } from "src/app/constants/constants";

@Component({
    selector: "app-channel-bitrates-profile-column",
    templateUrl: "./channel-bitrates-profile-column.component.html"
})
export class ChannelBitratesProfileColumnComponent {
    @Input() bitrate: Bitrate;

    constants = Constants;
}
