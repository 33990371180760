import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from "@angular/core";
import { Subscription } from "rxjs";
import { ControlContainer, NgForm } from "@angular/forms";
import * as _ from "lodash";

import { GridsService } from "../../../pages/grids/grids.service";
import { Grid } from "../../../pages/grids/grid";

@Component({
    selector: "zx-grid-select",
    templateUrl: "./zx-grid-select.component.html",
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class ZxGridSelectComponent implements OnInit, OnDestroy {
    @Input() name: string;
    @Input() model: Grid[];
    @Input() required?: boolean;
    @Input() disabled?: boolean;
    @Input() filter?: string;
    @Output() modelChange = new EventEmitter();

    loading: boolean;
    grids: Grid[];
    placeholder = "";
    private gridsSubscription: Subscription;

    constructor(private gs: GridsService) {}

    modelChanged() {
        this.modelChange.emit(this.model);
    }

    ngOnInit() {
        this.loading = true;
        this.gs.refreshGrids();
        this.gridsSubscription = this.gs.grids.subscribe(grids => {
            if (this.filter) {
                if (this.filter === "public") {
                    this.grids = _.filter(grids, ["public", 1]);
                    this.placeholder = "PUBLIC";
                } else if (this.filter === "private") {
                    this.grids = _.filter(grids, ["public", 0]);
                    this.placeholder = "PRIVATE";
                } else this.grids = [...grids];
            } else {
                this.grids = [...grids];
            }
            this.loading = false;
        });
    }

    ngOnDestroy() {
        this.gridsSubscription.unsubscribe();
    }
}
