import { ComponentRef } from "@angular/core";
import { Base } from "@zixi/models";
import { KeyMap } from "../../../models/shared";
import { ZxDateTimeDisplayComponent } from "./zx-date-time-display.component";
import { dateFormats } from "src/app/services/time-zone.service";

type stringCallback<T> = (row: T) => string;

export const assignDateTimeDisplayInputsFactory =
    <T = KeyMap<Base>>(
        dateTimeStringCallback: stringCallback<T>,
        format?: dateFormats,
        adjustToUserTimezone?: boolean,
        overrideFormat?: string
    ) =>
    (ComponentRef: ComponentRef<ZxDateTimeDisplayComponent>, row: T, searchTerm) => {
        const comp = ComponentRef.instance;
        comp.dateTimeString = dateTimeStringCallback(row);
        comp.searchTerm = searchTerm?.toString();
        comp.format = format ?? dateFormats.longDateTimeSeconds;
        comp.adjustToUserTimezone = adjustToUserTimezone ?? true;
        comp.overrideFormat = overrideFormat;
    };
